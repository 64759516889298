// Event Names
export const APPCUES = 'Appcues';
export const CONTENT_LIBRARY = 'Content Library';
export const DASHBOARD = 'Dashboard';
export const EVENT_DETAILS = 'Event Details';
export const FEATURE_LOCK = 'Feature Locked';
export const IPD = 'IPD';
export const MAIN_MENU = 'Main Menu';
export const NAVIGATION = 'Navigation';
export const NOTIFICATION = 'Notification';
export const ONBOARDING = 'Onboarding';
export const PROFILE_MENU = 'Profile Menu';
export const SCHEDULE_WEBINAR = 'Schedule Webinar';
export const SEARCH = 'Search';
export const SEARCHV2 = 'SearchV2';
export const TRANSCRIPT = 'Transcript';
export const UPGRADE_URL_CLICKED = 'Upgrade';
export const VIDEO = 'Video';
export const VIDEO_EDIT = 'Video Edit';
// Types
export const ANALYTICS = 'Analytics';
export const AUDIOSETTINGS = 'Audio Settings';
export const AUTORECORD = 'Auto Record';
export const BRANDING_AND_COLORS = 'Branding and Colors';
export const CALENDAR = 'Calendar';
export const CHANNEL = 'Channel';
export const COPY_PAST_WEBINAR = 'Copy Past Webinar';
export const EMAIL = 'Email';
export const ENGAGEMENT = 'Engagement';
export const NOTIFICATION_PREFERENCES = 'Notification Preferences';
export const ORGANIZERS = 'Organizers';
export const PAGE_VISIT = 'Page Visit';
export const PANELISTS = 'Panelists';
export const REGISTRATION = 'Registration';
export const TABS = 'Tabs';
export const TRIALER_CARD = 'Trialer Card';
export const UPGRADE_CARD = 'Upgrade Card';
export const WEBINAR = 'Webinar';
export const SHARE = 'Share';
export const PAYMENT = 'Payment';
export const PAYMENT_TOS = 'Payment-Tos';
export const PAYMENT_CONNECT_TO_STRIPE = 'Payment-Connect to Stripe';

export const SETTINGS = 'Settings';

export const THEME = 'Theme';
