import config from 'appConfig';
import Role from 'Reporting/role';

function sanitize(context) {
  // Remove all entries with null/undefined values.
  Object.entries(context).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete context[key];
    }
  });
}

export default class SuperProperty {
  constructor() {
    this.userKey = null;
    this.accountKey = null;
    this.userSessionId = null;
    this.anonymousId = null;
    this.role = null;
    this.entitlements = null;
    this.appName = config.appName;
    this.appVersion = config.appVersion;
    this.appEnvironment = config.appEnv;
    this.pricingTier = null;
    this.channel = null;
    this.accountCreated = null;
    Object.preventExtensions(this);
  }

  setUserKey(userKey) {
    this.userKey = userKey;
    return this;
  }

  setAccountKey(accountKey) {
    this.accountKey = accountKey;
    return this;
  }

  setUserSessionId(userSessionId) {
    this.userSessionId = userSessionId;
    return this;
  }

  setPricingTier(pricingTier) {
    this.pricingTier = pricingTier;
    return this;
  }

  setChannel(channel) {
    this.channel = channel;
    return this;
  }

  setAnonymousId(anonymousId) {
    this.anonymousId = anonymousId;
    return this;
  }

  setRole(role) {
    if (!Role.values().includes(role)) {
      throw new Error('Invalid role type set on super property');
    }
    this.role = role;
    return this;
  }

  setEntitlements(entitlements) {
    this.entitlements = entitlements;
    return this;
  }

  setAccountCreated(created) {
    this.accountCreated = created;
    return this;
  }

  build() {
    sanitize(this);
    return Object.freeze(this);
  }
}

