const breakpoints = {
  extraLarge: 1200,
  large: 992,
  medium: 768,
  small: 576
};

export const EXTRA_LARGE = 'XL';
export const LARGE = 'L';
export const MEDIUM = 'M';
export const SMALL = 'S';
export const EXTRA_SMALL = 'XS';

export default class ScreenSize {
  static isExtraLarge(windowWidth) {
    return windowWidth >= breakpoints.extraLarge;
  }

  static isLarge(windowWidth) {
    return windowWidth < breakpoints.extraLarge && windowWidth >= breakpoints.large;
  }

  static isMedium(windowWidth) {
    return windowWidth < breakpoints.large && windowWidth >= breakpoints.medium;
  }

  static isSmall(windowWidth) {
    return windowWidth < breakpoints.medium && windowWidth >= breakpoints.small;
  }

  static isExtraSmall(windowWidth) {
    return windowWidth < breakpoints.small;
  }

  static isDown(breakpoint, windowWidth) {
    switch (breakpoint) {
      case EXTRA_LARGE:
        return true;

      case LARGE:
        return windowWidth < breakpoints.extraLarge;

      case MEDIUM:
        return windowWidth < breakpoints.large;

      case SMALL:
        return windowWidth < breakpoints.medium;

      case EXTRA_SMALL:
        return windowWidth < breakpoints.small;

      default:
        console.error('Invalid breakpoint passed to ScreenSize.isDown()');
        return false;
    }
  }

  static isUp(breakpoint, windowWidth) {
    switch (breakpoint) {
      case EXTRA_LARGE:
        return windowWidth >= breakpoints.extraLarge;

      case LARGE:
        return windowWidth >= breakpoints.large;

      case MEDIUM:
        return windowWidth >= breakpoints.medium;

      case SMALL:
        return windowWidth >= breakpoints.small;

      case EXTRA_SMALL:
        return true;

      default:
        console.error('Invalid breakpoint passed to ScreenSize.isUp()');
        return false;
    }
  }
}

export function isTouchDevice() {
  return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}
