import { getHandoutsConstraintsAction,
  getAllHandoutsAction,
  getHandoutAction,
  uploadHandoutAction,
  createHandoutAction,
  deleteHandoutAction } from './actionTypes';

const initialState = {
  handoutsConstraints: {
    isFetching: false,
    data: {},
    error: null
  },
  allHandouts: {
    isFetching: false,
    data: [],
    error: null
  },
  handout: {
    isFetching: false,
    data: {},
    error: null
  },
  newHandout: {
    isFetching: false,
    data: {},
    error: null
  },
  deleteHandout: {
    isFetching: false,
    error: null
  },
  isUploading: false,
  uploadError: false

};

export default (state = initialState, action) => {
  switch (action.type) {
    // -------------------Handouts Constraints----------------------------
    case getHandoutsConstraintsAction.toString(): {
      const handoutsConstraintsCopy = { ...state.handoutsConstraints };
      handoutsConstraintsCopy.isFetching = true;
      return {
        ...state,
        handoutsConstraints: handoutsConstraintsCopy
      };
    }
    case getHandoutsConstraintsAction.complete.toString(): {
      const handoutsConstraintsCopy = { ...state.handoutsConstraints };
      handoutsConstraintsCopy.isFetching = false;
      handoutsConstraintsCopy.data = action.data;
      return {
        ...state,
        handoutsConstraints: handoutsConstraintsCopy
      };
    }
    case getHandoutsConstraintsAction.failed.toString(): {
      const handoutsConstraintsCopy = { ...state.handoutsConstraints };
      handoutsConstraintsCopy.isFetching = false;
      handoutsConstraintsCopy.error = action.error;
      return {
        ...state,
        handoutsConstraints: handoutsConstraintsCopy
      };
    }
    // -------------------Get All Handouts----------------------------
    case getAllHandoutsAction.toString(): {
      const allHandoutsCopy = { ...state.allHandouts };
      allHandoutsCopy.isFetching = true;
      return {
        ...state,
        allHandouts: allHandoutsCopy
      };
    }
    case getAllHandoutsAction.complete.toString(): {
      const allHandoutsCopy = { ...state.allHandouts };
      allHandoutsCopy.isFetching = false;
      const handoutsData = action.data && action.data._embedded && action.data._embedded.handouts
        ? action.data._embedded.handouts : [];
      allHandoutsCopy.data = handoutsData;
      return {
        ...state,
        allHandouts: allHandoutsCopy
      };
    }
    case getAllHandoutsAction.failed.toString(): {
      const allHandoutsCopy = { ...state.allHandouts };
      allHandoutsCopy.isFetching = false;
      allHandoutsCopy.data = [];
      allHandoutsCopy.error = action.error;
      return {
        ...state,
        allHandouts: allHandoutsCopy
      };
    }
    // -------------------Get Handout-----------------------------------
    case getHandoutAction.toString(): {
      const handoutCopy = { ...state.handout };
      handoutCopy.isFetching = true;
      return {
        ...state,
        handout: handoutCopy
      };
    }
    case getHandoutAction.complete.toString(): {
      const handoutCopy = { ...state.handout };
      handoutCopy.isFetching = false;
      handoutCopy.data = action.data;
      return {
        ...state,
        handout: handoutCopy
      };
    }
    case getHandoutAction.failed.toString(): {
      const handoutCopy = { ...state.handout };
      handoutCopy.isFetching = false;
      handoutCopy.error = action.error;
      return {
        ...state,
        handout: handoutCopy
      };
    }
    // -------------------Create Handout--------------------------------
    case createHandoutAction.toString(): {
      const newHandoutCopy = { ...state.newHandout };
      newHandoutCopy.isFetching = true;
      return {
        ...state,
        newHandout: newHandoutCopy
      };
    }
    case createHandoutAction.complete.toString(): {
      const newHandoutCopy = { ...state.newHandout };
      newHandoutCopy.isFetching = false;
      newHandoutCopy.data = action.newHandout;

      const allHandoutsCopy = { ...state.allHandouts };
      allHandoutsCopy.data.push(newHandoutCopy.data);
      return {
        ...state,
        newHandout: newHandoutCopy,
        allHandouts: allHandoutsCopy
      };
    }
    case createHandoutAction.failed.toString(): {
      const newHandoutCopy = { ...state.newHandout };
      newHandoutCopy.isFetching = false;
      newHandoutCopy.error = action.error;
      return {
        ...state,
        newHandout: newHandoutCopy
      };
    }
    // -------------------Upload Handout----------------------------
    case uploadHandoutAction.toString(): {
      return {
        ...state,
        isUploading: true,
        uploadError: false
      };
    }

    case uploadHandoutAction.complete.toString(): {
      return {
        ...state,
        isUploading: false,
        uploadError: false
      };
    }
    case uploadHandoutAction.failed.toString(): {
      return {
        ...state,
        isUploading: false,
        uploadError: true
      };
    }
    // -------------------Delete Handout--------------------------------
    case deleteHandoutAction.toString(): {
      const deleteHandoutCopy = { ...state.deleteHandout };
      deleteHandoutCopy.isFetching = true;
      return {
        ...state,
        deleteHandout: deleteHandoutCopy
      };
    }
    case deleteHandoutAction.complete.toString(): {
      const deleteHandoutCopy = { ...state.deleteHandout };
      deleteHandoutCopy.isFetching = false;

      const allHandoutsCopy = { ...state.allHandouts };
      const removeIndex = allHandoutsCopy.data.map((handout) => handout.objectkey).indexOf(action.handoutKey);
      allHandoutsCopy.data.splice(removeIndex, 1);
      return {
        ...state,
        deleteHandout: deleteHandoutCopy,
        allHandouts: allHandoutsCopy
      };
    }
    case deleteHandoutAction.failed.toString(): {
      const deleteHandoutCopy = { ...state.deleteHandout };
      deleteHandoutCopy.isFetching = false;
      deleteHandoutCopy.error = action.error;
      return {
        ...state,
        deleteHandout: deleteHandoutCopy
      };
    }
    default:
      return state;
  }
};
