import MixpanelTracker from '../../mixpanelTracker';
import { APPCUES } from '../../mixpanelConstants';

const mixpanelTracker = new MixpanelTracker();

export default function trackAppcues(name, event) {
  if (name && event) {
    const properties = {
      Name: name,
      FlowName: event.flowName,
      Type: event.flowType,
      Action: event.name
    };

    mixpanelTracker.setEventName(APPCUES);
    mixpanelTracker.setEventProperties(properties);
    mixpanelTracker.sendEvent();
  }
}
