import { retrieveReplyToAction,
  updateReplyToAction,
  retrieveFollowUpAction,
  updateFollowUpAction,
  retrieveReminderEmailAction,
  updateReminderEmailAction,
  retrieveConfirmationEmailAction,
  updateConfirmationEmailAction } from './actionTypes';

export function retrieveReplyTo(webinarKey) {
  return { type: retrieveReplyToAction.toString(), webinarKey };
}

export function updateReplyTo(webinarKey, replyToObj) {
  return { type: updateReplyToAction.toString(), webinarKey, replyToObj };
}

export function retrieveFollowUpEmail(webinarKey, attendeeType) {
  return { type: retrieveFollowUpAction.toString(), webinarKey, attendeeType };
}

export function updateFollowUpEmail(webinarKey, followUpEmailObj, attendeeType) {
  return {
    type: updateFollowUpAction.toString(), webinarKey, attendeeType, followUpEmailObj
  };
}

export function retrieveReminderEmail(webinarKey) {
  return { type: retrieveReminderEmailAction.toString(), webinarKey };
}

export function updateReminderEmail(webinarKey, reminderEmailObj) {
  return { type: updateReminderEmailAction.toString(), webinarKey, reminderEmailObj };
}

export function retrieveConfirmationEmail(webinarKey) {
  return { type: retrieveConfirmationEmailAction.toString(), webinarKey };
}

export function updateConfirmationEmail(webinarKey, confirmationEmailObj) {
  return { type: updateConfirmationEmailAction.toString(), webinarKey, confirmationEmailObj };
}

