import filestackHelper from '../../Utils/filestackHelper';

import { getRecordingAction,
  getViewsAction,
  updateContentAction,
  deleteRecordingAction,
  uploadRecordingProgressAction,
  createRecordingAction,
  searchRecordingAction,
  updateRecordingStatus,
  resetSearchedRecordingAction } from './actions';
import { getTranscriptUrl } from '../VideoEditor/selectors';
import { getRecordingKeyFromResources } from './helpers';

const defaultState = {
  isFetching: false,
  isUploading: false,
  allRecordings: null,
  filtered: {
    allRecordings: new Map(),
    pagination: {
      totalElements: 0
    },
    isFetching: false,
    search: ''
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case updateRecordingStatus.toString(): {
      const { assetKey, assetVersion, status } = action.payload.recording;
      const allRecordingsCopy = new Map(state.allRecordings);
      const recordingCopy = allRecordingsCopy.get(assetKey);

      let recording;

      if (assetVersion != null && recordingCopy.assetVersionDetails) {
        const updateAssetVersionDetails = (version) => {
          if (version.assetVersion === assetVersion) {
            version.status = status;
          }
          return version;
        };

        recording = {
          ...action.payload.recording,
          recordingKey: getRecordingKeyFromResources(action.payload.recording),
          assetVersionDetails: recordingCopy.assetVersionDetails.filter(updateAssetVersionDetails)
        };
      } else {
        recording = {
          ...action.payload.recording,
          recordingKey: getRecordingKeyFromResources(action.payload.recording)
        };
      }

      allRecordingsCopy.set(assetKey, recording);

      return {
        ...state,
        allRecordings: allRecordingsCopy
      };
    }
    case getRecordingAction.toString(): {
      return {
        ...state,
        isFetching: true
      };
    }
    case searchRecordingAction.toString(): {
      return {
        ...state,
        filtered: {
          ...state.filtered,
          isFetching: true
        }
      };
    }
    case resetSearchedRecordingAction.toString(): {
      return {
        ...state,
        filtered: {
          ...state.filtered,
          search: '',
          allRecordings: new Map(),
          pagination: { totalElements: 0 },
          isFetching: false
        }
      };
    }
    case getRecordingAction.complete.toString(): {
      const allRecordingsCopy = state.allRecordings ? new Map(state.allRecordings) : new Map();
      action.data.recordings.forEach((value, key) => {
        value.transcript = getTranscriptUrl(value);
        allRecordingsCopy.set(key, value);
      });
      return {
        ...state,
        allRecordings: allRecordingsCopy,
        pagination: action.data.pagination,
        isFetching: false
      };
    }
    case searchRecordingAction.complete.toString(): {
      const filteredRecordingsCopy = state.filtered.recordings ? new Map(state.filtered.recordings) : new Map();
      action.data.recordings.forEach((value, key) => {
        filteredRecordingsCopy.set(key, value);
      });
      return {
        ...state,
        filtered: {
          ...state.filtered,
          search: action.data.search,
          allRecordings: filteredRecordingsCopy,
          pagination: action.data.pagination,
          isFetching: false
        }
      };
    }
    case searchRecordingAction.failed.toString():
    case getRecordingAction.failed.toString(): {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case getViewsAction.complete.toString(): {
      return {
        ...state,
        views: action.data
      };
    }
    case updateContentAction.complete.toString(): {
      const allRecordingsCopy = new Map(state.allRecordings);
      const recordingKey = action.data.productRefKey;
      const recording = allRecordingsCopy.get(recordingKey);
      if (recording) {
        recording.content = action.data;
        allRecordingsCopy.set(recordingKey, recording);
      }
      return {
        ...state,
        allRecordings: allRecordingsCopy
      };
    }

    case deleteRecordingAction.complete.toString(): {
      const allRecordingsCopy = new Map(state.allRecordings);
      allRecordingsCopy.delete(action.assetKey);

      return {
        ...state,
        allRecordings: allRecordingsCopy
      };
    }

    case createRecordingAction.toString(): {
      return {
        ...state,
        isUploading: true,
        progressPercentage: 0
      };
    }

    case createRecordingAction.complete.toString(): {
      const newRecording = action.data;
      newRecording.createTime = new Date().toISOString();
      newRecording.name = filestackHelper.getTitleSafe(action.filename);
      const map = new Map([[newRecording.assetKey, newRecording]]);
      const newAllRecordings = state.allRecordings ? new Map([...map, ...state.allRecordings]) : new Map([...map]);
      return {
        ...state,
        allRecordings: newAllRecordings,
        isUploading: false,
        progressPercentage: 0
      };
    }

    case createRecordingAction.failed.toString(): {
      return {
        ...state,
        isUploading: false,
        uploadError: action.message,
        progressPercentage: 0
      };
    }

    case uploadRecordingProgressAction.toString(): {
      const percentage = action.progress * 100;
      return {
        ...state,
        progressPercentage: percentage
      };
    }

    default: {
      return state;
    }
  }
};
