import questionType from 'Api/Webinar/Survey/Model/questionType';

export default function sanitizeSurveyData(data) {
  if (data && data.questions && data.questions.length > 0) {
    const sanitizeQuestions = data.questions.map((question) => {
      if (question.type === questionType.SHORT_ANSWER
        || question.type === questionType.RATING) {
        question.choices = [];
      }
      return question;
    });
    data.questions = sanitizeQuestions;
  }

  return data;
}
