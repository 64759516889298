import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import windowSize from 'react-window-size';
import { toggleModal } from 'Reducks/Ui/actions';
import { FEATURE_UNLOCK } from 'Components/Modals/constants';
import { connectDispatch } from 'Utils/decorators';
import { withTranslation } from 'react-i18next';
import styles from './styles.scss';
import classicIcon from '../Assets/standard.svg';
import onDemandIcon from '../Assets/recorded.svg';
import webcastIcon from '../Assets/webcast.svg';
import recordedGreyIcon from '../Assets/recorded-grey.svg';
import classicGreyIcon from '../Assets/standard-grey.svg';
import webcastGreyIcon from '../Assets/webcast-grey.svg';
import ScreenSize, { SMALL, isTouchDevice } from '../../../../Utils/screenHelper';

export const WEBINAR_TYPES = {
  CLASSIC: 'CLASSIC',
  WEBCAST: 'BROADCAST',
  SIMULIVE: 'SIMULIVE'
};

@connectDispatch()
class WebinarType extends Component {
  static propTypes = {
    onWebinarTypeChanged: PropTypes.func,
    dispatch: PropTypes.func,
    experienceType: PropTypes.string,
    windowWidth: PropTypes.number,
    shouldShow: PropTypes.bool,
    webcastEnabled: PropTypes.bool.isRequired,
    webCastLimit: PropTypes.number.isRequired,
    classicLimit: PropTypes.number.isRequired,
    classicMaxLimit: PropTypes.number.isRequired,
    webCastMaxLimit: PropTypes.number.isRequired,
    onDemandEnabled: PropTypes.bool.isRequired,
    standardBroadCastEnabled: PropTypes.bool.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    shouldShow: true
  };

  constructor(props) {
    super(props);
    this.state = {
      classicTooltipOpen: false,
      webcastTooltipOpen: false,
      onDemandTooltipOpen: false,
      classicMouseHover: false,
      webcastMouseHover: false,
      onDemandMouseHover: false
    };
  }

  toggleTooltip = (webinarType) => {
    if (webinarType === WEBINAR_TYPES.SIMULIVE) {
      this.setState({ onDemandTooltipOpen: !this.state.onDemandTooltipOpen });
    } else if (webinarType === WEBINAR_TYPES.CLASSIC) {
      this.setState({ classicTooltipOpen: !this.state.classicTooltipOpen });
    } else if (webinarType === WEBINAR_TYPES.WEBCAST) {
      this.setState({ webcastTooltipOpen: !this.state.webcastTooltipOpen });
    }
  };

  onSelected = (webinarType, enabled) => {
    if (!enabled) {
      this.props.dispatch(toggleModal({ id: FEATURE_UNLOCK }));
      return;
    }

    if (webinarType === WEBINAR_TYPES.SIMULIVE) {
      this.props.onWebinarTypeChanged(WEBINAR_TYPES.SIMULIVE);
    } else if (webinarType === WEBINAR_TYPES.CLASSIC) {
      this.props.onWebinarTypeChanged(WEBINAR_TYPES.CLASSIC);
    } else if (webinarType === WEBINAR_TYPES.WEBCAST) {
      this.props.onWebinarTypeChanged(WEBINAR_TYPES.WEBCAST);
    }
  };

  onMouseEvent = (isHovering, webinarType) => {
    if (webinarType === WEBINAR_TYPES.SIMULIVE) {
      clearTimeout(this.onDemandMouseTimeId);
      this.onDemandMouseTimeId = setTimeout(() => this.setState({ onDemandMouseHover: isHovering }), isHovering ? 200 : 0);
    } else if (webinarType === WEBINAR_TYPES.CLASSIC) {
      clearTimeout(this.classicMouseTimeId);
      this.classicMouseTimeId = setTimeout(() => this.setState({ classicMouseHover: isHovering }), isHovering ? 200 : 0);
    } else if (webinarType === WEBINAR_TYPES.WEBCAST) {
      clearTimeout(this.webcastMouseTimeId);
      this.webcastMouseTimeId = setTimeout(() => this.setState({ webcastMouseHover: isHovering }), isHovering ? 200 : 0);
    }
  };

  renderTooltip = (webinarType) => {
    const { windowWidth, webCastLimit, webCastMaxLimit, webcastEnabled, standardBroadCastEnabled, classicMaxLimit, classicLimit } = this.props;
    const { classicTooltipOpen, webcastTooltipOpen, onDemandTooltipOpen } = this.state;
    const { t } = this.props;
    let isOpenFlag;
    let id = '';
    let tooltipString = '';
    if (webinarType === WEBINAR_TYPES.SIMULIVE) {
      isOpenFlag = onDemandTooltipOpen;
      id = 'on_demand_webinar_help';
      tooltipString = t('schedule.onDemandTooltip');
    } else if (webinarType === WEBINAR_TYPES.CLASSIC) {
      isOpenFlag = classicTooltipOpen;
      id = 'classic_webinar_help';
      tooltipString = standardBroadCastEnabled ? t('schedule.classicTooltip', { limit: classicLimit.toLocaleString() }) : t('schedule.classicTooltip', { limit: classicMaxLimit.toLocaleString() });
    } else if (webinarType === WEBINAR_TYPES.WEBCAST) {
      isOpenFlag = webcastTooltipOpen;
      id = 'webcast_webinar_help';
      tooltipString = webcastEnabled ? t('schedule.webcastTooltip', { limit: webCastLimit.toLocaleString() }) : t('schedule.webcastTooltip', { limit: webCastMaxLimit.toLocaleString() });
    }
    return ScreenSize.isDown(SMALL, windowWidth) || isTouchDevice() ? (
      <div className={styles.tooltipContainer}>
        <span id={id}><i className='togo-icon togo-icon-info' /></span>
        <Tooltip
          className={styles.blueTooltip}
          placement="bottom"
          isOpen={isOpenFlag}
          toggle={() => this.toggleTooltip(webinarType)}
          target={id}>
          {tooltipString}
        </Tooltip>
      </div>
    ) : null;
  };

  renderBox = (webinarType, enabled) => {
    const { experienceType, windowWidth, webCastLimit, webCastMaxLimit, classicLimit, classicMaxLimit } = this.props;
    const { webcastMouseHover, classicMouseHover, onDemandMouseHover } = this.state;
    const { t } = this.props;
    const selected = experienceType === webinarType;
    const screenSmall = ScreenSize.isDown(SMALL, windowWidth) || isTouchDevice();
    let isHovered = false;
    let icon;
    let title = '';
    let tooltipString = '';
    if (webinarType === WEBINAR_TYPES.SIMULIVE) {
      isHovered = onDemandMouseHover;
      icon = enabled ? onDemandIcon : recordedGreyIcon;
      title = t('schedule.onDemand');
      tooltipString = t('schedule.onDemandTooltip');
    } else if (webinarType === WEBINAR_TYPES.CLASSIC) {
      isHovered = classicMouseHover;
      icon = enabled ? classicIcon : classicGreyIcon;
      title = t('schedule.classic');
      tooltipString = enabled ? t('schedule.classicTooltip', { limit: classicLimit.toLocaleString() }) : t('schedule.classicTooltip', { limit: classicMaxLimit.toLocaleString() });
    } else if (webinarType === WEBINAR_TYPES.WEBCAST) {
      isHovered = webcastMouseHover;
      icon = enabled ? webcastIcon : webcastGreyIcon;
      title = t('schedule.webcast');
      tooltipString = enabled ? t('schedule.webcastTooltip', { limit: webCastLimit.toLocaleString() }) : t('schedule.webcastTooltip', { limit: webCastMaxLimit.toLocaleString() });
    }
    return (
      <button id={`schedule_type_selector_${webinarType.toLowerCase()}`}
        className={classNames(
          styles.box,
          !enabled && styles.boxLocked,
          { [styles.centerBox]: webinarType === WEBINAR_TYPES.WEBCAST },
          { [styles.boxSelected]: selected },
          { [styles.hoverBox]: !screenSmall }
        )}
        onClick={() => this.onSelected(webinarType, enabled)}
        onMouseEnter={!screenSmall ? () => this.onMouseEvent(true, webinarType) : null}
        onMouseLeave={!screenSmall ? () => this.onMouseEvent(false, webinarType) : null}
        aria-label={title}
      >
        <i className={classNames(styles.lockIcon, 'togo-icon togo-icon-lock-closed')} />
        {isHovered ? title : <img src={icon} alt={title} className={styles.iconStyle} />}
        <div className={styles.boxText}>
          {isHovered ? <span className={styles.hintText}>{tooltipString}</span> : title}
        </div>
        {this.renderTooltip(webinarType)}
      </button>
    );
  };

  render() {
    const {
      windowWidth, shouldShow, webcastEnabled, onDemandEnabled, standardBroadCastEnabled
    } = this.props;
    if (!shouldShow) {
      return null;
    }
    return (
      <div className={styles.container} id='schedule_webinar_type_container'>
        {ScreenSize.isDown(SMALL, windowWidth) ? (
          <div className={styles.boxContainer}>
            {this.renderBox(WEBINAR_TYPES.CLASSIC, standardBroadCastEnabled)}
            {this.renderBox(WEBINAR_TYPES.WEBCAST, webcastEnabled)}
            {this.renderBox(WEBINAR_TYPES.SIMULIVE, onDemandEnabled)}
          </div>
        ) : (
          <div className={styles.boxContainer}>
            {this.renderBox(WEBINAR_TYPES.CLASSIC, standardBroadCastEnabled)}
            {this.renderBox(WEBINAR_TYPES.WEBCAST, webcastEnabled)}
            {this.renderBox(WEBINAR_TYPES.SIMULIVE, onDemandEnabled)}
          </div>
        )}
        <br/>
      </div>
    );
  }
}

export default windowSize(withTranslation(WebinarType));
