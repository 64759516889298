import { createAction as createActionV2 } from 'redux-promise-middleware-actions';
import { createAction } from '../reduxActions';

export const eventTabChangedAction = createAction('EVENTS_TAB_CHANGED');
export const pastEventPageChangedAction = createAction('PAST_EVENTS_PAGE_CHANGED');
export const upcomingEventPageChangedAction = createAction('UPCOMING_EVENTS_PAGE_CHANGED');
export const showAllEventsAction = createActionV2('SHOW_ALL_EVENTS_SELECTED', (showAll) => showAll);
export const hideVideoEditingTrimAlert = createActionV2('HIDE_VIDEO_EDITING_TRIM_ALERT');
export const toggleModal = createAction('TOGGLE_MODAL', (modal = {}) => modal);
export const setEventCompactView = createAction('SET_EVENT_COMPACT_VIEW', (isCompactView) => ({ isCompactView }));

export function onEventTabChanged(index) {
  return { type: eventTabChangedAction.toString(), index };
}

export function onPastEventPageChanged(pageIndex) {
  return { type: pastEventPageChangedAction.toString(), pageIndex };
}

export function onUpcomingEventPageChanged(pageIndex) {
  return { type: upcomingEventPageChangedAction.toString(), pageIndex };
}
