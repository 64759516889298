import { createAsyncAction } from 'Reducks/reduxActions';

export const getIsVideoPushEnabledAction = createAsyncAction('GET_IS_VIDEO_PUSH_ENABLED');
export const getAllVideosAction = createAsyncAction('GET_ALL_VIDEOS');
export const getVideoAction = createAsyncAction('GET_VIDEO');
export const uploadVideoAction = createAsyncAction('UPLOAD_VIDEO');
export const uploadYouTubeLinkAction = createAsyncAction('UPLOAD_YOUTUBE_LINK');
export const createUploadPolicyAction = createAsyncAction('CREATE_UPLOAD_POLICY');
export const uploadToS3Action = createAsyncAction('UPLOAD_VIDEO_TO_S3');
export const updateVideoAction = createAsyncAction('UPDATE_VIDEO');
export const deleteVideoAction = createAsyncAction('DELETE_VIDEO');
export const getYouTubeVideoDetailsAction = createAsyncAction('GET_YOUTUBE_VIDEO_DETAILS');

