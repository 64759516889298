import { getHandoutsConstraintsAction,
  getAllHandoutsAction,
  getHandoutAction,
  createUploadPolicyAction,
  uploadToS3Action,
  createHandoutAction,
  deleteHandoutAction } from './actionTypes';

export function getHandoutsConstraints() {
  return { type: getHandoutsConstraintsAction.toString() };
}

export function getAllHandouts(webinarKey) {
  return { type: getAllHandoutsAction.toString(), webinarKey };
}

export function getHandout(webinarKey, handoutKey) {
  return { type: getHandoutAction.toString(), webinarKey, handoutKey };
}

export function createUploadPolicy(webinarKey, fileMetaData, file, userWebinarInfo) {
  return {
    type: createUploadPolicyAction.toString(), webinarKey, fileMetaData, file, userWebinarInfo
  };
}

export function uploadToS3(url, method, payload) {
  return {
    type: uploadToS3Action.toString(), url, method, payload
  };
}

export function createHandout(webinarKey, handout) {
  return { type: createHandoutAction.toString(), webinarKey, handout };
}

export function deleteHandout(webinarKey, handoutKey) {
  return { type: deleteHandoutAction.toString(), webinarKey, handoutKey };
}
