import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { SEND_NOTIFICATION } from 'Reducks/Notification/actions';
import * as webinarService from '../../Api/Webinar/webinarService';

import { getRegistrantDetailsAction } from './actions';

function* getRegistrantDetails({ webinarKey, registrantKey, includePaymentInfo }) {
  try {
    const data = yield call(webinarService.getRegistrantDetails, webinarKey, registrantKey, includePaymentInfo);
    yield put({ type: getRegistrantDetailsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getRegistrantDetailsAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

export function* watchGetRegistrantDetails() {
  yield takeLatest(getRegistrantDetailsAction.toString(), getRegistrantDetails);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRegistrantDetails)
  ]);
}
