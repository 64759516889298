import v4 from 'uuid';

export function getNumericUUID() {
  return Math.floor(Math.random() * 1000000) + 1;
}

// Returns an alphanumeric string as UUID defined in the v4 spec of uuid library.
export function getAlphanumericUUID() {
  // Use v4
  return v4();
}
