const ADD = 'Add';
const CANCEL = 'Cancel';
const CLICK = 'Click';
const CREATE = 'Create';
const DOWNLOAD = 'Download';
const EDIT = 'Edit';
const HOVER = 'Hover';
const JOIN = 'Join';
const MOVE = 'Move';
const OPEN = 'Open';
const PRACTICE = 'Practice';
const REMOVE = 'Remove';
const SAVE = 'Save';
const SELECT = 'Select';
const SHARE = 'Share';
const SHARE_MODAL = 'Share Modal Opened';
const START = 'Start';
const UNKNOWN = 'Unknown';
const SHOW_ALL = 'Show All';
const VIEW = 'View';
const VIEW_MODE_CHANGE = 'View Mode Change';

export default class Action {
  static get EDIT() {
    return EDIT;
  }

  static get ADD() {
    return ADD;
  }

  static get REMOVE() {
    return REMOVE;
  }

  static get MOVE() {
    return MOVE;
  }

  static get SAVE() {
    return SAVE;
  }

  static get CANCEL() {
    return CANCEL;
  }

  static get CLICK() {
    return CLICK;
  }

  static get SELECT() {
    return SELECT;
  }

  static get VIEW() {
    return VIEW;
  }

  static get START() {
    return START;
  }

  static get JOIN() {
    return JOIN;
  }

  static get PRACTICE() {
    return PRACTICE;
  }

  static get CREATE() {
    return CREATE;
  }

  static get SHARE() {
    return SHARE;
  }

  static get SHARE_MODAL() {
    return SHARE_MODAL;
  }

  static get UNKNOWN() {
    return UNKNOWN;
  }

  static get HOVER() {
    return HOVER;
  }

  static get OPEN() {
    return OPEN;
  }

  static get DOWNLOAD() {
    return DOWNLOAD;
  }

  static get SHOW_ALL() {
    return SHOW_ALL;
  }

  static get VIEW_MODE_CHANGE() {
    return VIEW_MODE_CHANGE;
  }

  static values() {
    return [EDIT, ADD, REMOVE, MOVE, CANCEL, SAVE, SELECT, VIEW, START, JOIN, PRACTICE, CREATE, SHARE, UNKNOWN,
      OPEN, HOVER, DOWNLOAD, SHOW_ALL, VIEW_MODE_CHANGE, CLICK];
  }
}
