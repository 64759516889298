import { getApplicationState,
  setSchedulingPreference,
  hideNotificationPreferenceMessage,
  markNotificationPreferenceAsRead,
  setDarkMode, setApplicationState } from './actions';

const defaultState = {
  pending: false,
  darkMode: false,
  notificationPreferences: {
    showPreferenceMessage: true,
    preferenceMessageRead: false
  },
  scheduling: {
    preferredEvent: null
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case getApplicationState.fulfilled.toString(): {
      return {
        ...state,
        ...action.payload.data,
        pending: false
      };
    }
    case getApplicationState.pending.toString(): {
      return {
        ...state,
        pending: true
      };
    }
    case getApplicationState.rejected.toString(): {
      return {
        ...state,
        pending: false
      };
    }
    case setApplicationState.fulfilled.toString(): {
      return {
        ...state,
        ...action.payload
      };
    }
    case setSchedulingPreference.fulfilled.toString(): {
      return {
        ...state,
        scheduling: { preferredEvent: action.payload.preferredEvent }
      };
    }
    case hideNotificationPreferenceMessage.fulfilled.toString(): {
      const newState = { ...state };
      newState.notificationPreferences.showPreferenceMessage = action.payload.showPreferenceMessage;
      return newState;
    }
    case markNotificationPreferenceAsRead.fulfilled.toString(): {
      const newState = { ...state };
      newState.notificationPreferences.preferenceMessageRead = action.payload.preferenceMessageRead;
      return newState;
    }
    case setDarkMode.fulfilled.toString(): {
      return {
        ...state,
        darkMode: action.payload.darkMode
      };
    }
    default: {
      return state;
    }
  }
};
