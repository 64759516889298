import { createAsyncAction as createAsyncActionV2, createAction } from 'redux-promise-middleware-actions';
import { fetchSimuliveRecording,
  sendPanelistInviation,
  sendCoOrganizerInviation,
  getTimeZones,
  getWebinar,
  updateOrganizers,
  updateWebinarAutoRecorded } from 'Api/Webinar/webinarService';
import { createAsyncAction } from '../reduxActions';

// WEBINAR
export const getUpcomingWebinarsAction = createAsyncAction('GET_UPCOMING_WEBINARS');
export const getPastWebinarsAction = createAsyncAction('GET_PAST_WEBINARS');
export const deleteWebinarAction = createAsyncAction('DELETE_WEBINAR');
export const getSupportedWebinarTypes = createAsyncAction('GET_SUPPORTED_WEBINAR_TYPES');
export const getPastFilteredWebinarsAction = createAsyncAction('GET_PAST_FILTERED_WEBINARS');
export const getUpcomingFilteredWebinarsAction = createAsyncAction('GET_CURRENT_AND_FUTURE_FILTERED_WEBINARS');
export const clearPastFilteredWebinarsAction = createAsyncAction('DELETE_PAST_FILTERED_WEBINARS');
export const clearUpcomingFilteredWebinarsAction = createAsyncAction('DELETE_CURRENT_AND_FUTURE_FILTERED_WEBINARS');
export const getWebinarAction = createAsyncAction('GET_WEBINAR');
export const getCalendarDetailsAction = createAsyncAction('GET_CALENDAR_DETAILS');
export const getSimuliveRecording = createAsyncActionV2('GET_SIMULIVE_RECORDING', fetchSimuliveRecording);
export const addSimuliveRecordingAction = createAction('ADD_SIMULIVE_RECORDING');
export const updateSimuliveRecording = createAsyncAction('UPDATE_SIMULIVE_RECORDING');
export const getWebinarTimeZones = createAsyncActionV2('GET_TIMEZONES', getTimeZones);
export const getWebinarAllDetails = createAsyncActionV2('GET_WEBINAR_ALL_DETAILS', getWebinar);

// START WEBINAR
export const startWebinarAction = createAsyncAction('START_WEBINAR');
export const getAuthCodeForGoToOpenerAction = createAsyncAction('GET_OAUTH_CODE_FOR_GOTO_LAUNCHER');

// PANELISTS
export const getPanelistsAction = createAsyncAction('GET_PANELISTS');

// INVITATION
export const resendPanelistInvitation = createAsyncActionV2('SEND_PANELISTS_INVITATION', sendPanelistInviation);
export const resendCoOrganizerInvitation = createAsyncActionV2('SEND_COORGANIZER_INVITATION', sendCoOrganizerInviation);

// DETAILS
export const getWebinarDetailsAction = createAsyncAction('GET_WEBINAR_DETAILS');
export const updateWebinarDetailsAction = createAsyncAction('UPDATE_WEBINAR_DETAILS');
export const getWebinarAttendanceAction = createAsyncAction('GET_WEBINAR_ATTENDANCE');
export const updateWebinarBrandingAction = createAsyncAction('UPDATE_WEBINAR_BRANDING');

// AUDIO SETTINGS
export const postWebinarAudioSettingsAction = createAsyncAction('POST_WEBINAR_AUDIO_SETTINGS');
export const getAutoRecordedAction = createAsyncAction('GET_AUTO_RECORDED');
export const getOrganizerAudioSettingsAction = createAsyncAction('GET_ORGANIZER_AUDIO_SETTINGS');
export const updateWebinarAutoRecordedAction = createAsyncActionV2('POST_AUTO_RECORDED', updateWebinarAutoRecorded);

// EDIT PANELIST
export const editPanelistsAction = createAsyncAction('EDIT_PANELISTS');

// EDIT ORGANIZER
export const editOrganizersAction = createAsyncAction('EDIT_ORGANIZERS');
export const updateOrganizersAction = createAsyncActionV2('UPDATE_ORGANIZERS', updateOrganizers);

// SERIES
export const getSeriesRecurrenceAction = createAsyncAction('GET_SERIES_RECURRENCE');
export const updateSeriesRecurrenceAction = createAsyncAction('UPDATE_SERIES_RECURRENCE');

export function fetchUpcomingWebinars(page, pageSize) {
  return { type: getUpcomingWebinarsAction.toString(), page, pageSize };
}

export function fetchWebinar(webinarKey, organizerKey, shouldLoadWebinar) {
  return {
    type: getWebinarAction.toString(), webinarKey, organizerKey, shouldLoadWebinar
  };
}

export function fetchPastWebinars(page, pageSize) {
  return { type: getPastWebinarsAction.toString(), page, pageSize };
}

export function fetchPastFilteredWebinars(filter, fromTime, toTime, page, pageSize) {
  return {
    type: getPastFilteredWebinarsAction.toString(), filter, fromTime, toTime, page, pageSize
  };
}

export function fetchUpcomingFilteredWebinars(filter, page, pageSize) {
  return {
    type: getUpcomingFilteredWebinarsAction.toString(), filter, page, pageSize
  };
}

export function clearPastFilteredWebinars() {
  return { type: clearPastFilteredWebinarsAction.toString() };
}

export function clearUpcomingFilteredWebinars() {
  return { type: clearUpcomingFilteredWebinarsAction.toString() };
}

export function fetchWebinarAttendance(webinarKey) {
  return { type: getWebinarAttendanceAction.toString(), webinarKey };
}

export function fetchSupportedWebinarTypes(accountKey, userKey) {
  return { type: getSupportedWebinarTypes.toString(), accountKey, userKey };
}

export function deleteWebinar(webinarKey, sendEmails, body, recurrenceKey, redirectToDashboard = true) {
  return {
    type: deleteWebinarAction.toString(), webinarKey, sendEmails, body, recurrenceKey, redirectToDashboard
  };
}

export function getCalendarDetails(webinarKey, userKey, sourceUrl) {
  return {
    type: getCalendarDetailsAction.toString(), webinarKey, userKey, sourceUrl
  };
}

export function fetchPanelists(webinarKey) {
  return { type: getPanelistsAction.toString(), webinarKey };
}

export function fetchWebinarDetails(webinarKey, details) {
  return { type: getWebinarDetailsAction.toString(), webinarKey, details };
}

export function updateWebinarDetails(webinarKey, details, notifyParticipants) {
  return {
    type: updateWebinarDetailsAction.toString(), webinarKey, details, notifyParticipants
  };
}

export function updateWebinarAudioSettings(webinarKey, audioDetails, notifyParticipants) {
  return {
    type: postWebinarAudioSettingsAction.toString(), webinarKey, audioDetails, notifyParticipants
  };
}

export function fetchWebinarAutoRecorded(organizerKey, webinarKey) {
  return { type: getAutoRecordedAction.toString(), organizerKey, webinarKey };
}

export function editPanelists(webinarKey, panelists, deleteKeys) {
  return {
    type: editPanelistsAction.toString(), webinarKey, panelists, deleteKeys
  };
}

export function editOrganizers(webinarKey, organizers, deleteKeys) {
  return {
    type: editOrganizersAction.toString(), webinarKey, organizers, deleteKeys
  };
}

export function startWebinar(webinarKey, successUrl, mode, authCode, redirectUrl) {
  return {
    type: startWebinarAction.toString(), webinarKey, successUrl, mode, authCode, redirectUrl
  };
}

export function getAuthCodeForGoToOpener(redirectUri) {
  return { type: getAuthCodeForGoToOpenerAction.toString(), redirectUri };
}

export function fetchSeriesRecurrence(recurrenceKey) {
  return { type: getSeriesRecurrenceAction.toString(), recurrenceKey };
}

export function updateSeriesRecurrence(webinarKey, recurrenceKey, added, modified, notifyParticipants) {
  return {
    type: updateSeriesRecurrenceAction.toString(), webinarKey, recurrenceKey, added, modified, notifyParticipants
  };
}

export function updateWebinarBranding(webinarKey, brandingData) {
  return { type: updateWebinarBrandingAction.toString(), webinarKey, brandingData };
}

export function updateSimuliveWebinarRecording(webinarKey, selectedRecording) {
  return { type: updateSimuliveRecording.toString(), webinarKey, selectedRecording };
}
