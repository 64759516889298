import { createAsyncAction } from 'Reducks/reduxActions';

export const retrieveReplyToAction = createAsyncAction('RETRIEVE_REPLY_TO');
export const updateReplyToAction = createAsyncAction('UPDATE_REPLY_TO');
export const retrieveFollowUpAction = createAsyncAction('RETRIEVE_FOLLOW_UP_EMAIL');
export const updateFollowUpAction = createAsyncAction('UPDATE_FOLLOW_UP_EMAIL');
export const retrieveReminderEmailAction = createAsyncAction('RETRIEVE_REMINDER_EMAIL');
export const updateReminderEmailAction = createAsyncAction('UPDATE_REMINDER_EMAIL');
export const retrieveConfirmationEmailAction = createAsyncAction('RETRIEVE_CONFIRMATION_EMAIL');
export const updateConfirmationEmailAction = createAsyncAction('UPDATE_CONFIRMATION_EMAIL');

