import { createAsyncAction } from 'Reducks/reduxActions';

export const retrieveWebinarPollsAction = createAsyncAction('RETRIEVE_WEBINAR_POLL');
export const createWebinarPollAction = createAsyncAction('CREATE_WEBINAR_POLL');
export const deleteWebinarPollAction = createAsyncAction('DELETE_WEBINAR_POLL');
export const updateWebinarPollAction = createAsyncAction('UPDATE_WEBINAR_POLL');
export const updateWebinarPollOrderAction = createAsyncAction('UPDATE_WEBINAR_POLL_ORDER');

export function retrieveWebinarPoll(webinarKey) {
  return { type: retrieveWebinarPollsAction.toString(), webinarKey };
}

export function createWebinarPoll(webinarKey, poll) {
  return { type: createWebinarPollAction.toString(), webinarKey, poll };
}

export function deleteWebinarPoll(webinarKey, pollKey) {
  return { type: deleteWebinarPollAction.toString(), webinarKey, pollKey };
}

export function updateWebinarPoll(webinarKey, poll) {
  return { type: updateWebinarPollAction.toString(), webinarKey, poll };
}

export function updateWebinarPollOrder(webinarKey, pollQuestions) {
  return { type: updateWebinarPollOrderAction.toString(), webinarKey, pollQuestions };
}
