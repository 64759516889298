const createWorkerMiddleware = (worker, workerTrigger) => {
  if (!worker || !worker.postMessage) {
    throw new Error(`Invalid worker supplied to createWorkerMiddleware. Instead received ${JSON.stringify(worker)}`);
  }

  return (store) => {
    worker.onmessage = ({ data: workerResults }) => {
      store.dispatch(workerResults);

      if (workerResults && workerResults.payload.terminate) {
        worker.terminate();
      }
    };

    return (next) => (action) => {
      if (action.type === workerTrigger) {
        worker.postMessage(action);
      }

      return next(action);
    };
  };
};

export default createWorkerMiddleware;
