import { createAsyncAction } from 'Reducks/reduxActions';

export const getAssociatedRecordingsAction = createAsyncAction('GET_ASSOCIATED_RECORDINGS');
export const getAssetAssociationsAction = createAsyncAction('GET_ASSET_ASSOCIATIONS');
export const getAutomaticallyAddedRecordingAssociationAction = createAsyncAction('GET_AUTOMATICALLY_ADDED_ASSET_ASSOCIATIONS');
export const getAssetWebinarAssociationsAction = createAsyncAction('GET_ASSET_WEBINAR_ASSOCIATIONS');
export const deleteRecordingAssetAssociationAction = createAsyncAction('DELETE_RECORDING_ASSET_ASSOCIATION');
export const createRecordingAssetAssociationAction = createAsyncAction('CREATE_RECORDING_ASSET_ASSOCIATION');
export const updateWebinarRecordingAssociationAction = createAsyncAction('UPDATE_WEBINAR_RECORDING_ASSOCIATION');
export const updateRecordingAssetAssociationAction = createAsyncAction('UPDATE_RECORDING_ASSET_ASSOCIATION');
export const getRecordingMetaDataAction = createAsyncAction('GET_RECORDING_METADATA');
