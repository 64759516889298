import moment from 'moment';
import momentTimezone from 'moment-timezone';
import i18n from 'i18next';

export function addDaysToDate(date, days) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
}

export function compareDatesByFullTime(dateOne, dateTwo) {
  return dateTwo.getTime() - dateOne.getTime();
}

function quantizeDateToYearMonthDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function compareDatesByYearMonthDay(dateOne, dateTwo) {
  return compareDatesByFullTime(quantizeDateToYearMonthDay(dateOne), quantizeDateToYearMonthDay(dateTwo));
}

function quantizeDateToDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

function quantizeDateToYearMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getSundayBeforeOrOnFirstDayOfMonth(date) {
  const firstDayOfMonth = quantizeDateToYearMonth(date);
  const dayOfWeekOfFirstDayOfMonth = firstDayOfMonth.getDay();

  return addDaysToDate(firstDayOfMonth, -dayOfWeekOfFirstDayOfMonth);
}

function getSundaysInMonth(date) {
  const sundaysUpperLimit = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  const sundays = [];
  let sunday = getSundayBeforeOrOnFirstDayOfMonth(date);
  while (compareDatesByFullTime(sunday, sundaysUpperLimit) > 0) {
    sundays.push(sunday);
    sunday = addDaysToDate(sunday, 7);
  }

  return sundays;
}

export function addMonthsToDate(date, months) {
  return new Date(date.getFullYear(), date.getMonth() + months, 1);
}

export function addYearsToDate(date, years) {
  return new Date(date.getFullYear() + years, date.getMonth(), 1);
}

export function areSameYearMonthDay(dateOne, dateTwo) {
  return compareDatesByYearMonthDay(dateOne, dateTwo) === 0;
}

export function isValidDate(date) {
  const result = Date.parse(date);
  return moment(result).isValid() && date.length > 7 && window.isNaN(result) === false;
}

export function compareDatesByYearMonth(dateOne, dateTwo) {
  return compareDatesByFullTime(quantizeDateToYearMonth(dateOne), quantizeDateToYearMonth(dateTwo));
}

export function areSameYearMonth(dateOne, dateTwo) {
  return compareDatesByYearMonth(dateOne, dateTwo) === 0;
}

export function compareDatesByDay(dateOne, dateTwo) {
  return compareDatesByFullTime(quantizeDateToDay(dateOne), quantizeDateToDay(dateTwo));
}

export function compareDatesByMonth(dateOne, dateTwo) {
  return compareDatesByFullTime(quantizeDateToYearMonth(dateOne), quantizeDateToYearMonth(dateTwo));
}

export function formatAsDate(date = new Date(), format = i18n.t('dateFormat.monthAndDayAsStringFormat')) {
  return moment(date).format(format);
}

export function formatAsFullDateTime(date = new Date(), timezone = 'UTC') {
  return `${moment(date).tz(timezone).format(i18n.t('dateFormat.fullDateFormat'))} ${momentTimezone(date).tz(timezone).format('z')}`;
}

export function buildMonth(date) {
  const sundays = getSundaysInMonth(date);

  return sundays.map((currentSunday) => {
    const nextSunday = addDaysToDate(currentSunday, 7);

    const week = [];
    let currentDay = new Date(currentSunday.getTime());
    while (compareDatesByFullTime(currentDay, nextSunday) > 0) {
      week.push(currentDay);
      currentDay = addDaysToDate(currentDay, 1);
    }

    return week;
  });
}

export function updateEndNextDay(startMoment, endMoment) {
  if (startMoment.hours() > endMoment.hours()) {
    return moment(startMoment).add(1, 'day').set('hour', endMoment.hour()).set('minute', endMoment.minute());
  }
  return moment(startMoment).set('hour', endMoment.hour()).set('minute', endMoment.minute());
}

export function updateMoment(dateTime, event, isDateChange) {
  if (isDateChange) {
    const dateStrings = event.target.value.split('-');
    const year = Number(dateStrings[0]);
    const month = Number(dateStrings[1]) - 1;
    const date = Number(dateStrings[2]);
    dateTime.set({ year, month, date });
  } else {
    const timeStrings = event.target.value.split(':');
    const hour = Number(timeStrings[0]);
    const minute = Number(timeStrings[1]);
    dateTime.set({ hour, minute });
  }
  return dateTime;
}

export function isInPast(dateMoment) {
  return dateMoment.isBefore(moment());
}

export function diffInDateInMilliseconds(dateOne, dateTwo) {
  return dateOne - dateTwo;
}

export function endsNextDay(startDate, endDate, timeZone) {
  return !(moment.tz(startDate, timeZone).day() === moment.tz(endDate, timeZone).day());
}

export function getMilliseconds(value = 1, unit = 'second') {
  switch (unit) {
    case 'second':
      return value * 1000;
    case 'minute':
      return value * 60 * 1000;
    case 'hour':
      return value * 60 * 60 * 1000;
    case 'day':
      return value * 24 * 60 * 60 * 1000;
    case 'week':
      return value * 7 * 24 * 60 * 60 * 1000;
    default:
      return value * 1000;
  }
}
