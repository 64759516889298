import config from 'appConfig';
import { getAuth } from 'Api';
import * as httpMethods from 'Api/Helpers/httpMethods';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';
import AttendeeType from 'Components/Card/Events/Details/Emails/Model/attendeeType';

const URL_PREFIX = '/api/webinars/';
const URL_SETTINGS_POSTFIX = '/settings';
const URL_REPLY_TO_POSTFIX = '/replyTo';
const URL_FOLLOW_UP_EMAIL_POSTFIX = '/followUpEmail';
const URL_EMAIL_CONFIRMATION_POSTFIX = '/confirmationEmail';
const URL_REMINDER_EMAIL_POSTFIX = '/reminderEmail';

// Reply To Email functions
function makeReplyToRequest(webinarKey, body, httpMethod = httpMethods.GET) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SETTINGS_POSTFIX}${URL_REPLY_TO_POSTFIX}`;
  const options = {
    headers: {
      Authorization: `Token ${getAuth().access_token}`,
      'Content-Type': 'application/json'
    },
    method: httpMethod
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function retrieveReplyTo(webinarKey) {
  return makeReplyToRequest(webinarKey);
}

export function updateReplyTo(webinarKey, body) {
  return makeReplyToRequest(webinarKey, body, httpMethods.PUT);
}

// Reminder Email functions
function makeReminderEmailRequest(webinarKey, body, httpMethod = httpMethods.GET, parseBody = true) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_REMINDER_EMAIL_POSTFIX}`;
  const options = {
    headers: {
      Authorization: `Token ${getAuth().access_token}`,
      'Content-Type': 'application/json'
    },
    method: httpMethod
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return fetchJson(url, options, JSON_MIME_TYPE, parseBody);
}

export function retrieveReminderEmail(webinarKey) {
  return makeReminderEmailRequest(webinarKey);
}

export function updateReminderEmail(webinarKey, body) {
  return makeReminderEmailRequest(webinarKey, body, httpMethods.PUT, false);
}

// Follow up Email functions
function makeFollowUpEmailRequest(webinarKey, type, body, httpMethod = httpMethods.GET, parseBody = true) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_FOLLOW_UP_EMAIL_POSTFIX}?type=${type}`;
  const options = {
    headers: {
      Authorization: `Token ${getAuth().access_token}`,
      'Content-Type': 'application/json'
    },
    method: httpMethod
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return fetchJson(url, options, JSON_MIME_TYPE, parseBody);
}

export function retrieveWebinarFollowUpEmail(webinarKey, type = 'attendee') {
  if (!AttendeeType.values().includes(type)) {
    throw new Error(`Cannot update follow up email with a type that's not a part of this list ${AttendeeType.values()}`);
  }
  return makeFollowUpEmailRequest(webinarKey, type);
}

export function updateWebinarFollowUpEmail(webinarKey, body, type = 'attendee') {
  if (!AttendeeType.values().includes(type)) {
    throw new Error(`Cannot update follow up email with a type that's not a part of this list ${AttendeeType.values()}`);
  }
  return makeFollowUpEmailRequest(webinarKey, type, body, httpMethods.PUT, false);
}

// Confirmation Email functions

function makeEmailConfirmationRequest(webinarKey, body, httpMethod = httpMethods.GET, parseBody = true) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_EMAIL_CONFIRMATION_POSTFIX}`;
  const options = {
    headers: {
      Authorization: `Token ${getAuth().access_token}`,
      'Content-Type': 'application/json'
    },
    method: httpMethod
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return fetchJson(url, options, JSON_MIME_TYPE, parseBody);
}

export function retrieveConfirmationEmail(webinarKey) {
  return makeEmailConfirmationRequest(webinarKey);
}
export function updateConfirmationEmail(webinarKey, body) {
  return makeEmailConfirmationRequest(webinarKey, body, httpMethods.PUT, false);
}

export function getReminderEmailPreview(webinarKey, message = '') {
  return `${config.g2w.url}/api/reminder/preview?webinar=${webinarKey}&message=${encodeURIComponent(message)}`;
}

export function getConfirmationEmailPreview(webinarKey, message = '') {
  return `${config.g2w.url}/api/confirmation/preview?webinar=${webinarKey}&customTextTop=${encodeURIComponent(message)}`;
}

export function getFollowUpEmailPreview(webinarKey, attendeeType, message) {
  return `${config.g2w.url}/api/followup/preview?webinar=${webinarKey}&followUpType=${attendeeType}&message=${encodeURIComponent(message)}`;
}
