import config from 'appConfig';
import { getBearerHeaders } from '../Helpers/getHeaders';
import { fetchJson } from '../Helpers/fetch';
import { GET, POST, PUT, DELETE } from '../Helpers/httpMethods';

/**
 * Payment Service
 * https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=collaboration&title=Coupons+Service+REST+APIs
 */

const baseUrl = config.couponService.url;

export function getCouponsForWebinar(accountKey, webinarKey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/associations/products/g2w/accounts/${accountKey}/references/${webinarKey}`, options, 'json')
    .catch((res) => {
      if (res.status === 404) {
        return {};
      } return Promise.reject(res);
    });
}

export function createCoupon(body, accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/coupons/products/g2w/accounts/${accountKey}`, options, 'json');
}

export function associateCouponToWebinar(body, accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/associations/products/g2w/accounts/${accountKey}`, options, 'json');
}

export function updateCoupon(body) {
  const { accountKey, userKey, couponKey } = body;
  delete body.accountKey;
  delete body.userKey;
  delete body.couponKey;
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/coupons/products/g2w/accounts/${accountKey}/users/${userKey}/coupons/${couponKey}`, options, 'json');
}

export function deleteCouponForWebinar(body) {
  const associationKeysToBeDeleted = body.associationKeys && body.associationKeys.length && body.associationKeys.toString();
  const options = {
    headers: getBearerHeaders(),
    method: DELETE
  };
  return fetchJson(`${baseUrl}/associations/products/g2w/accounts/${body.accountKey}/references/${body.webinarKey}?associationKeys=${associationKeysToBeDeleted}`, options, 'json', false);
}
