// Tealium tracking is used by the Marketing team
// Refer to https://docs.google.com/spreadsheets/d/1NV1iFV-G_5KABT1ulomjM2Qw32RYM_bex3t-qfDA6c0/edit#gid=195298985 for field definitions
import { TEALIUM_PAGE_NAMES, TEALIUM_PAGE_TYPES } from './tealiumConstants';

const utagProductData = {
  site_type: 'product',
  site_product: 'g2w',
  product: ['g2w'], // Purchased products, maybe optional?
  country_code: 'us',
  country_code_ip: '', // Currently, keeping this as empty. Will update later on
  language_code: 'en' // Account language
};

export function setLanguageAndCountry(language, country) {
  utagProductData.language_code = language;
  utagProductData.country_code = country;
}

export function setProductData(data) {
  utagProductData.product = data;
}

function pageView(pageViewData) {
  if (window.utag) {
    window.utag.view({ ...utagProductData, ...pageViewData });
  }
}

function linkView(linkViewData) {
  if (window.utag) {
    window.utag.link({ ...utagProductData, ...linkViewData });
  }
}

export function reportPageView(pageName, userKey = '', accountKey = '') {
  const pageData = {
    user_key: userKey,
    account_id: accountKey,
    url: window.location.href
  };
  const vpSuffix = '.vp';
  switch (pageName) {
    case TEALIUM_PAGE_NAMES.DASHBOARD:
      pageData.page_type = TEALIUM_PAGE_TYPES.HOME;
      pageData.page_name = TEALIUM_PAGE_NAMES.DASHBOARD + vpSuffix;
      break;
    case TEALIUM_PAGE_NAMES.EVENT_DETAILS:
      pageData.page_type = TEALIUM_PAGE_TYPES.CONTENT;
      pageData.page_name = TEALIUM_PAGE_NAMES.EVENT_DETAILS + vpSuffix;
      break;
    case TEALIUM_PAGE_NAMES.SETTINGS:
      pageData.page_type = TEALIUM_PAGE_TYPES.SETTINGS;
      pageData.page_name = TEALIUM_PAGE_NAMES.SETTINGS + vpSuffix;
      break;
    case TEALIUM_PAGE_NAMES.SCHEDULE_WEBINAR:
      pageData.page_type = TEALIUM_PAGE_TYPES.CONTENT;
      pageData.page_name = TEALIUM_PAGE_NAMES.SCHEDULE_WEBINAR + vpSuffix;
      break;
    case TEALIUM_PAGE_NAMES.VIDEO_LIBRARY:
      pageData.page_type = TEALIUM_PAGE_TYPES.CONTENT;
      pageData.page_name = TEALIUM_PAGE_NAMES.VIDEO_LIBRARY + vpSuffix;
      break;
    default:
      break;
  }
  pageView(pageData);
}

export function reportLinkView(eventName, userKey = '', accountKey = '') {
  const linkData = {
    user_key: userKey,
    account_id: accountKey
  };

  // TODO: Need to add additional tracking for link events.
  switch (eventName) {
    // Temporary case as an example. Should be replaced once concrete events have been finalized.
    case 'test':
      linkData.form_name = 'test';
      linkData.form_selector = '[data-wa-formname=testSelector]';
      linkData.event_name = 'event name';
      break;
    default:
      break;
  }

  linkView(linkData);
}
