import * as Constants from 'Containers/StartWebinar/constants';
import { setSessionStorage, clearSessionStorage } from 'Utils/sessionStorage';

export function clearWebinarDetailsAfterSessionStarted() {
  clearSessionStorage(Constants.WEBINAR_KEY_FOR_STARTING_WEBINAR);
  clearSessionStorage(Constants.SUCCESS_URL_FOR_STARTING_WEBINAR);
  clearSessionStorage(Constants.AUTH_CODE_FOR_STARTING_WEBINAR);
  clearSessionStorage(Constants.MODE_FOR_STARTING_WEBINAR);
}

export function setWebinarDetailsNeedToStartSession(webinarKey, successUrl, mode) {
  setSessionStorage(Constants.WEBINAR_KEY_FOR_STARTING_WEBINAR, webinarKey);
  setSessionStorage(Constants.SUCCESS_URL_FOR_STARTING_WEBINAR, window.location.href);
  setSessionStorage(Constants.MODE_FOR_STARTING_WEBINAR, mode);
}
