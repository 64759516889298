import { put, all, fork, takeLatest, call } from 'redux-saga/effects';
import { getUpcomingWebinarsForDates } from 'Api/Webinar/webinarService';
import { getEventsForDatesAction } from './actions';

export function* fetchEvents({ startDate, endDate }) {
  try {
    const data = yield call(getUpcomingWebinarsForDates, startDate, endDate);

    yield put({ type: getEventsForDatesAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: getEventsForDatesAction.failed.toString(), message: e.message });
  }
}

// ------------------------------------ WATCHERS -----------------------------------
export function* watchGetEvents() {
  yield takeLatest(getEventsForDatesAction.toString(), fetchEvents);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetEvents)
  ]);
}
