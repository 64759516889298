import api from 'Api';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import filter from 'redux-storage-decorator-filter';
import reducers from 'Reducks/rootReducer';
import registerWithMiddleware from 'Reducks/registerSagas';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLoader, createMiddleware, reducer } from 'redux-storage';
import { routerMiddleware } from 'connected-react-router';
import promiseMiddleware from 'redux-promise-middleware';
import reportReactError from 'Utils/sentryUtils';
import { createBrowserHistory } from 'history';
import BetterReduxLogger from 'Utils/better-redux-logger';
import config from 'appConfig';
import appLogger from '../Utils/logging';
import { getUserAction } from '../Reducks/User/actions';
import appcuesMiddleware from '../Middleware/appcuesMiddleware';
import bold360Middleware from '../Middleware/bold360Middleware';
import launchDarklyMiddleware from '../Middleware/launchDarklyMiddleware';
import mixpanelMiddleware from '../Middleware/mixpanelMiddleware';
import notificationsMiddleware from '../Middleware/Notifications/notificationsMiddleware';
import redirectMiddleware from '../Middleware/redirectMiddleware';
import assetPollingWorkerMiddleware from '../Middleware/assetPollingWorkerMiddleware';
import { setUserType, setOrganizerKey, setAccountKey, setAppVersion } from '../MessageBus/messageBusEventCreator';
import * as UserTypes from '../MessageBus/Constants/userTypeConstants';
import sentryMiddleware from '../Middleware/sentryMiddleware';
import supportHashRoute from '../Middleware/supportHashRouteMiddleware';
import messageBusMiddleware from '../Middleware/messageBusMiddleware';

let store;
export const history = createBrowserHistory();

const load = () => Promise.resolve(Object.assign({}, JSON.parse(sessionStorage.getItem('endToEnd')), JSON.parse(localStorage.getItem('endToEnd'))));

const save = (state) => {
  if (state.auth) {
    sessionStorage.setItem('endToEnd', JSON.stringify({ auth: state.auth }));
  }
  return Promise.resolve();
};

function getRootReducer(root) {
  return reducer(root, (oldState, newState) => {
    const state = Object.assign({}, oldState);
    if (newState && newState.auth) {
      state.auth = newState.auth;
    }
    return state;
  });
}

export default function configureStore() {
  const engine = { load, save };
  const persistEngine = filter(engine, [['auth']]);
  const debug = process.env.NODE_ENV === 'development' || process.env.ENVIRONMENT === 'ed';
  const rootReducer = getRootReducer(reducers(history));
  // Middleware
  const loggerMiddleware = createLogger({
    collapsed: true,
    predicate: () => debug,
    logger: new BetterReduxLogger(),
    diff: true
  });

  const sagaMiddleware = createSagaMiddleware({
    onError: reportReactError
  });
  const storageMiddleware = createMiddleware(persistEngine, [], ['AUTH_REDIRECT']);

  const apiMiddleware = () => (next) => (action) => {
    if (action.type === 'REDUX_STORAGE_LOAD' && action.payload.auth) {
      api.setAuth(action.payload.auth);
    } else if (action.type === getUserAction.fulfilled.toString()) {
      api.setUser(action.payload.user);
      api.setAccountKey(action.payload.metadata.accountKey);
      setUserType(UserTypes.ORGANIZER);
      setOrganizerKey(action.payload.user.id);
      setAppVersion(config.appVersion);
      setAccountKey(action.payload.metadata.accountKey);
      // set userkey and accountKey for logging on splunk
      appLogger.setContext('userKey', action.payload.user.id);
      appLogger.setContext('accountKey', action.payload.metadata.accountKey);
    }
    return next(action);
  };

  const middleware = [
    supportHashRoute,
    apiMiddleware,
    appcuesMiddleware,
    messageBusMiddleware,
    assetPollingWorkerMiddleware,
    bold360Middleware,
    launchDarklyMiddleware,
    mixpanelMiddleware,
    notificationsMiddleware,
    promiseMiddleware(),
    redirectMiddleware,
    routerMiddleware(history),
    sagaMiddleware,
    sentryMiddleware,
    storageMiddleware,
    loggerMiddleware
  ];

  store = createStore(
    rootReducer,
    compose(
      applyMiddleware(...middleware),
      process.env.ENVIRONMENT !== 'live' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true }) : (f) => f
    )
  );

  registerWithMiddleware(sagaMiddleware);

  return createLoader(persistEngine)(store).then(() => store);
}

export function getStore() {
  if (!store) {
    console.error('You are trying to access the store before it has been defined');
  }
  return store;
}
