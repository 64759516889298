
export function mapRecordingsWithContent(recordingsMap, publishedRecordings) {
  const published = publishedRecordings.sort((a, b) => {
    const aDate = new Date(a.modifiedTime);
    const bDate = new Date(b.modifiedTime);
    return aDate - bDate;
  });
  const newRecordingsMap = new Map(recordingsMap);
  published.forEach((content) => {
    const recording = newRecordingsMap.get(content.assetKey);
    if (recording) {
      recording.content = content;
      recording.isPublished = content.shareStatus === 'PUBLIC';
      newRecordingsMap.set(content.assetKey, recording);
    }
  });

  return newRecordingsMap;
}

export function getAttributeValue(asset, key, resourceType = 'recording') {
  let attributeValue = null;
  if (asset && asset.resources) {
    for (const res of asset.resources) {
      if (res.resourceType === resourceType) {
        const matchingAttribue = res.attributes.find((att) => att.name === key);
        attributeValue = matchingAttribue ? matchingAttribue.value : null;
        if (attributeValue) {
          break;
        }
      }
    }
  }

  return attributeValue;
}

export function createRecordingMap(recordingsMap, recordings) {
  const recordingsCopy = new Map(recordingsMap);
  recordings.forEach((content) => {
    const recording = recordingsMap.get(content.productRefKey);
    if (recording != null && content.productRefKey === recording.recordingKey) {
      recording.content = content;
      recording.isPublished = (content.shareStatus === 'PUBLIC');
      recordingsCopy.set(content.productRefKey, recording);
    }
  });
  return recordingsCopy;
}

export function getAssociationKeyFromAssetKey(asset, assetKey) {
  const associationResources = asset && asset._embedded && asset._embedded.associationResources;
  if (!associationResources) {
    return null;
  }
  let associationResource = associationResources.find((element) => element.assetKey === assetKey);
  if (!associationResource) {
    associationResource = associationResources.length > 0 ? associationResources[0] : null;
  }
  return associationResource ? associationResource.associationKey : null;
}

export function getAssociationKeyFromAssociationType(asset, associationType) {
  const associationResources = asset && asset._embedded && asset._embedded.associationResources;
  if (!associationResources) {
    return null;
  }
  let associationResource = associationResources.find((element) => element.associationType
   && element.associationType.toLowerCase().indexOf(associationType) > -1);
  if (!associationResource) {
    associationResource = associationResources.length > 0 ? associationResources[0] : null;
  }
  return associationResource ? associationResource.associationKey : null;
}

export function addToRecordingMap(recordingsMap, recording) {
  if (!(recordingsMap instanceof Map) || !(recording instanceof Object)) {
    return;
  }
  const key = recording.content ? recording.content.productRefKey : recording.recordingKey;
  recordingsMap.set(key, recording);
}

export function getLastCompletedVersion(recording) {
  if (recording.assetVersionDetails && recording.assetVersionDetails.length) {
    const latestAsset = recording.assetVersionDetails
      .filter((version) => version.status === 'COMPLETED')
      .sort((a, b) => b.assetVersion - a.assetVersion)[0];

    if (latestAsset && latestAsset.assetVersion) {
      return latestAsset.assetVersion;
    }
  }

  if (recording.status === 'COMPLETED' && typeof recording.assetVersion === 'number') {
    return recording.assetVersion;
  }

  return 0;
}
