import { unregister } from '../../Notifications/Push/serviceWorkerManager';
import { init } from '../../Notifications/Push/notificationOrchestrator';
import { getUserKey } from '../../Notifications/Helpers/notificationsHelper';

const hasMetadata = (state) => (
  state.user && state.user.metadata
);

const isSetFlags = (action) => action.type === 'SET_FLAGS';

const isLaunchDarklyReady = (action, state) => action.data.isLDReady || state.launchDarkly.isLDReady;

const showNotifications = (action) => action.data.showNotifications;

const notificationsMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  const state = getState();
  const containsMetadata = hasMetadata(state);
  if (containsMetadata && isSetFlags(action) && isLaunchDarklyReady(action, state) && showNotifications(action)) {
    init(state.user, dispatch);
  } else if (isSetFlags(action) && isLaunchDarklyReady(action, state) && !showNotifications(action)) {
    unregister(getUserKey(state.user));
  }
  return next(action);
};

export default notificationsMiddleware;
