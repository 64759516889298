import { getAuth } from 'Api';

export const TOKEN_TYPE = { BEARER: 'Bearer', TOKEN: 'Token' };

export default function getHeaders(needsAuth, authTokenName = TOKEN_TYPE.TOKEN, contentType = 'application/json') {
  return () => {
    const header = contentType ? { 'Content-Type': contentType } : {};
    const auth = getAuth();
    if (needsAuth && auth && auth.access_token) {
      header.Authorization = `${authTokenName} ${auth.access_token}`;
    }
    return header;
  };
}

export function getBearerHeaders(contentType) {
  return getHeaders(true, TOKEN_TYPE.BEARER, contentType);
}
