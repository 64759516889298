import errors from 'Api/Helpers/apiErrors.json';
import { put, all, fork, takeLatest, call } from 'redux-saga/effects';
import { SEND_NOTIFICATION } from 'Reducks/Notification/actions';
import * as webinarService from 'Api/Webinar/webinarService';
import { trackWebinarCreated } from 'Mixpanel/Features/Webinar/webinarTracker';
import { WEBINAR_TYPES } from 'Components/Card/ScheduleWebinarV2/WebinarType';
import { addSimuliveRecordingAction } from 'Reducks/Webinar/actions';
import { scheduleWebinarAction } from './actions';

export function * postScheduleWebinar({
  organizerKey, webinarData, occurrenceType, copiedWebinarKey, selectedRecording, newExperience
}) {
  try {
    let audioSettings;
    let response;
    if (copiedWebinarKey) {
      const copiedData = yield call(webinarService.getWebinar, copiedWebinarKey, 'AUDIO');
      delete copiedData.webinarTimes;
      delete copiedData.recurrencePeriod;
      const data = Object.assign(copiedData, webinarData);
      response = yield call(webinarService.copyWebinar, copiedWebinarKey, data);
    } else {
      if (webinarData.experienceType === WEBINAR_TYPES.WEBCAST) {
        audioSettings = yield call(webinarService.getOrganizerAudioSettings);
        if (!audioSettings.allowed.audioModes.includes('VOIP')) {
          throw new Error(errors.api.audio.voipNotAllowed);
        } else if (audioSettings.audioModes.includes('PRIVATE')) {
          throw new Error(errors.api.audio.customAudioNotAllowed);
        }
      }
      response = yield call(webinarService.scheduleWebinar, organizerKey, webinarData, occurrenceType);
    }

    const webinarKey = response.webinarKey;
    yield put({ type: scheduleWebinarAction.complete.toString(), webinarKey, webinarData });
    if ((webinarData.experienceType === WEBINAR_TYPES.SIMULIVE) && selectedRecording) {
      yield put({ type: addSimuliveRecordingAction.toString(), webinarKey, selectedRecording });
    }

    yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.eventCreated', isError: false });
    trackWebinarCreated(webinarData, occurrenceType, newExperience);
  } catch (e) {
    yield put({ type: scheduleWebinarAction.failed.toString(), errorMessage: e.message });
    if (e.status === 400) {
      switch (e.body.Details) {
        case errors.api.schedule.recurrenceTimeInPast:
        case errors.api.schedule.timeInPast:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
          break;
        case errors.api.schedule.endBeforeStart:
        case errors.api.schedule.recurrenceEndBeforeStart:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.endTimeAfterStartTimeFail', isError: true });
          break;
        case errors.api.schedule.overYearAhead:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.startTimeYearAheadFail', isError: true });
          break;
        case errors.api.schedule.invalidSubject:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTitleFail', isError: true });
          break;
        case errors.api.schedule.maximumAllowedEvent:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maximumUpcomingEventsAllowed', isError: true });
          break;
        default:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.genericFail', isError: true });
      }
    } else if (e.message === errors.api.audio.customAudioNotAllowed) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.customAudioNotAllowed', isError: true });
    } else if (e.message === errors.api.audio.voipNotAllowed) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.voipNotAllowed', isError: true });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.genericFail', isError: true });
    }
  }
}

// ------------------------------------ WATCHERS -----------------------------------
export function * watchScheduleWebinar() {
  yield takeLatest(scheduleWebinarAction.toString(), postScheduleWebinar);
}

export default function * rootSaga() {
  yield all([fork(watchScheduleWebinar)]);
}
