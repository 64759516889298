import LDRedux from 'ld-redux';
import config from 'appConfig';
import { getUserAction } from '../Reducks/User/actions';

const flags = {
  'show-notifications': false,
  'videoedit-enabled': false,
  'scheduling-v-2-enabled': false,
  'video-transcripts-enabled': false,
  publishWebinarsToG2SEnabled: false,
  appCuesQuickTipsEnabled: false,
  hostOnlyCoOrganizerEnabled: false,
  isUIThemeToggleEnabled: false
};

const newUserLoaded = (action, getState) => (
  action.type === String(getUserAction.fulfilled) && action.payload.metadata && !getState().user.metadata
);

const launchDarklyMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  if (newUserLoaded(action, getState)) {
    const user = {
      key: action.payload.metadata.userKey,
      email: action.payload.metadata.email,
      custom: {
        channel: action.payload.metadata.channel,
        seats: action.payload.metadata.seats,
        serviceType: action.payload.metadata.serviceType
      }
    };

    LDRedux.init({
      clientSideId: config.launchDarkly.clientId,
      dispatch,
      flags,
      user
    });
  }

  return next(action);
};

export default launchDarklyMiddleware;
