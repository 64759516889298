import { createAsyncAction } from 'Reducks/reduxActions';

export const getHandoutsConstraintsAction = createAsyncAction('GET_HANDOUTS_CONSTRAINTS');
export const getAllHandoutsAction = createAsyncAction('GET_ALL_HANDOUTS');
export const getHandoutAction = createAsyncAction('GET_HANDOUT');
export const uploadHandoutAction = createAsyncAction('UPLOAD_HANDOUT');
export const createUploadPolicyAction = createAsyncAction('CREATE_UPLOAD_POLICY');
export const uploadToS3Action = createAsyncAction('UPLOAD_HANDOUT_TO_S3');
export const createHandoutAction = createAsyncAction('CREATE_HANDOUT');
export const deleteHandoutAction = createAsyncAction('DELETE_HANDOUT');

