import { getRegistrantDetailsAction } from './actions';

const defaultState = {
  isFetching: true,
  data: {},
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case getRegistrantDetailsAction.toString(): {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case getRegistrantDetailsAction.complete.toString(): {
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: null
      };
    }
    case getRegistrantDetailsAction.failed.toString(): {
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: action.error
      };
    }
    default:
      return {
        ...state
      };
  }
};
