/* eslint-disable no-extend-native */
Number.prototype.toCurrentLocaleString = function toCurrentLocaleString() {
  if (this.locale) {
    return this.valueOf().toLocaleString(this.locale);
  }
  return this.valueOf().toLocaleString();
};

export function setNumberLocale(locale) {
  Number.prototype.locale = locale;
}
