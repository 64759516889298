import { createAsyncAction } from 'Reducks/reduxActions';

export const getRegistrantsAction = createAsyncAction('GET_WEBINAR_REGISTRANTS');
export const approvePendingRegistrationsAction = createAsyncAction('UPDATE_PENDING_WEBINAR_REGISTRANTS');
export const approveDeniedRegistrationsAction = createAsyncAction('UPDATE_DENIED_WEBINAR_REGISTRANTS');
export const cancelRegistrationsAction = createAsyncAction('CANCEL_WEBINAR_REGISTRANTS');
export const denyPendingRegistrationsAction = createAsyncAction('DELETE_PENDING_WEBINAR_REGISTRANTS');
export const resendConfirmationEmailAction = createAsyncAction('RESEND_CONFIRMATION_EMAIL');
export const getRegistrationReportAction = createAsyncAction('GET_REGISTRATION_REPORT');
export const getFilteredRegistrantsAction = createAsyncAction('GET_FILTERED_REGISTRANTS');
export const clearRegistrantsAction = createAsyncAction('CLEAR_WEBINAR_REGISTRANTS');

export function fetchWebinarRegistrants(webinarKey, includePaymentInfo) {
  return { type: getRegistrantsAction.toString(), webinarKey, includePaymentInfo };
}

export function fetchFilteredRegistrants(filter) {
  return { type: getFilteredRegistrantsAction.toString(), filter };
}

export function approvePendingRegistrations(webinarKey, body, includePaymentInfo) {
  return { type: approvePendingRegistrationsAction.toString(), webinarKey, body, includePaymentInfo };
}

export function approveDeniedRegistrations(webinarKey, body, includePaymentInfo) {
  return { type: approveDeniedRegistrationsAction.toString(), webinarKey, body, includePaymentInfo };
}

export function denyPendingRegistrations(webinarKey, body, includePaymentInfo) {
  return { type: denyPendingRegistrationsAction.toString(), webinarKey, body, includePaymentInfo };
}

export function cancelRegistrations(webinarKey, body, paymentInfo) {
  return { type: cancelRegistrationsAction.toString(), webinarKey, body, paymentInfo };
}

export function resendConfirmationEmail(webinarKey, body) {
  return { type: resendConfirmationEmailAction.toString(), webinarKey, body };
}

export function getRegistrationReport(webinarLocale, webinarKey) {
  return { type: getRegistrationReportAction.toString(), webinarLocale, webinarKey };
}

export function clearWebinarRegistrants(webinarKey) {
  return { type: clearRegistrantsAction.toString(), webinarKey };
}
