import DashboardClickedEvent from 'MessageBus/Events/dashboardClickedEvent';
import { dispatchClickedEventToMessageBus, setWebinarInfo } from 'MessageBus/Helpers/webinarDetailsMessageBusHelper';
import { createAndUpdateChannelAction, publishVideoToChannelAction } from 'Reducks/Channel/actions';
import { updateSimuliveRecording } from 'Reducks/Webinar/actions';
import { createWebinarPollAction } from 'Reducks/Poll/actions';
import { scheduleWebinarAction } from 'Reducks/ScheduleWebinar/actions';
import * as SurveyActions from 'Reducks/Survey/constants';
import { updateRegistrationSettingsAction } from 'Reducks/RegistrationSettings/actions';
import { createUploadPolicyAction } from 'Reducks/Videos/actionTypes';
import { saveVideo } from '../Reducks/VideoEditor/actions';
import { reportAppcuesEventAction } from '../Reducks/Appcues/actions';

/* eslint-disable default-case */
const messageBusMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case saveVideo.fulfilled.toString(): {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.saveVideoEdit, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case publishVideoToChannelAction.fulfilled.toString(): {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.publishVideo, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case createAndUpdateChannelAction.fulfilled.toString(): {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.createChannel, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case updateSimuliveRecording.complete.toString(): {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.updateWebinarRecording, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case createWebinarPollAction.complete.toString(): {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.createPollQuestions, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case scheduleWebinarAction.complete.toString(): {
      setWebinarInfo({ webinarKey: action.webinarKey, experienceType: action.webinarData.experienceType });
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.scheduleWebinar, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case SurveyActions.CREATE_WEBINAR_SURVEY_COMPLETE: {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.createSurvey, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case updateRegistrationSettingsAction.complete.toString(): {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.saveRegistrationQuestions, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case createUploadPolicyAction.complete.toString(): {
      dispatchClickedEventToMessageBus(DashboardClickedEvent.EVENTS.uploadHandout, DashboardClickedEvent.TYPE.BUTTON, null);
      break;
    }
    case reportAppcuesEventAction.toString(): {
      dispatchClickedEventToMessageBus(`${DashboardClickedEvent.EVENTS.appcues}_${action.payload.name}`, DashboardClickedEvent.TYPE.BUTTON, action.payload.event);
      break;
    }
  }
  return next(action);
};

export default messageBusMiddleware;
