const environment = process.env.ENVIRONMENT;
const defaults = {
  env: environment,
  wootric: {
    key: 'NPS-29f86f0a'
  },
  appCue: {
    quickTipsKey: '-LeXsafjK35meENwg7CW'
  },
  bold360: {
    accountId: '744269138093162628',
    websiteId: '2945319327781502018',
    invitationId: '201826779085538911',
    chatId: '739023343391842093'
  },
  mixpanel: {
    token: 'e6501de8ff865abf0323a500d9cab6b4',
    crossProductToken: '24a5f49d4e1c652bd9d05bcbe6c0faa3'
  },
  payments: {
    termsOfServiceUrl: 'https://www.logmeininc.com/legal/beta-terms-of-service-paymentintegration',
    faqUrl: 'https://link.gotowebinar.com/help-charge-webinars',
    cancellationPolicyUrl: 'https://link.gotowebinar.com/help-charge-cancellation',
    setupFaqUrl: 'https://link.gotowebinar.com/help-charge-setup'
  },
  audioSettingsLearnMoreUrl: 'https://link.gotowebinar.com/help-set-audio',
  handoutsLearnMoreUrl: 'https://link.gotowebinar.com/help-share-handouts',
  videosLearnMoreUrl: 'https://support.logmeininc.com/supportvideos/gotowebinar/video--quick-tips-and-tricks--gotowebinar-video-sharing-quick-tip',
  recordingsLearnMoreUrl: 'https://link.gotowebinar.com/help-record-webinar',
  sourceTrackingUrl: 'https://link.gotowebinar.com/help-track-source',
  scheduleTitleTipUrl: 'https://blog.gotomeeting.com/webinar-title-formulas',
  scheduleTimeTipUrl: 'https://blog.gotomeeting.com/7-webinar-scheduling-secrets',
  moreAboutStaffUrl: 'https://link.gotowebinar.com/help-user-roles',
  webinarPricingUrl: 'https://www.gotomeeting.com/webinar/pricing',
  youTube: {
    googleApiBaseUrl: 'https://www.googleapis.com',
    googleApiKey: 'AIzaSyA4wqQXhqcm1xSxc1xDku1THcQ4e6W9Ugw',
    thumbnailBaseUrl: 'https://img.youtube.com',
    embedBaseUrl: 'https://www.youtube-nocookie.com'
  },
  support: {
    supportUrl: 'https://link.gotowebinar.com/plogin',
    communityUrl: 'https://community.logmein.com/t5/GoToWebinar-Discussions/bd-p/GoToWebinar-Discussion',
    facebookUrl: 'https://www.facebook.com/GoToWebinar',
    twitterUrl: 'https://twitter.com/gotowebinar',
    g2s: 'https://link.gotowebinar.com/help-gotostage',
    en_US: {
      liveTrainingUrl: 'https://attendee.gototraining.com/5p6g1/catalog/5615113870425159680',
      onDemandUrl: 'https://attendee.gototraining.com/5p6g1/catalog/4877868267215150080'
    },
    de_DE: {
      liveTrainingUrl: 'https://attendee.gototraining.com/5p6g1/catalog/2117754333686828288',
      onDemandUrl: 'https://attendee.gototraining.com/5p6g1/catalog/1262761996018321152'
    },
    es_ES: {
      liveTrainingUrl: 'https://attendee.gototraining.com/5p6g1/catalog/3196510492625886208',
      onDemandUrl: 'https://attendee.gototraining.com/5p6g1/catalog/3196510492625886208'
    },
    fr_FR: {
      liveTrainingUrl: 'https://attendee.gototraining.com/5p6g1/catalog/3739955095435426048',
      onDemandUrl: 'https://attendee.gototraining.com/5p6g1/catalog/3739955095435426048'
    },
    it_IT: {
      liveTrainingUrl: 'https://attendee.gototraining.com/5p6g1/catalog/3070285457829332992',
      onDemandUrl: 'https://attendee.gototraining.com/5p6g1/catalog/3070285457829332992'
    }
  },
  // eslint-disable-next-line no-undef
  appName: __APP_NAME__,
  // eslint-disable-next-line no-undef
  appVersion: __APP_VERSION__
};

const ed = Object.assign({}, defaults, {
  appEnv: 'ed',
  g2w: {
    url: 'https://globaled1.g2w.test.expertcity.com',
    registrationUrl: 'https://attendeeed1.g2w.test.expertcity.com',
    simuliveUrl: 'https://apped1.g2w.test.expertcity.com'
  },
  g2s: {
    url: 'https://apied1.gotostage.test.expertcity.com',
    web: 'https://ed.gotostage.test.expertcity.com'
  },
  logging: {
    url: 'https://logginged1.getgo.test.expertcity.com'
  },
  oauth: {
    url: 'https://authenticationed1.col.test.expertcity.com',
    clientId: '6d973c1e-45f2-4f7e-bd80-8bad2caeb8e3',
    goToOpenerClientId: 'dc052542-ead1-49ad-a357-277178ee5147'
  },
  g2wReporting: {
    url: 'https://clouded1.g2w.test.expertcity.com'
  },
  accountService: {
    url: 'https://iamed1.serversdev.getgo.com',
    adminUrl: 'https://admined1.lmiinc.test.expertcity.com'
  },
  socialGraphService: {
    url: 'https://socialgraph.serversdev.getgo.com'
  },
  assetsService: {
    url: 'https://apied.recordingassets.lmiinc.test.expertcity.com'
  },
  featureService: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com'
  },
  messageBus: {
    url: 'https://messagebused.serversdev.getgo.com/events'
  },
  avatar: {
    url: 'https://avatarsed1.serversdev.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BKECtyDnob90OQWXb4Pjk8a268G2ug0Ztc-uKADqVW6qr55yFz3XsLNsLuq3OkVMs3DuBWHGX8Ru1YL4Q0Kv8ak'
  },
  urls: {
    admin: 'https://admined1.lmiinc.test.expertcity.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    g2a: 'https://i1app.test.g2ad.expertcity.com',
    ov: 'https://globaled1.openvoice.com',
    g2m: 'https://globaled1.g2m.test.expertcity.com',
    g2t: 'https://globaled1.g2t.test.expertcity.com',
    analytics: 'https://analyticsed.g2w.test.expertcity.com',
    myAccount: 'https://myaccounted.serversdev.getgo.com/',
    register: 'https://attendeeed1.g2w.test.expertcity.com/register',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions'
  },
  sentry: {
    url: 'https://3c2500766bb04608bace0caf0be007ef@sentry.io/1532103'
  },
  filestack: {
    key: 'AVy9tFcWmQyuYfheWhBcPz',
    policy: 'eyJleHBpcnkiOjI1MjQ2MzY4MDAsImNhbGwiOlsicmVtb3ZlIl19',
    signature: '3a99e39f1b729d07510ff00a15d80e7f971ebd8ac8adb90ec84958927268f635',
    g2s: {
      key: 'ARHjiGSx9TzaILDjAQe3ez'
    },
    storage: {
      s3: {
        path: 'ed/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billinged.serversdev.getgo.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.dev.mltree.net'
  },
  registration: {
    url: 'https://attendeeed1.g2w.test.expertcity.com'
  },
  qAndAService: { url: 'https://apied-featureservices.devplatform-dev.expertcity.com/qanda' },
  logger: {
    url: 'https://logginged1.getgo.test.expertcity.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: '103e2fee-e510-4197-833f-c4fb91434dbe'
  },
  launchDarkly: {
    clientId: '5be2128248b4d42ff691b7ef'
  },
  paymentService: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com/paymentsservice'
  },
  couponService: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com/couponservice'
  },
  settingsService: {
    url: 'https://apied.servicesdev.goto.com/v1/settings'
  }
});

const rc = Object.assign({}, defaults, {
  appEnv: 'rc',
  logging: {
    url: 'https://loggingrc1.getgo.test.expertcity.com'
  },
  g2w: {
    url: 'https://globalrc1.g2w.test.expertcity.com',
    registrationUrl: 'https://attendeerc1.g2w.test.expertcity.com',
    simuliveUrl: 'https://apprc1.g2w.test.expertcity.com'
  },
  g2s: {
    url: 'https://apirc1.gotostage.test.expertcity.com',
    web: 'https://rc.gotostage.test.expertcity.com'
  },
  g2wReporting: {
    url: 'https://cloudrc1.g2w.test.expertcity.com'
  },
  oauth: {
    url: 'https://loginrc2.lmiinc.test.expertcity.com/oauth',
    clientId: '1006b182-4bc4-4b9f-bf4e-3fa5d7900ebc',
    goToOpenerClientId: '5bfbed10-8bf2-4b23-89d3-884a6b7426f7'
  },
  accountService: {
    url: 'https://iamrc1.serversdev.getgo.com',
    adminUrl: 'https://adminrc1.lmiinc.test.expertcity.com'
  },
  socialGraphService: {
    url: 'https://socialgraphrc.serversdev.getgo.com'
  },
  assetsService: {
    url: 'https://apirc.recordingassets.lmiinc.test.expertcity.com'
  },
  featureService: {
    url: 'https://apirc-featureservices.devplatform-dev.expertcity.com'
  },
  messageBus: {
    url: 'https://messagebusrc.serversdev.getgo.com/events'
  },
  avatar: {
    url: 'https://avatarsrc1.serversdev.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BKECtyDnob90OQWXb4Pjk8a268G2ug0Ztc-uKADqVW6qr55yFz3XsLNsLuq3OkVMs3DuBWHGX8Ru1YL4Q0Kv8ak'
  },
  urls: {
    admin: 'https://adminrc1.lmiinc.test.expertcity.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    g2t: 'https://globalrc1.g2t.test.expertcity.com',
    g2a: 'https://pilotapp.test.g2ad.expertcity.com',
    analytics: 'https://analyticsrc.g2w.test.expertcity.com',
    g2m: 'https://globalrc1.g2m.test.expertcity.com',
    myAccount: 'https://myaccountrc.serversdev.getgo.com/',
    register: 'https://attendeerc1.g2w.test.expertcity.com/register',
    ov: 'https://globalrc1.openvoice.com',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions'
  },
  sentry: {
    url: 'https://678c927710cc4bba886d54fca17bfe46@sentry.io/1532104'
  },
  filestack: {
    key: 'AVy9tFcWmQyuYfheWhBcPz',
    policy: 'eyJleHBpcnkiOjI1MjQ2MzY4MDAsImNhbGwiOlsicmVtb3ZlIl19',
    signature: '3a99e39f1b729d07510ff00a15d80e7f971ebd8ac8adb90ec84958927268f635',
    g2s: {
      key: 'ARHjiGSx9TzaILDjAQe3ez'
    },
    storage: {
      s3: {
        path: 'rc/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billingrc.serversdev.getgo.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.rc.mltree.net'
  },
  registration: {
    url: 'https://attendeerc1.g2w.test.expertcity.com'
  },
  qAndAService: { url: 'https://apirc-featureservices.devplatform-dev.expertcity.com/qanda' },
  logger: {
    url: 'https://loggingrc1.col.test.expertcity.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: '8c0dc28a-b666-4184-99fc-f0e80a015280'
  },
  launchDarkly: {
    clientId: '5be5e3d9b3ea0a302d748678'
  },
  paymentService: {
    url: 'https://apirc-featureservices.devplatform-dev.expertcity.com/paymentsservice'
  },
  couponService: {
    url: 'https://apirc-featureservices.devplatform-dev.expertcity.com/couponservice'
  },
  settingsService: {
    url: 'https://apirc.servicesdev.goto.com/v1/settings'
  }
});

const stage = Object.assign({}, defaults, {
  appEnv: 'stage',
  logging: {
    url: 'https://loggingstage.getgo.com'
  },
  g2w: {
    url: 'https://globalstage.gotowebinar.com',
    registrationUrl: 'https://attendeestage.gotowebinar.com',
    simuliveUrl: 'https://appstage.gotowebinar.com'
  },
  g2s: {
    url: 'https://apistage.gotostage.com',
    web: 'https://stage.gotostage.com'
  },
  g2wReporting: {
    url: 'https://cloudstage.gotowebinar.com'
  },
  oauth: {
    url: 'https://authenticationstage.logmeininc.com/oauth',
    clientId: 'd67888a3-0356-4f23-8da2-63f7a583b158',
    goToOpenerClientId: 'ee4065d3-c28f-436e-a3e6-51d3b7b692f2'
  },
  accountService: {
    url: 'https://iamstage.servers.getgo.com',
    adminUrl: 'https://adminstage.logmeininc.com'
  },
  socialGraphService: {
    url: 'https://socialgraph.servers.getgo.com'
  },
  assetsService: {
    url: 'https://apistage.recordingassets.logmeininc.com'
  },
  featureService: {
    url: 'https://apistage.services.gotomeeting.com'
  },
  messageBus: {
    url: 'https://messagebusstage.servers.getgo.com/events'
  },
  avatar: {
    url: 'https://avatarsstage.servers.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BNK37D-zxuFRNPjzjsf_c9hSjm0l08W9ZcnrVcphGV4wmvrUDuDvQn8tdqgDP3RsclmU_1L0OTI3L2Xu9VbsfP4='
  },
  urls: {
    admin: 'https://adminstage.logmeininc.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    ov: 'https://globalstage.openvoice.com',
    analytics: 'https://analyticsstage.gotowebinar.com',
    g2m: 'https://globalstage.gotomeeting.com',
    g2t: 'https://globalstage.gototraining.com',
    g2a: 'https://app.gotoassist.com',
    myAccount: 'https://myaccountstage.logmeininc.com/',
    register: 'https://attendeestage.gotowebinar.com/register',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions'
  },
  sentry: {
    url: 'https://fb31433f75854371ab40bb847ecbec6f@sentry.io/1532105'
  },
  filestack: {
    key: 'AVy9tFcWmQyuYfheWhBcPz',
    policy: 'eyJleHBpcnkiOjI1MjQ2MzY4MDAsImNhbGwiOlsicmVtb3ZlIl19',
    signature: '3a99e39f1b729d07510ff00a15d80e7f971ebd8ac8adb90ec84958927268f635',
    g2s: {
      key: 'AL08Jt8HPRbSDqrT7hg3yz'
    },
    storage: {
      s3: {
        path: 'stage/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billingstage.servers.getgo.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.stage.mltree.net'
  },
  registration: {
    url: 'https://attendeestage.gotowebinar.com'
  },
  qAndAService: { url: 'https://apistage.services.gotomeeting.com/qanda' },
  logger: {
    url: 'https://loggingstage.getgo.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: 'de1c0c57-2f72-48bb-92a7-798e206ac9df'
  },
  launchDarkly: {
    clientId: '5be5e3e40b163a3033e89d64'
  },
  paymentService: {
    url: 'https://apistage.services.gotomeeting.com/paymentsservice'
  },
  couponService: {
    url: 'https://apistage.services.gotomeeting.com/couponservice'
  },
  stripe: {
    baseUrl: 'https://connect.stripe.com',
    clientId: 'ca_EgcWqiA8ZFfUgmP2ShTiT9qek46OEfYx'
  },
  settingsService: {
    url: 'https://apistage.services.goto.com/v1/settings'
  }
});

const live = Object.assign({}, defaults, {
  env: 'production',
  appEnv: 'live',
  bold360: {
    accountId: '744269138093162628',
    websiteId: '2945319327781502018',
    invitationId: '201826779085538911',
    chatId: '738998678928212396'
  },
  logging: {
    url: 'https://logging.getgo.com'
  },
  g2w: {
    url: 'https://global.gotowebinar.com',
    registrationUrl: 'https://attendee.gotowebinar.com',
    simuliveUrl: 'https://app.gotowebinar.com'
  },
  g2s: {
    url: 'https://api.gotostage.com',
    web: 'https://gotostage.com'
  },
  g2wReporting: {
    url: 'https://cloud.gotowebinar.com'
  },
  mixpanel: {
    token: 'ddfdcac48a1870e9ea86e52c81476af8',
    crossProductToken: '393c9d23daed13b2a940771b2d675355'
  },
  oauth: {
    url: 'https://authentication.logmeininc.com/oauth',
    clientId: 'dc09563b-c2f3-4156-8d5c-4747b8bdf2ad',
    goToOpenerClientId: '7dc99d97-1695-428e-bb92-f8a8bd313c4c'
  },
  accountService: {
    url: 'https://iam.servers.getgo.com',
    adminUrl: 'https://admin.logmeininc.com'
  },
  socialGraphService: {
    url: 'https://socialgraph.servers.getgo.com'
  },
  assetsService: {
    url: 'https://api.recordingassets.logmeininc.com'
  },
  featureService: {
    url: 'https://api.services.gotomeeting.com'
  },
  messageBus: {
    url: 'https://messagebus.servers.getgo.com/events'
  },
  avatar: {
    url: 'https://avatars.servers.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BPK-72_V6-kgxWN2xsP8uwS0kSOut6wKPR5rLIAzmqXoYLkTG8K15qqmGYGg4jia-S9FF5n5z0SzrYotKg52Cb8='
  },
  urls: {
    aboutUs: 'https://www.logmeininc.com/about-us',
    admin: 'https://admin.logmeininc.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    analytics: 'https://analytics.gotowebinar.com',
    ov: 'https://global.openvoice.com',
    g2m: 'https://global.gotomeeting.com',
    g2t: 'https://global.gototraining.com',
    g2a: 'https://app.gotoassist.com',
    myAccount: 'https://myaccount.logmeininc.com/',
    register: 'https://attendee.gotowebinar.com/register',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions'
  },
  sentry: {
    url: 'https://bb421d6349714210ba69191524c1a638@sentry.io/1532106'
  },
  filestack: {
    key: 'ASkJ1Mpr6Rz6VLgugVbKlz',
    policy: 'eyJleHBpcnkiOjM0NzEzMjE2MDAsImNhbGwiOlsicmVtb3ZlIl19',
    signature: 'b363ff92bd32eec5c8f1fddb427f34308628cc5d2eb550589d018813e9d4e925',
    g2s: {
      key: 'AL08Jt8HPRbSDqrT7hg3yz'
    },
    storage: {
      s3: {
        path: 'live/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billing.logmeininc.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.mltree.net'
  },
  registration: {
    url: 'https://attendee.gotowebinar.com'
  },
  qAndAService: {
    url: 'https://api.services.gotomeeting.com/qanda'
  },
  launchDarkly: {
    clientId: '5be2128248b4d42ff691b7f0'
  },
  logger: {
    url: 'https://logging.getgo.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: '2ba854e2-a719-4faf-89b9-d1cdaf95b3a1'
  },
  paymentService: {
    url: 'https://api.services.gotomeeting.com/paymentsservice'
  },
  couponService: {
    url: 'https://api.services.gotomeeting.com/couponservice'
  },
  settingsService: {
    url: 'https://api.services.goto.com/v1/settings'
  }
});

export function getCurrentConfig(env) {
  switch (env) {
    case 'dev':
    case 'ed':
      return ed;
    case 'rc':
      return rc;
    case 'stage':
      return stage;
    case 'live':
      return live;
    default: {
      return ed;
    }
  }
}

export default getCurrentConfig(environment);
