import { put, call, all, fork } from 'redux-saga/effects';
import { takeLatestAction } from 'Utils/sagaUtils';
import * as assetsService from 'Api/Assets/assetsService';
import * as recordingService from 'Api/Webinar/AssociatedRecordings/recordingsService';
import { trackRegistrationUpdated } from 'Mixpanel/Features/Registration/registrationTracker';
import { AFTER_SESSION_VIDEO } from 'Mixpanel/Features/Registration/registrationConstants';
import Action from 'Mixpanel/Model/action';
import { getAssociatedRecordingsAction,
  getAssetAssociationsAction,
  getAssetWebinarAssociationsAction,
  getRecordingMetaDataAction,
  getAutomaticallyAddedRecordingAssociationAction,
  deleteRecordingAssetAssociationAction,
  createRecordingAssetAssociationAction,
  updateRecordingAssetAssociationAction,
  updateWebinarRecordingAssociationAction } from './actionTypes';

export function* fetchAssociatedRecordings(recording) {
  try {
    const data = yield call(recordingService.getAssociatedRecordings, recording.webinarId, recording.includeOnlineRecordingAssociation);
    yield put({ type: getAssociatedRecordingsAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getAssociatedRecordingsAction.failed.toString(), error });
  }
}

export function* deleteAssociatedRecording(recording) {
  try {
    yield call(assetsService.deleteRecordingAssociation, recording.associationKeys, recording.accountKey, recording.userKey);
    yield put({
      type: deleteRecordingAssetAssociationAction.complete.toString(),
      recordingKey: recording.recordingKey,
      attendeeType: recording.attendeeType,
      assetKey: recording.assetKey,
      recordingLinkType: recording.recordingLinkType
    });
  } catch (error) {
    yield put({ type: deleteRecordingAssetAssociationAction.failed.toString(), error });
  }
}

function* createRecordingAssociation(recording) {
  try {
    trackRegistrationUpdated(AFTER_SESSION_VIDEO, Action.CREATE);
    const data = yield call(assetsService.createRecordingAssociation, recording.entityKeys,
      recording.assetKey, recording.associationType, recording.accountKey, recording.userKey, recording.recordingObj);
    yield put({
      type: createRecordingAssetAssociationAction.complete.toString(),
      recordingKey: recording.recordingKey,
      associationType: recording.associationType,
      assetKey: recording.assetKey,
      data,
      recordingObj: recording.recordingObj,
      recordingLinkType: recording.recordingLinkType
    });
  } catch (error) {
    yield put({ type: createRecordingAssetAssociationAction.failed.toString(), error });
  }
}

export function * updateRecordingAssociation(recording) {
  try {
    trackRegistrationUpdated(AFTER_SESSION_VIDEO, Action.EDIT);
    yield call(assetsService.updateRecordingAssociation, recording.associationKeys, recording.assetKey, recording.accountKey, recording.userKey);
    yield put({
      type: updateRecordingAssetAssociationAction.complete.toString(),
      associationType: recording.associationType,
      assetKey: recording.assetKey,
      recordingObj: recording.recordingObj,
      previousRecordingKey: recording.previousRecordingKey,
      recordingLinkType: recording.recordingLinkType
    });
  } catch (error) {
    yield put({ type: updateRecordingAssetAssociationAction.failed.toString(), error });
  }
}

function* fetchRecordingAssociations(recording) {
  try {
    const associationTypes = ['attendeeemailrecordinglink', 'absenteeemailrecordinglink', 'registration'];
    const data = yield call(assetsService.getRecordingAssociations, 'webinar',
      recording.entityKeys, [], associationTypes, recording.userKey);
    yield put({ type: getAssetAssociationsAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getAssetAssociationsAction.failed.toString(), error });
  }
}

export function* fetchAutomaticallyAddedRecordingAssociation(recording) {
  try {
    const associationTypes = ['session'];
    let data = yield call(assetsService.getRecordingAssociations, 'webinar', recording.entityKeys, [], associationTypes,
      recording.userKey);

    let assetKey = -1;
    let assetDetails = null;
    if (data && data._embedded && data._embedded.associationResources[0]) {
      assetDetails = data._embedded.associationResources[0];
      assetKey = assetDetails.assetKey;
    } else {
      return; // if no associations are found, we don't need to create/update
    }

    if (recording.createOrUpdate) {
      data = yield call(assetsService.createRecordingAssociation, recording.entityKeys, assetKey, `${recording.attendeeType}emailrecordinglink`,
        recording.accountKey, recording.userKey);
      yield put({
        type: createRecordingAssetAssociationAction.complete.toString(),
        recordingKey: recording.recordingObj.recordingKey,
        associationType: `${recording.attendeeType}emailrecordinglink`,
        assetKey: recording.recordingObj.assetKey,
        data,
        recordingObj: recording.recordingObj,
        recordingLinkType: recording.recordingLinkType
      });
    } else {
      let associationKey;
      if (assetDetails != null) {
        associationKey = assetDetails.associationKey;
      } else {
        associationKey = -1;
      }
      yield call(assetsService.updateRecordingAssociation, [associationKey], assetKey, recording.accountKey, recording.userKey);
      yield put({
        type: updateRecordingAssetAssociationAction.complete.toString(),
        associationType: `${recording.attendeeType}emailrecordinglink`,
        assetKey: recording.recordingObj.assetKey,
        recordingObj: recording.recordingObj,
        previousRecordingKey: recording.recordingObj.previousRecordingKey,
        recordingLinkType: recording.recordingLinkType
      });
    }
    yield put({ type: getAutomaticallyAddedRecordingAssociationAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getAutomaticallyAddedRecordingAssociationAction.failed.toString(), error });
  }
}

function* fetchAssetWebinarAssociations({ assetKeys }) {
  try {
    const associationTypes = ['simulive', 'session', 'offlinewebinar', 'registration'];
    const data = yield call(assetsService.getRecordingAssociations, 'webinar', [], assetKeys, associationTypes);
    yield put({ type: getAssetWebinarAssociationsAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getAssetWebinarAssociationsAction.failed.toString(), error });
  }
}

export function* fetchRecordingMetadata(recording) {
  try {
    const originalRecording = yield call(assetsService.getRecordingByRecordingKey, recording.accountKey, recording.recordingKey);
    const assetKey = originalRecording.get(recording.recordingKey).assetKey;
    const latestRecording = yield call(assetsService.getRecordingByAssetKey, assetKey);
    const data = new Map([[recording.recordingKey, latestRecording]]);

    yield put({ type: getRecordingMetaDataAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getRecordingMetaDataAction.failed.toString(), error });
  }
}

function* updateWebinarRecordingAssociation(recording) {
  try {
    yield call(recordingService.createRecordingAssociation, recording.webinarKey,
      recording.recordingKey, recording.recordingSettings);
    yield put({
      type: updateWebinarRecordingAssociationAction.complete.toString(),
      recordingKey: recording.recordingKey,
      associationType: recording.associationType,
      previousRecordingKey: recording.previousRecordingKey,
      recordingObj: recording.recordingObj,
      recordingLinkType: recording.recordingLinkType
    });
  } catch (error) {
    yield put({ type: updateWebinarRecordingAssociationAction.failed.toString(), error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(takeLatestAction.bind(this, getAssociatedRecordingsAction.toString(), fetchAssociatedRecordings)),
    fork(takeLatestAction.bind(this, getAssetAssociationsAction.toString(), fetchRecordingAssociations)),
    fork(takeLatestAction.bind(this, getAssetWebinarAssociationsAction.toString(), fetchAssetWebinarAssociations)),
    fork(takeLatestAction.bind(this, getRecordingMetaDataAction.toString(), fetchRecordingMetadata)),
    fork(takeLatestAction.bind(this, createRecordingAssetAssociationAction.toString(), createRecordingAssociation)),
    fork(takeLatestAction.bind(this, deleteRecordingAssetAssociationAction.toString(), deleteAssociatedRecording)),
    fork(takeLatestAction.bind(this, updateRecordingAssetAssociationAction.toString(), updateRecordingAssociation)),
    fork(takeLatestAction.bind(this, updateWebinarRecordingAssociationAction.toString(), updateWebinarRecordingAssociation)),
    fork(takeLatestAction.bind(this, getAutomaticallyAddedRecordingAssociationAction.toString(), fetchAutomaticallyAddedRecordingAssociation))
  ]);
}
