import { OCCURRENCE_TYPE, RECURRENCE_PERIOD, SERIES_TYPE } from 'Components/Card/ScheduleWebinar/WebinarOccurrence/constants';
import { isInPast } from 'Utils/dateHelpers';
import moment from 'moment';
import config from 'appConfig';
import { extractNextUpcomingSessionDateTimeForWebinar } from 'Components/Card/Events/webinarDateHelper';

export function getEditedSpeakers(speakersMap, webinarKey, isPanelist, addedPanelists, deletedKeys) {
  const newSpeakersMap = Object.assign({}, (speakersMap));
  let newSpeakers = Array.from(speakersMap[webinarKey]);

  if (addedPanelists) {
    newSpeakers = newSpeakers.concat(addedPanelists);
  }

  if (deletedKeys) {
    deletedKeys.forEach((d) => {
      const index = newSpeakers.findIndex((s) => (isPanelist ? s.panelistKey === d : s.organizerKey === d.key));
      if (index !== -1) newSpeakers.splice(index, 1);
    });
  }

  newSpeakersMap[webinarKey] = newSpeakers;
  return newSpeakersMap;
}

export function getUpdatedSpeakers(speakersMap, webinarKey, editedOrganizers, updatedKeys) {
  const newSpeakersMap = Object.assign({}, (speakersMap));
  let newSpeakers = Array.from(speakersMap[webinarKey]);

  if (updatedKeys) {
    updatedKeys.forEach((d) => {
      const index = newSpeakers.findIndex((s) => (s.organizerKey === d.key));
      if (index !== -1) newSpeakers.splice(index, 1);
    });
  }

  if (editedOrganizers) {
    newSpeakers = newSpeakers.concat(editedOrganizers);
  }

  newSpeakersMap[webinarKey] = newSpeakers;
  return newSpeakersMap;
}

export function updateWebinar(details, webinarKey, state, isPastWebinar) {
  const previousWebinars = isPastWebinar ? state.pastWebinar.data : state.upcomingWebinar.data;

  if (previousWebinars) {
    const newWebinars = [...previousWebinars];
    const index = newWebinars.findIndex((w) => w.webinarKey === webinarKey);
    if (index === -1) {
      const newWebinar = { ...details, webinarKey };
      newWebinars.push(newWebinar);
    } else {
      const webinar = newWebinars[index];
      if (webinar.type === SERIES_TYPE.SEQUENCE && details.times) {
        const newTimes = details.times;
        const oldTimes = (webinar.times || []).filter((t) => isInPast(moment(t.endTime)));
        details.times = oldTimes.concat(newTimes);
      }
      newWebinars[index] = Object.assign(webinar, details);
    }

    return newWebinars;
  }
  return [{ ...details, webinarKey }];
}

export function deleteWebinar(webinarKey, upcomingWebinar) {
  if (upcomingWebinar) {
    const newUpcomingWebinar = [...upcomingWebinar];
    return newUpcomingWebinar.filter((w) => w.webinarKey !== webinarKey);
  }
  return null;
}

export function getIsPastWebinar(webinarKey, upcomingWebinar = [], pastWebinar = [], webinar = {}) {
  const upcomingIndex = upcomingWebinar.findIndex((w) => w.webinarKey === webinarKey);
  const pastIndex = pastWebinar.findIndex((w) => w.webinarKey === webinarKey);

  if (upcomingIndex !== -1) {
    return false;
  }
  if (pastIndex !== -1) {
    return true;
  }
  if (webinar.times) {
    const webinarTime = extractNextUpcomingSessionDateTimeForWebinar(webinar);
    return isInPast(moment(webinarTime.endTime));
  }

  // we cant find out so just return false
  return false;
}

export function constructTimeAndTimeZone(details) {
  return { times: [{ startTime: details.startTime, endTime: details.endTime }], timeZone: details.timeZone };
}

export function updateRecurrenceDetails(state, action) {
  const newState = Object.assign({}, state);
  const seriesRecurrence = Object.assign({}, newState.seriesRecurrence);
  let upcomingWebinar = Object.assign([], newState.upcomingWebinar.data);
  const recurrence = Object.assign({}, seriesRecurrence[action.recurrenceKey]);
  let webinarTimes = recurrence.webinarTimes;

  if (action.modifiedData && action.modifiedData.length > 0) {
    action.modifiedData.forEach((code, index) => {
      if (code === 202) {
        const newDate = action.modified[index];
        const timeIndex = webinarTimes.findIndex((time) => time.webinarKey === newDate.webinarKey);
        if (timeIndex >= 0) {
          upcomingWebinar = updateWebinar(constructTimeAndTimeZone(newDate), newDate.webinarKey, newState, false);
          newState.upcomingWebinar.data = upcomingWebinar;
          webinarTimes[timeIndex] = newDate;
        }
      }
    });
  }

  if (action.addedData && action.addedData.length > 0) {
    action.addedData.forEach((added) => {
      if (added.times && added.times.length > 0) {
        webinarTimes = webinarTimes.concat(added.times);
      }
    });
  }

  recurrence.webinarTimes = webinarTimes;
  seriesRecurrence[action.recurrenceKey] = recurrence;

  return { seriesRecurrence, upcomingWebinar };
}

export function getWebinarRegistrationUrl(webinarKey, recurrencePeriod) {
  const isRecurring = recurrencePeriod !== RECURRENCE_PERIOD.NEVER && recurrencePeriod !== RECURRENCE_PERIOD.ANYTIME;
  return `${config.registration.url}/${isRecurring ? 'rt' : 'register'}/${webinarKey}`;
}

export function createOrgJoinLink(webinarKey, organizerKey) {
  return `${config.g2w.url}/ojoin/${webinarKey}/${organizerKey}`;
}

export function getOrganizer(organizers = [], orgKey) {
  let organizer = null;
  organizers.forEach((org) => {
    if (org.organizerKey === orgKey) {
      organizer = org;
    }
  });
  return organizer;
}

export function isUserOrganizer(coOrganizers, userKey, webinar) {
  if (webinar.organizerKey === userKey) {
    return true;
  }
  const user = getOrganizer(coOrganizers, userKey);
  return user !== null;
}

export function isWebinarInSession(webinar) {
  return webinar.inSession && webinar.recurrencePeriod.toLowerCase() !== OCCURRENCE_TYPE.ONDEMAND;
}
