import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { takeLatestAction } from 'Utils/sagaUtils';
import * as Types from 'Components/Card/Events/Details/Registration/registrationTypes';
import * as webinarService from 'Api/Webinar/webinarService';
import * as registrationService from 'Api/Webinar/Registration/registrationService';
import { getRegistrationSettingsAction,
  updateRegistrationSettingsAction,
  getCustomDisclaimerAction } from './actions';
import { SEND_NOTIFICATION } from '../Notification/actions';

function* fetchRegistrationSettings({ webinarKey, details }) {
  try {
    const data = yield call(webinarService.getRegistrationSettings, webinarKey, details);
    yield put({ type: getRegistrationSettingsAction.complete.toString(), data, webinarKey });
  } catch (e) {
    yield put({
      type: getRegistrationSettingsAction.failed.toString(),
      message: e.message
    });
  }
}

function* updateRegistrationSettings({ webinarKey, details, registrationType }) {
  let errorOccured = false;
  try {
    const data = yield call(webinarService.updateRegistrationSettings, webinarKey, details);
    yield put({
      type: updateRegistrationSettingsAction.complete.toString(), data, webinarKey, details, registrationType
    });
    yield call(fetchRegistrationSettings, { webinarKey, details });
  } catch (e) {
    errorOccured = true;
    yield put({
      type: updateRegistrationSettingsAction.failed.toString(),
      message: e.message
    });
  } finally {
    let messageKey = null;
    switch (registrationType) {
      case Types.PASSWORD:
        messageKey = errorOccured ? 'cards.webinar.details.registration.passwordFail' : 'cards.webinar.details.registration.passwordSuccess';
        break;
      case Types.CONFIRMATION:
        messageKey = errorOccured ? 'cards.webinar.details.registration.confirmationFailed' : 'cards.webinar.details.registration.confirmationSuccess';
        break;
      case Types.QUESTIONS:
        messageKey = errorOccured ? 'cards.webinar.details.registration.registrationForm.failureNotification' : 'cards.webinar.details.registration.registrationForm.successNotification';
        break;
      case Types.RECORDING:
        messageKey = errorOccured ? 'cards.webinar.details.registration.recordingUpdateFailed' : 'cards.webinar.details.registration.recordingUpdateSuccess';
        break;
      case Types.REGISTRATION_LIMIT:
        messageKey = errorOccured ? 'cards.webinar.details.registration.registrationLimitUpdateFailed' : 'cards.webinar.details.registration.registrationLimitUpdateSuccess';
        break;
      default:
        messageKey = errorOccured ? 'cards.webinar.details.registration.registrationUpdateFailed' : 'cards.webinar.details.registration.registrationUpdated';
        break;
    }
    if (messageKey) {
      yield put({ type: SEND_NOTIFICATION, messageKey, isError: errorOccured });
    }
  }
}

export function* fetchCustomDisclaimer(action) {
  try {
    const data = yield call(registrationService.getWebinarCustomDisclaimer, action.webinarKey);
    yield put({ type: getCustomDisclaimerAction.complete.toString(), webinarKey: action.webinarKey, data });
  } catch (error) {
    yield put({ type: getCustomDisclaimerAction.failed.toString(), error });
  }
}

export function* watchFetchRegistrationSettings() {
  yield takeLatest(getRegistrationSettingsAction.toString(), fetchRegistrationSettings);
}

export function* watchUpdateRegistrationSettings() {
  yield takeLatest(updateRegistrationSettingsAction.toString(), updateRegistrationSettings);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchRegistrationSettings),
    fork(watchUpdateRegistrationSettings),
    fork(takeLatestAction.bind(this, getCustomDisclaimerAction.toString(), fetchCustomDisclaimer))
  ]);
}
