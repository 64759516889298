import config from 'appConfig';
import errors from 'Api/Helpers/apiErrors.json';
import { call, put, all, fork, takeLatest, take } from 'redux-saga/effects';
import { getRecordings, deleteRecording, createRecording, getFileUploadTicket, initiateAsset } from '../../Api/Assets/assetsService';
import { getViews } from '../../Api/reportingService';
import { updateContent } from '../../Api/GoToStage/contentService';
import { createUploadFileChannel, createfileUploadPayload } from '../../Api/Helpers/upload';
import { SEND_NOTIFICATION } from '../Notification/actions';
import { getRecordingAction, searchRecordingAction, resetSearchedRecordingAction, getViewsAction, updateContentAction,
  deleteRecordingAction, createRecordingAction, uploadRecordingProgressAction, pollRecordingStatus } from './actions';
import { getBearerHeaders } from '../../Api/Helpers/getHeaders';
import { getInitiatedAssets } from './helpers';

export function* fetchRecordings({ accountKey, page }) {
  try {
    const data = yield call(getRecordings, accountKey, page);
    yield put({ type: getRecordingAction.complete.toString(), data });
    const initiated = getInitiatedAssets(data);
    yield put({ type: pollRecordingStatus.toString(), assets: initiated, baseUrl: config.assetsService.url, headers: getBearerHeaders() });
  } catch (e) {
    yield put({ type: getRecordingAction.failed.toString(), message: e.message });
  }
}

export function* searchRecordings({ accountKey, page, search }) {
  try {
    const data = yield call(getRecordings, accountKey, page, search);
    yield put({ type: searchRecordingAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: searchRecordingAction.failed.toString(), message: e.message });
  }
}

export function* resetSearchedRecordings() {
  yield put({ type: resetSearchedRecordingAction.complete.toString() });
}

export function* fetchViews({ recordingKeys }) {
  try {
    const data = yield call(getViews, recordingKeys);
    yield put({ type: getViewsAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: getViewsAction.failed.toString(), message: e.message });
  }
}

export function* updateRecordingContent({ contentId, details }) {
  try {
    const data = yield call(updateContent, contentId, details);
    yield put({ type: updateContentAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: updateContentAction.failed.toString(), message: e.message });
  }
}

export function* deleteRecordingAsset({ assetKey }) {
  try {
    const data = yield call(deleteRecording, assetKey);
    yield put({ type: deleteRecordingAction.complete.toString(), data, assetKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.recordings.delete.success', isError: false });
  } catch (e) {
    switch (e.body.reason) {
      case errors.api.videoLibrary.assetReferenced:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.videoLibrary.notification.recordingInUseFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.recordings.delete.failed', isError: true });
    }
    yield put({ type: deleteRecordingAction.failed.toString(), message: e.message });
  }
}

export function* createRecordingAsset({ file, accountKey, resources }) {
  try {
    const recording = yield call(createRecording, file, accountKey, resources);
    const asset = [{ assetKey: recording.assetKey, assetVersion: recording.version }];
    const fileUploadTicket = yield call(getFileUploadTicket, recording, file.ext);
    const formData = createfileUploadPayload(fileUploadTicket.fileUploadDetails.formData, file.originalFile);
    const channel = yield call(createUploadFileChannel, fileUploadTicket.fileUploadDetails.uploadUrl, formData);

    while (true) {
      const { progress = 0, error, success } = yield take(channel);
      if (success) {
        yield put({ type: createRecordingAction.complete.toString(), filename: file.filename, data: recording });
        yield call(initiateAsset, recording);
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.recordings.upload.success', isError: false });
        yield put({ type: pollRecordingStatus.toString(), assets: asset, baseUrl: config.assetsService.url, headers: getBearerHeaders() });
        return;
      }

      if (error) {
        yield put({ type: createRecordingAction.failed.toString() });
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.recordings.upload.fail', isError: true });
        return;
      }

      yield put({ type: uploadRecordingProgressAction.toString(), progress });
    }
  } catch (e) {
    yield put({ type: createRecordingAction.failed.toString(), message: e.message });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.recordings.upload.fail', isError: true });
  }
}

export function* watchFetchRecordings() {
  yield takeLatest(getRecordingAction.toString(), fetchRecordings);
}

export function* watchSearchRecordings() {
  yield takeLatest(searchRecordingAction.toString(), searchRecordings);
}

export function* watchResetSearchedRecordings() {
  yield takeLatest(resetSearchedRecordingAction.toString(), resetSearchedRecordings);
}

export function* watchFetchViews() {
  yield takeLatest(getViewsAction.toString(), fetchViews);
}

export function* watchupdateContent() {
  yield takeLatest(updateContentAction.toString(), updateRecordingContent);
}

export function* watchDeleteRecording() {
  yield takeLatest(deleteRecordingAction.toString(), deleteRecordingAsset);
}

export function* watchCreateRecording() {
  yield takeLatest(createRecordingAction.toString(), createRecordingAsset);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchRecordings),
    fork(watchResetSearchedRecordings),
    fork(watchSearchRecordings),
    fork(watchFetchViews),
    fork(watchupdateContent),
    fork(watchDeleteRecording),
    fork(watchCreateRecording)
  ]);
}
