import { asyncReducer } from 'redux-promise-middleware-actions';
import { extractNextUpcomingSessionDateTimeForWebinar } from 'Components/Card/Events/webinarDateHelper';
import moment from 'moment';
import { WEBINAR_TYPES } from 'Components/Card/ScheduleWebinar/WebinarType';
import { clearPastFilteredWebinarsAction,
  clearUpcomingFilteredWebinarsAction,
  deleteWebinarAction,
  editOrganizersAction,
  updateOrganizersAction,
  editPanelistsAction,
  getAutoRecordedAction,
  getPastFilteredWebinarsAction,
  getUpcomingFilteredWebinarsAction,
  getOrganizerAudioSettingsAction,
  getPanelistsAction,
  getPastWebinarsAction,
  getSeriesRecurrenceAction,
  getSimuliveRecording,
  getSupportedWebinarTypes,
  getUpcomingWebinarsAction,
  getWebinarAction,
  getWebinarAllDetails,
  getWebinarAttendanceAction,
  getWebinarDetailsAction,
  getWebinarTimeZones,
  updateWebinarAutoRecordedAction,
  postWebinarAudioSettingsAction,
  startWebinarAction,
  updateSimuliveRecording,
  updateSeriesRecurrenceAction,
  updateWebinarBrandingAction,
  updateWebinarDetailsAction,
  addSimuliveRecordingAction } from './actions';
import { deleteWebinar,
  getEditedSpeakers,
  getUpdatedSpeakers,
  getIsPastWebinar,
  updateRecurrenceDetails,
  updateWebinar } from './webinarDetailsHelper';

const defaultState = {
  isFetchingRecurrenceSeries: false,
  isUpdatingSimuliveRecording: false,
  upcomingWebinar: {
    data: [],
    pagination: {},
    isFetching: false
  },
  panelists: {},
  organizers: {},
  audio: {},
  branding: {},
  handouts: {},
  autoRecorded: {},
  supportedWebinarTypes: {},
  loadingSupportedTypes: false,
  attendance: {
    fetchingKeys: [],
    failedKeys: [],
    data: {}
  },
  pastWebinar: {
    pagination: {},
    isFetching: false
  },
  pastFilteredWebinars: {
    pagination: {},
    isFetching: false,
    data: [],
    search: '',
    fromTime: moment().subtract(10, 'y').utc().format(),
    toTime: moment().utc().format()
  },
  upcomingFilteredWebinars: {
    pagination: {},
    isFetching: false,
    data: [],
    search: '',
    fromTime: moment().utc().format(),
    toTime: moment().add(1, 'y').utc().format()
  },
  organizerSettings: {
    isFetching: false,
    isVideoLibraryEnabled: true
  },
  seriesRecurrence: {},
  simuliveRecording: {},
  webinarAllDetails: {}
};

const simuliveRecordingReducer = asyncReducer(getSimuliveRecording);
const timezonesReducer = asyncReducer(getWebinarTimeZones);
const webinarAllDetailsReducer = asyncReducer(getWebinarAllDetails);

export default (newState = defaultState, action) => {
  const state = {
    ...newState,
    simuliveRecording: simuliveRecordingReducer(newState.simuliveRecording, action),
    timezones: timezonesReducer(newState.timezones, action),
    webinarAllDetails: webinarAllDetailsReducer(newState.webinarAllDetails, action)
  };

  switch (action.type) {
    // --------------------------------------- UPCOMING WEBINARS -----------------------------------------------
    case getUpcomingWebinarsAction.toString(): {
      return {
        ...state,
        upcomingWebinar: {
          ...state.upcomingWebinar,
          isFetching: true
        }
      };
    }
    case getUpcomingWebinarsAction.complete.toString(): {
      const newUpcomingWebinar = action.data.data || [];
      let newUpcomingWebinarSorted = newUpcomingWebinar.sort((event1, event2) => {
        const date1 = moment(extractNextUpcomingSessionDateTimeForWebinar(event1).startTime);
        const date2 = moment(extractNextUpcomingSessionDateTimeForWebinar(event2).startTime);
        return date2.isBefore(date1) ? 1 : -1;
      });

      const supportedWebinarTypes = newState.supportedWebinarTypes;
      if (Object.keys(supportedWebinarTypes).length) {
        const experienceTypesSupported = [];
        if (supportedWebinarTypes) {
          for (const supportedWebinarType in supportedWebinarTypes) {
            if (supportedWebinarTypes[supportedWebinarType]) {
              if (supportedWebinarType === 'isSimuliveEnabled') {
                experienceTypesSupported.push(WEBINAR_TYPES.SIMULIVE);
              } else if (supportedWebinarType === 'isAVBroadcastEnabled') {
                experienceTypesSupported.push(WEBINAR_TYPES.WEBCAST);
              } else {
                experienceTypesSupported.push(WEBINAR_TYPES.CLASSIC);
              }
            }
          }
        }
        newUpcomingWebinarSorted = newUpcomingWebinarSorted.filter((event) => experienceTypesSupported.includes(event.experienceType));
      }

      return {
        ...state,
        upcomingWebinar: {
          data: newUpcomingWebinarSorted,
          isFetching: false,
          pagination: {
            limit: action.data.limit,
            page: action.data.page,
            next: action.data.next,
            previous: action.data.previous,
            pageSize: action.data.pageSize,
            total: action.data.total
          }
        }
      };
    }
    case getUpcomingWebinarsAction.failed.toString(): {
      return {
        ...state,
        upcomingWebinar: {
          ...state.upcomingWebinar,
          isFetching: false,
          error: action.message
        }
      };
    }

    case getPastWebinarsAction.toString(): {
      return {
        ...state,
        pastWebinar: {
          ...state.pastWebinar,
          isFetching: true,
          error: undefined
        }
      };
    }
    case getPastWebinarsAction.complete.toString(): {
      return {
        ...state,
        pastWebinar: {
          ...state.pastWebinar,
          data: action.data.webinars,
          pagination: {
            limit: action.data.limit,
            page: action.data.page,
            next: action.data.next,
            previous: action.data.previous,
            pageSize: action.data.pageSize,
            total: action.data.total
          },
          isFetching: false
        }
      };
    }
    case getPastWebinarsAction.failed.toString(): {
      return {
        ...state,
        pastWebinar: {
          ...state.pastWebinar,
          isFetching: true,
          error: action.message
        }
      };
    }
    case getPastFilteredWebinarsAction.toString(): {
      return {
        ...state,
        pastFilteredWebinars: {
          ...state.pastFilteredWebinars,
          isFetching: true,
          error: null
        }
      };
    }
    case getPastFilteredWebinarsAction.complete.toString(): {
      const webinars = [];
      webinars.push(...action.data.webinars);
      return {
        ...state,
        pastFilteredWebinars: {
          ...state.pastFilteredWebinars,
          data: webinars,
          search: action.data.filter,
          pagination: {
            limit: action.data.limit,
            page: action.data.page,
            next: action.data.next,
            previous: action.data.previous,
            pageSize: action.data.pageSize,
            total: action.data.total
          },
          isFetching: false
        }
      };
    }
    case getPastFilteredWebinarsAction.failed.toString(): {
      return {
        ...state,
        pastFilteredWebinars: {
          ...state.pastFilteredWebinars,
          isFetching: false,
          error: action.message
        }
      };
    }
    case getUpcomingFilteredWebinarsAction.toString(): {
      return {
        ...state,
        upcomingFilteredWebinars: {
          ...state.upcomingFilteredWebinars,
          isFetching: true,
          error: null
        }
      };
    }
    case getUpcomingFilteredWebinarsAction.complete.toString(): {
      const webinars = [];
      webinars.push(...action.data.data);
      return {
        ...state,
        upcomingFilteredWebinars: {
          ...state.upcomingFilteredWebinars,
          data: webinars,
          search: action.data.filter,
          pagination: {
            limit: action.data.limit,
            page: action.data.page,
            next: action.data.next,
            previous: action.data.previous,
            pageSize: action.data.pageSize,
            total: action.data.total
          },
          isFetching: false
        }
      };
    }
    case getUpcomingFilteredWebinarsAction.failed.toString(): {
      return {
        ...state,
        upcomingFilteredWebinars: {
          ...state.upcomingFilteredWebinars,
          isFetching: false,
          error: action.message
        }
      };
    }
    case clearPastFilteredWebinarsAction.complete.toString(): {
      return {
        ...state,
        pastFilteredWebinars: {
          ...defaultState.pastFilteredWebinars
        }
      };
    }
    case clearPastFilteredWebinarsAction.failed.toString(): {
      return {
        ...state,
        pastFilteredWebinars: {
          ...defaultState.pastFilteredWebinars
        }
      };
    }
    case clearUpcomingFilteredWebinarsAction.complete.toString(): {
      return {
        ...state,
        upcomingFilteredWebinars: {
          ...defaultState.upcomingFilteredWebinars
        }
      };
    }
    case clearUpcomingFilteredWebinarsAction.failed.toString(): {
      return {
        ...state,
        upcomingFilteredWebinars: {
          ...defaultState.upcomingFilteredWebinars
        }
      };
    }
    case deleteWebinarAction.toString(): {
      return {
        ...state,
        isDeleting: true
      };
    }
    case deleteWebinarAction.complete.toString(): {
      const copySeriesRecurrence = Object.assign({}, state.seriesRecurrence);
      if (action && action.recurrenceKey) {
        const recurrence = Object.assign({}, copySeriesRecurrence[action.recurrenceKey]);
        const webinarTimes = recurrence.webinarTimes.filter((time) => action.webinarKey !== time.webinarKey);
        recurrence.webinarTimes = webinarTimes;
        copySeriesRecurrence[action.recurrenceKey] = recurrence;
      }
      const newUpcomingWebinar = deleteWebinar(action.webinarKey, state.upcomingWebinar.data);
      return {
        ...state,
        upcomingWebinar: {
          ...state.upcomingWebinar,
          data: newUpcomingWebinar
        },
        seriesRecurrence: copySeriesRecurrence,
        isDeleting: false
      };
    }
    case deleteWebinarAction.failed.toString(): {
      return {
        ...state,
        deleteWebinarError: action.error,
        isDeleting: false
      };
    }

    /* ----------- Get Panelists ---------- */
    case getPanelistsAction.toString(): {
      return {
        ...state
      };
    }
    case getPanelistsAction.complete.toString(): {
      state.panelists[action.webinarKey] = action.data[0];
      return {
        ...state
      };
    }
    case getPanelistsAction.failed.toString(): {
      return {
        ...state,
        error: action.message
      };
    }
    /* ----------- WebinarDetails ---------- */
    case getWebinarAction.complete.toString(): {
      const isPastWebinar = getIsPastWebinar(action.webinarKey, state.upcomingWebinar.data, state.pastWebinar.data, action.data);
      const newWebinars = updateWebinar(action.data, action.webinarKey, state, isPastWebinar);
      if (isPastWebinar) {
        return {
          ...state,
          pastWebinar: {
            ...state.pastWebinar,
            data: newWebinars
          }
        };
      }
      return {
        ...state,
        upcomingWebinar: {
          ...state.upcomingWebinar,
          data: newWebinars
        }
      };
    }
    case getWebinarDetailsAction.complete.toString(): {
      const [audio, organizers, branding] = action.data;
      const newAudio = Object.assign({}, state.audio);
      const newBranding = Object.assign({}, state.branding);
      const newOrganizers = Object.assign({}, state.organizers);
      const allNumbers = [];
      for (const countryCode in audio.phoneNumbers) {
        if (Object.prototype.hasOwnProperty.call(audio.phoneNumbers, countryCode)) {
          const numbers = audio.phoneNumbers[countryCode];
          if (numbers.toll) {
            allNumbers.push({ country: countryCode, number: numbers.toll, tollFree: false });
          }
          if (numbers.tollFree) {
            allNumbers.push({ country: countryCode, number: numbers.tollFree, tollFree: true });
          }
        }
      }
      audio.allNumbers = allNumbers;
      newAudio[action.webinarKey] = audio;
      newBranding[action.webinarKey] = branding;
      newOrganizers[action.webinarKey] = organizers;
      return {
        ...state,
        audio: newAudio,
        organizers: newOrganizers,
        branding: newBranding
      };
    }
    case getWebinarDetailsAction.failed.toString(): {
      return {
        ...state,
        detailsError: action.message
      };
    }
    case updateWebinarDetailsAction.toString(): {
      return {
        ...state,
        isUpdatingWebinarDetails: true
      };
    }
    case updateWebinarDetailsAction.complete.toString(): {
      const isPastWebinar = getIsPastWebinar(action.webinarKey, state.upcomingWebinar.data, state.pastWebinar.data, action.details);
      const newWebinars = updateWebinar(action.details, action.webinarKey, state, isPastWebinar);
      if (isPastWebinar) {
        return {
          ...state,
          pastWebinar: {
            ...state.pastWebinar,
            data: newWebinars
          },
          isUpdatingWebinarDetails: false,
          detailsError: null
        };
      }
      return {
        ...state,
        upcomingWebinar: {
          ...state.upcomingWebinar,
          data: newWebinars
        },
        isUpdatingWebinarDetails: false,
        detailsError: null
      };
    }
    case updateWebinarDetailsAction.failed.toString(): {
      return {
        ...state,
        isUpdatingWebinarDetails: false,
        detailsError: action.message
      };
    }
    case getSupportedWebinarTypes.toString(): {
      return {
        ...state,
        loadingSupportedTypes: true
      };
    }
    case getSupportedWebinarTypes.complete.toString(): {
      const supportedWebinarTypes = action.supportedTypes;
      return {
        ...state,
        supportedWebinarTypes,
        loadingSupportedTypes: false
      };
    }
    case getSupportedWebinarTypes.failed.toString(): {
      return {
        ...state,
        loadingSupportedTypes: false
      };
    }
    case updateWebinarBrandingAction.complete.toString(): {
      return {
        ...state,
        branding: {
          ...state.branding,
          [action.webinarKey]: action.data
        }
      };
    }

    /* ----------- Edit Panelists ---------- */
    case editOrganizersAction.toString():
    case editPanelistsAction.toString(): {
      return {
        ...state,
        isEditingSpeaker: true
      };
    }
    case editPanelistsAction.complete.toString(): {
      const [added] = action.data;
      const newPanelists = getEditedSpeakers(state.panelists, action.webinarKey, true, added, action.deleteKeys);
      return {
        ...state,
        isEditingSpeaker: false,
        panelists: newPanelists,
        detailsError: null
      };
    }
    case editPanelistsAction.failed.toString(): {
      return {
        ...state,
        isEditingSpeaker: false,
        detailsError: action.message
      };
    }
    /* ----------- Edit Panelists ---------- */
    case editOrganizersAction.complete.toString(): {
      const [added] = action.data;
      const newOrganizers = getEditedSpeakers(state.organizers, action.webinarKey, false, added, action.deleteKeys);
      return {
        ...state,
        isEditingSpeaker: false,
        organizers: newOrganizers,
        detailsError: null
      };
    }
    case editOrganizersAction.failed.toString(): {
      return {
        ...state,
        isEditingSpeaker: false,
        detailsError: action.message
      };
    }
    case updateOrganizersAction.fulfilled.toString(): {
      const edited = action.payload.updatedOrganizers;
      const newOrganizers = getUpdatedSpeakers(state.organizers, action.payload.webinarKey, edited, action.payload.updatedKeys);
      return {
        ...state,
        isEditingSpeaker: false,
        organizers: newOrganizers,
        detailsError: null
      };
    }

    /* ----------- AudioSettings ---------- */
    case postWebinarAudioSettingsAction.complete.toString(): {
      const [audio] = action.data;
      const newAudio = Object.assign({}, state.audio);
      const allNumbers = [];
      for (const countryCode in audio.phoneNumbers) {
        if (Object.prototype.hasOwnProperty.call(audio.phoneNumbers, countryCode)) {
          const numbers = audio.phoneNumbers[countryCode];
          if (numbers.toll) {
            allNumbers.push({ country: countryCode, number: numbers.toll, tollFree: false });
          }
          if (numbers.tollFree) {
            allNumbers.push({ country: countryCode, number: numbers.tollFree, tollFree: true });
          }
        }
      }
      audio.allNumbers = allNumbers;
      newAudio[action.webinarKey] = audio;
      return {
        ...state,
        audio: newAudio
      };
    }
    case updateWebinarAutoRecordedAction.fulfilled.toString():
    case getAutoRecordedAction.complete.toString(): {
      state.autoRecorded[action.payload.webinarKey] = action.payload.autoRecordEnabled;
      return {
        ...state
      };
    }
    case getOrganizerAudioSettingsAction.toString():
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          audio: {},
          isFetching: true
        }
      };
    case getOrganizerAudioSettingsAction.complete.toString():
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          audio: action.data,
          isFetching: false
        }
      };
    case getOrganizerAudioSettingsAction.failed.toString():
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          isFetching: false
        }
      };
    default: {
      return state;
    }

    /* ------------ Attendance ----------------- */
    case getWebinarAttendanceAction.toString(): {
      const fetchingKeys = [...state.attendance.fetchingKeys];
      fetchingKeys.push(action.webinarKey);
      const failedKeys = [...state.attendance.failedKeys];
      return {
        ...state,
        attendance: {
          ...state.attendance,
          failedKeys: failedKeys.filter((key) => key !== action.webinarKey),
          fetchingKeys
        }
      };
    }
    case getWebinarAttendanceAction.complete.toString(): {
      const fetchingKeys = [...state.attendance.fetchingKeys];
      return {
        ...state,
        attendance: {
          ...state.attendance,
          fetchingKeys: fetchingKeys.filter((key) => key !== action.webinarKey),
          data: {
            ...state.attendance.data,
            [action.webinarKey]: { ...action.attendanceStats }
          }
        }
      };
    }
    case getWebinarAttendanceAction.failed.toString(): {
      const failedKeys = [...state.attendance.failedKeys];
      const fetchingKeys = [...state.attendance.fetchingKeys];
      failedKeys.push(action.webinarKey);
      return {
        ...state,
        attendance: {
          ...state.attendance,
          fetchingKeys: fetchingKeys.filter((key) => key !== action.webinarKey),
          failedKeys
        }
      };
    }

    /* ------------ Start Webinar ----------------- */
    case startWebinarAction.toString(): {
      return {
        ...state,
        isFetchingStartWebinar: true,
        startWebinarError: null
      };
    }

    case startWebinarAction.complete.toString(): {
      return {
        ...state,
        isFetchingStartWebinar: false,
        startWebinarError: null
      };
    }

    case startWebinarAction.failed.toString(): {
      return {
        ...state,
        isFetchingStartWebinar: false,
        startWebinarError: action.message
      };
    }

    /* ------------ Series Recurrence ----------------- */
    case getSeriesRecurrenceAction.toString(): {
      return {
        ...state,
        isFetchingRecurrenceSeries: true
      };
    }

    case getSeriesRecurrenceAction.complete.toString(): {
      const seriesRecurrence = Object.assign({}, state.seriesRecurrence);
      seriesRecurrence[action.recurrenceKey] = action.data;
      return {
        ...state,
        seriesRecurrence,
        isFetchingRecurrenceSeries: false
      };
    }

    case getSeriesRecurrenceAction.failed.toString(): {
      return {
        ...state,
        recurrenceError: action.message,
        isFetchingRecurrenceSeries: false
      };
    }

    case updateSeriesRecurrenceAction.toString(): {
      return {
        ...state,
        isUpdatingRecurrenceSeries: true
      };
    }

    case updateSeriesRecurrenceAction.complete.toString(): {
      const { seriesRecurrence, upcomingWebinar } = updateRecurrenceDetails(state, action);
      return {
        ...state,
        seriesRecurrence,
        upcomingWebinar: {
          ...state.upcomingWebinar,
          data: upcomingWebinar
        },
        isUpdatingRecurrenceSeries: false
      };
    }

    case updateSeriesRecurrenceAction.failed.toString(): {
      return {
        ...state,
        recurrenceError: action.message,
        isUpdatingRecurrenceSeries: false
      };
    }

    case updateSimuliveRecording.toString(): {
      return {
        ...state,
        isUpdatingSimuliveRecording: true
      };
    }

    case updateSimuliveRecording.failed.toString():
    case updateSimuliveRecording.complete.toString(): {
      return {
        ...state,
        isUpdatingSimuliveRecording: false
      };
    }

    case addSimuliveRecordingAction.toString(): {
      const recording = action.selectedRecording;
      recording.webinarKey = action.webinarKey;
      return {
        ...state,
        simuliveRecording: {
          data: recording
        }
      };
    }
  }
};
