// Page names

export const TEALIUM_PAGE_NAMES = {
  DASHBOARD: 'dashboard',
  SETTINGS: 'settings',
  EVENT_DETAILS: 'eventDetails',
  SCHEDULE_WEBINAR: 'scheduleWebinar',
  VIDEO_LIBRARY: 'videoLibrary'
};

export const TEALIUM_PAGE_TYPES = {
  SETTINGS: 'settings',
  CONTENT: 'content',
  HOME: 'home'
};
