import config from 'appConfig';
import mixpanel from 'mixpanel-browser';
import SuperProperty from './Model/superProperty';
import People from './Model/people';

export function initializeMixpanel() {
  const mixpanelConfig = {
    persistence: 'localStorage'
  };

  mixpanel.init(config.mixpanel.token, mixpanelConfig);
  mixpanel.init(config.mixpanel.crossProductToken, mixpanelConfig, 'crossProduct');
}

export function setSuperProperty(superProperty) {
  if (!(superProperty instanceof SuperProperty)) {
    throw new Error('Cannot set super property without an object of SuperProperty instance');
  }

  mixpanel.register(superProperty);
  if (mixpanel.crossProduct) {
    mixpanel.crossProduct.register(superProperty);
  }
}

export function setPeopleProperty(people) {
  if (!(people instanceof People)) {
    throw new Error('Cannot set people property without an object of People instance');
  }
  mixpanel.people.set(people);
}

export function identifyUser(userId) {
  if (typeof userId !== 'string') {
    throw new Error('Cannot identify user without a user Id.');
  }
  mixpanel.identify(userId);
  if (mixpanel.crossProduct) {
    mixpanel.crossProduct.identify(userId);
  }
}

export function optOutUser() {
  mixpanel.opt_out_tracking();
  if (mixpanel.crossProduct) {
    mixpanel.crossProduct.opt_out_tracking();
  }
}

export function optInUser() {
  mixpanel.opt_in_tracking();
  if (mixpanel.crossProduct) {
    mixpanel.crossProduct.opt_in_tracking();
  }
}
