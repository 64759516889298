import { asyncReducer } from 'redux-promise-middleware-actions';
import { getQAndASettings, updateQAndASettings } from './actions';

const getSettingsReducer = asyncReducer(getQAndASettings);
const updateSettingsReducer = asyncReducer(updateQAndASettings);

export default (newState = {}, action) => {
  const state = {
    ...newState,
    ...getSettingsReducer(newState, action),
    ...updateSettingsReducer(newState, action)
  };

  switch (action.type) {
    default: {
      return state;
    }
  }
};
