import { rootSaga as user } from './User';
import { rootSaga as webinar } from './Webinar';
import { rootSaga as registrationSettings } from './RegistrationSettings';
import { rootSaga as recording } from './Recording';
import { rootSaga as survey } from './Survey';
import { rootSaga as poll } from './Poll';
import { rootSaga as registrantsList } from './RegistrantsList';
import { rootSaga as sourceTracking } from './SourceTracking';
import { rootSaga as registrantDetails } from './RegistrantDetails';
import { rootSaga as scheduleWebinar } from './ScheduleWebinar';
import { rootSaga as analytics } from './Analytics';
import { rootSaga as emailSettings } from './EmailSettings';
import { rootSaga as handouts } from './Handouts';
import { rootSaga as settings } from './AccountSettings';
import { rootSaga as associatedRecordings } from './Recording/AssociatedRecordings';
import { rootSaga as videos } from './Videos';
import { rootSaga as recordingReports } from './Recording/Reporting';
import { rootSaga as calendar } from './Calendar';

const sagas = [
  user,
  webinar,
  registrationSettings,
  recording,
  survey,
  poll,
  scheduleWebinar,
  analytics,
  registrantsList,
  sourceTracking,
  registrantDetails,
  settings,
  emailSettings,
  associatedRecordings,
  handouts,
  videos,
  recordingReports,
  calendar
];

export default function registerWithMiddleware(middleware) {
  sagas.forEach((saga) => {
    middleware.run(saga);
  });
}
