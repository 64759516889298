import appLogger from 'Utils/logging';
import config from 'appConfig';
import is from 'is_js';
import { getUserAction } from '../Reducks/User/actions';

const logger = appLogger.create('bold360Middleware');

const bold360Middleware = () => (next) => (action) => {
  if (!is.mobile() && action.type === getUserAction.fulfilled.toString() && action.payload.metadata && action.payload.user && (action.payload.user.locale === 'en_US' || action.payload.user.locale === 'en_GB')) {
    try {
      const _bcvma = window._bcvma || [];
      _bcvma.push(['setAccountID', config.bold360.accountId]);
      _bcvma.push(['setParameter', 'WebsiteID', config.bold360.websiteId]);
      _bcvma.push(['setParameter', 'InvitationID', config.bold360.invitationId]);
      _bcvma.push(['setParameter', 'VisitName', '']);
      _bcvma.push(['setParameter', 'VisitPhone', '']);
      _bcvma.push(['setParameter', 'VisitEmail', '']);
      _bcvma.push(['setParameter', 'VisitRef', '']);
      _bcvma.push(['setParameter', 'VisitInfo', '']);
      _bcvma.push(['setParameter', 'CustomUrl', '']);
      _bcvma.push(['setParameter', 'WindowParameters', '']);
      _bcvma.push(['addFloat', { type: 'chat', id: config.bold360.chatId }]);
      _bcvma.push(['pageViewed']);
      const bcLoad = function bcLoadFunc() {
        if (window.bcLoaded) return;
        window.bcLoaded = true;
        const vms = document.createElement('script');
        vms.type = 'text/javascript';
        vms.async = true;
        const protocol = document.location.protocol === 'https:' ? 'https://' : 'http://';
        vms.src = `${protocol}vmss.boldchat.com/aid/${config.bold360.accountId}/bc.vms4/vms.js`;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(vms, s);
      };
      if (window.pageViewer && window.pageViewer.load) window.pageViewer.load();
      else if (document.readyState === 'complete') bcLoad();
      else if (window.addEventListener) window.addEventListener('load', bcLoad, false);
      else window.attachEvent('onload', bcLoad);

      const nanorep = window.nanorep || {};
      nanorep.floatingWidget = nanorep.floatingWidget || {};
      nanorep.floatingWidget._calls = nanorep.floatingWidget._calls || [];
      nanorep.floatingWidget._calls.push(['init', function setOption() {
        this.setStorageOptions({ crossDomainHelper: 'https://logmeinsupport.nanorep.co/web/assets/_storage.html' });
        nanorep.floatingWidget._calls.push(['load', function setContext() {
          this.setContext({ Version: '2019' });
        }]);
      }]);
    } catch (error) {
      logger.error('Error on Bold 360', error);
    }
  }
  // TODO: Remove this code once the Bold360 widget control is fixed on the portal.
  if (is.mobile()) {
    const bcDOMElement = document.getElementsByClassName('bcFloat')[0];
    if (bcDOMElement) {
      bcDOMElement.style.display = 'none';
    }
  }
  return next(action);
};

export default bold360Middleware;
