// Sub Types
export const REGISTRATION_LIMIT = 'Registration Limit';
export const NOTIFICATION = 'Notification';
export const APPROVED = 'Approved';
export const JOIN_LIMIT = 'Join Limit';
export const CONFIRMATION = 'Confirmation';
export const PASSWORD = 'Password';
export const REGISTRATION_FORM = 'Registration Form';
export const SOURCE_TRACKING = 'Source Tracking';
export const REGISTRANT = 'Registrant';
export const AFTER_SESSION_VIDEO = 'After Session Video';

