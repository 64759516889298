import appLogger from 'Utils/logging';
import moment from 'moment';
import { startMode } from 'Components/Card/Events/startWebinarHelper';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getOrganizerWebinarSettingsAction } from 'Reducks/AccountSettings/actions';
import { getMessagesByTag } from 'Reducks/MessageCenterNotification/actions';
import { startWebinarAction, getUpcomingWebinarsAction, getWebinarAttendanceAction } from '../Reducks/Webinar/actions';
import { scheduleWebinarAction } from '../Reducks/ScheduleWebinar/actions';
import { getUserAction } from '../Reducks/User/actions';
import { getApplicationState, setApplicationState } from '../Reducks/AppState/actions';
import { createAndUpdateChannelAction, publishVideoToChannelAction } from '../Reducks/Channel/actions';
import { reportAppcuesEventAction } from '../Reducks/Appcues/actions';

const logger = appLogger.create('appcuesMiddleware');

function getAccountsAgeInDays(date) {
  const end = moment(date);
  const start = moment();
  return Math.round(moment.duration(start.diff(end)).asDays());
}

const appCuesIdentify = (userKey, value) => {
  try {
    window.Appcues.identify(userKey, value);
  } catch (error) {
    logger.error('Error on Appcues identify', error);
  }
};

const appcuesPage = () => {
  try {
    window.Appcues.page();
  } catch (error) {
    logger.error('Error on Appcues page', error);
  }
};

const supportedEvents = [
  'flow_started',
  'flow_completed',
  'flow_skipped',
  'step_started',
  'step_completed',
  'step_skipped',
  'step_interacted',
  'form_submitted',
  'form_field_submitted'
];

const setupTracking = (dispatch) => {
  if (window.Appcues) {
    window.Appcues.on('all', (name, event) => {
      if (supportedEvents.includes(name)) {
        dispatch(reportAppcuesEventAction(name, event));
      }
    });
  }
};

const getAppcues = (state) => {
  if (state && state.appState && state.appState.appcues) {
    return state.appState.appcues;
  }
  return {};
};

const setAppcues = (state, data) => {
  if (state && state.appState && state.appState.appcues) {
    return {
      appcues: {
        ...state.appState.appcues,
        ...data
      }
    };
  }
  return {
    appcues: {
      ...data
    }
  };
};

/* eslint-disable default-case */
const appcuesMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      appcuesPage();
      break;
    }
    case getUserAction.fulfilled.toString(): {
      if (action.payload && action.payload.metadata && action.payload.user) {
        const metadata = action.payload.metadata;
        const user = action.payload.user;
        const accountAgeDays = getAccountsAgeInDays(user.meta.created);
        const newUser = (moment(user.meta.created)).isAfter(moment('2018-09-04T07:00:00.000Z')); // 9/4/2018 e2e was ga
        const userObj = { accountKey: metadata.accountKey,
          seats: metadata.seats,
          plan: metadata.description,
          type: metadata.channel,
          language: user.locale,
          createdAtDate: user.meta.created,
          accountAgeDays,
          newUser,
          serviceType: metadata.serviceType };

        appCuesIdentify(metadata.userKey, userObj);
        setupTracking(dispatch);
      }
      break;
    }

    case getApplicationState.fulfilled.toString():
    case setApplicationState.fulfilled.toString(): {
      let appState = { ...action.payload };
      if (action.type === getApplicationState.fulfilled.toString()) {
        appState = action.payload.data;
      }
      const userKey = getState().user.metadata.userKey;
      if (appState && appState.appcues && Object.keys(appState.appcues).length > 0) {
        const completed = appState.onboarding && appState.onboarding.onboardingTipsIndex && appState.onboarding.onboardingTipsIndex === -1;
        Object.assign(appState.appcues, { oldOnBoardingCompleted: completed });
        appCuesIdentify(userKey, appState.appcues);
      }
      break;
    }

    case 'SET_FLAGS': {
      const state = getState();
      if (state && state.user && state.user.metadata && state.user.metadata.userKey && action.data && action.data.isLDReady) {
        const userKey = state.user.metadata.userKey;
        appCuesIdentify(userKey, { scheduleV2Enabled: action.data.schedulingV2Enabled });
      }

      break;
    }

    case getUpcomingWebinarsAction.complete.toString(): {
      const upcomingWebinars = action.data && action.data.data ? action.data.data : [];
      const userKey = getState().user.metadata.userKey;

      appCuesIdentify(userKey, { upcomingWebinarsCount: upcomingWebinars.length });
      break;
    }

    case startWebinarAction.complete.toString(): {
      const state = getState();
      const isPractice = action.mode === startMode.PRACTICE;
      let data = null;
      if (getAppcues(state).hasRunPracticeSession !== true && isPractice) {
        data = setAppcues(state, { hasRunPracticeSession: true });
      }
      if (getAppcues(state).hasRunLiveSession !== true && !isPractice) {
        data = setAppcues(state, { hasRunLiveSession: true });
      }

      if (data) {
        dispatch(setApplicationState(data));
      }

      break;
    }

    case scheduleWebinarAction.complete.toString(): {
      const state = getState();
      if (getAppcues(state).hasScheduledWebinar !== true) {
        const data = setAppcues(state, { hasScheduledWebinar: true });
        dispatch(setApplicationState(data));
      }

      break;
    }

    case createAndUpdateChannelAction.fulfilled.toString(): {
      const state = getState();
      if (getAppcues(state).hasCreatedChannel !== true) {
        const data = setAppcues(state, { hasCreatedChannel: true });
        dispatch(setApplicationState(data));
      }

      break;
    }

    case publishVideoToChannelAction.fulfilled.toString(): {
      const state = getState();
      if (getAppcues(state).hasPublishedVideo !== true) {
        const data = setAppcues(state, { hasPublishedVideo: true });
        dispatch(setApplicationState(data));
      }
      break;
    }
    case getOrganizerWebinarSettingsAction.fulfilled.toString(): {
      const state = getState();
      if (state && state.user && state.user.metadata && state.user.metadata.userKey && action.payload) {
        appCuesIdentify(state.user.metadata.userKey, { isTranscriptsEnabled: action.payload.transcriptsEnabled || false });
      }
      break;
    }
    case getMessagesByTag.fulfilled.toString(): {
      const state = getState();
      const tag = action.payload.tag;
      const messages = action.payload.data;
      if (tag === 'RegistrationLimitReached' && messages.length > 0) {
        appCuesIdentify(state.user.metadata.userKey, { registrationLimitReached: true });
      }
      break;
    }

    case getWebinarAttendanceAction.complete.toString(): {
      const state = getState();
      const { attendanceStats } = action;
      const { user } = state;
      const { metadata } = user || {};
      const { maxAttendees, userKey } = metadata || {};
      const attendeeCount = (attendanceStats && attendanceStats.attendeeCount) || 0;
      if (maxAttendees) {
        const ratio = (attendeeCount / maxAttendees) * 100;
        if (ratio >= 75) {
          appCuesIdentify(userKey, { attendeeLimitReached: true });
        }
      }
      break;
    }
  }
  return next(action);
};

export default appcuesMiddleware;
