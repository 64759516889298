export const CREATE_WEBINAR_SURVEY_START = 'CREATE_WEBINAR_SURVEY_START';
export const CREATE_WEBINAR_SURVEY_COMPLETE = 'CREATE_WEBINAR_SURVEY_COMPLETE';
export const CREATE_WEBINAR_SURVEY_FAILURE = 'CREATE_WEBINAR_SURVEY_FAILURE';

export const DELETE_WEBINAR_SURVEY_START = 'DELETE_WEBINAR_SURVEY_START';
export const DELETE_WEBINAR_SURVEY_COMPLETE = 'DELETE_WEBINAR_SURVEY_COMPLETE';
export const DELETE_WEBINAR_SURVEY_FAILURE = 'DELETE_WEBINAR_SURVEY_FAILURE';

export const RETRIEVE_WEBINAR_SURVEY_START = 'RETRIEVE_WEBINAR_SURVEY_START';
export const RETRIEVE_WEBINAR_SURVEY_COMPLETE = 'RETRIEVE_WEBINAR_SURVEY_COMPLETE';
export const RETRIEVE_WEBINAR_SURVEY_FAILURE = 'RETRIEVE_WEBINAR_SURVEY_FAILURE';

// TODO: Delete these constants if not needed
export const RETRIEVE_WEBINAR_SURVEY_INFO_START = 'RETRIEVE_WEBINAR_SURVEY_INFO_START';
export const RETRIEVE_WEBINAR_SURVEY_INFO_COMPLETE = 'RETRIEVE_WEBINAR_SURVEY_INFO_COMPLETE';
export const RETRIEVE_WEBINAR_SURVEY_INFO_FAILURE = 'RETRIEVE_WEBINAR_SURVEY_INFO_FAILURE';

export const RETRIEVE_WEBINAR_SURVEY_RESPONSES_START = 'RETRIEVE_WEBINAR_SURVEY_RESPONSES_START';
export const RETRIEVE_WEBINAR_SURVEY_RESPONSES_COMPLETE = 'RETRIEVE_WEBINAR_SURVEY_RESPONSES_COMPLETE';
export const RETRIEVE_WEBINAR_SURVEY_RESPONSES_FAILURE = 'RETRIEVE_WEBINAR_SURVEY_RESPONSES_FAILURE';

export const RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_START = 'RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_START';
export const RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_COMPLETE = 'RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_COMPLETE';
export const RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_FAILURE = 'RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_FAILURE';
