import errors from 'Api/Helpers/apiErrors.json';
import { call, put, all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { history } from 'Store/configureStore';
import { getRegistrationSettingsAction } from 'Reducks/RegistrationSettings/actions';
import * as webinarService from '../../Api/Webinar/webinarService';
import { constructTimeAndTimeZone } from './webinarDetailsHelper';
import { getAttendanceStats } from '../../Api/reportingService';

import { getUpcomingWebinarsAction,
  getPastWebinarsAction,
  getPastFilteredWebinarsAction,
  clearPastFilteredWebinarsAction,
  getUpcomingFilteredWebinarsAction,
  clearUpcomingFilteredWebinarsAction,
  deleteWebinarAction,
  getPanelistsAction,
  getWebinarDetailsAction,
  updateWebinarDetailsAction,
  postWebinarAudioSettingsAction,
  getAutoRecordedAction,
  editPanelistsAction,
  editOrganizersAction,
  getSupportedWebinarTypes,
  getWebinarAttendanceAction,
  startWebinarAction,
  getSeriesRecurrenceAction,
  updateSeriesRecurrenceAction,
  updateWebinarBrandingAction,
  updateSimuliveRecording,
  getSimuliveRecording,
  getWebinarAction, getCalendarDetailsAction } from './actions';
import { SEND_NOTIFICATION } from '../Notification/actions';

export function* fetchUpcomingWebinars({ page, pageSize }) {
  try {
    const data = yield call(webinarService.getUpcomingWebinars, page, pageSize);
    yield put({ type: getUpcomingWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getUpcomingWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchPastWebinars({ page, pageSize }) {
  try {
    const data = yield call(webinarService.getPastWebinars, page, pageSize);
    yield put({ type: getPastWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getPastWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchPastFilteredWebinars({
  filter, fromTime, toTime, page, pageSize
}) {
  try {
    let data = yield call(webinarService.getPastFilteredWebinars, filter, fromTime, toTime, page, pageSize);
    data = { ...data, filter };
    yield put({ type: getPastFilteredWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getPastFilteredWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* clearPastFilteredWebinars() {
  try {
    const data = {};
    yield put({ type: clearPastFilteredWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: clearPastFilteredWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchUpcomingFilteredWebinars({
  filter, page, pageSize
}) {
  try {
    let data = yield call(webinarService.getUpcomingWebinarsFiltered, filter, page, pageSize);
    data = { ...data, filter };
    yield put({ type: getUpcomingFilteredWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getUpcomingFilteredWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* clearUpcomingFilteredWebinars() {
  try {
    const data = {};
    yield put({ type: clearUpcomingFilteredWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: clearUpcomingFilteredWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* deleteWebinar({
  webinarKey, sendEmails, body, recurrenceKey, redirectToDashboard
}) {
  try {
    const data = yield call(webinarService.deleteWebinarWithEmail, webinarKey, sendEmails, body);
    if (sendEmails) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarSuccessWithEmailSent' });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarSuccessWithNoEmailSent' });
    }
    yield put({
      type: deleteWebinarAction.complete.toString(), webinarKey, data, recurrenceKey, redirectToDashboard
    });
  } catch (e) {
    yield put({ type: deleteWebinarAction.failed.toString(), error: e });
    switch (e.body.description) {
      case errors.api.schedule.inSession:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarInSessionFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarFail', isError: true });
    }
  }
}

function* fetchPanelists({ webinarKey }) {
  try {
    const data = yield call(webinarService.getWebinarDetails, webinarKey, ['panelists']);
    yield put({ type: getPanelistsAction.complete.toString(), data, webinarKey });
  } catch (e) {
    yield put({
      type: getPanelistsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchWebinarDetails({ webinarKey, details }) {
  try {
    const data = yield call(webinarService.getWebinarDetails, webinarKey, details);
    yield put({ type: getWebinarDetailsAction.complete.toString(), data, webinarKey });
  } catch (e) {
    yield put({
      type: getWebinarDetailsAction.failed.toString(),
      message: e.message
    });
  }
}

function* updateWebinarDetails({ webinarKey, details, notifyParticipants }) {
  try {
    const data = yield call(webinarService.updateWebinarDetails, webinarKey, details, notifyParticipants);
    yield put({
      type: updateWebinarDetailsAction.complete.toString(), data, details, webinarKey
    });
    if (notifyParticipants) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.updateSuccessWithEmailSent' });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.updateSuccessWithoutEmail' });
    }
  } catch (e) {
    yield put({ type: updateWebinarDetailsAction.failed.toString(), message: e.message });

    switch (e.body.Details) {
      case errors.api.schedule.timeInPast:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
        break;
      case errors.api.schedule.endBeforeStart:
      case errors.api.schedule.recurrenceEndBeforeStart:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.endTimeAfterStartTimeFail', isError: true });
        break;
      case errors.api.schedule.overYearAhead:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.startTimeYearAheadFail', isError: true });
        break;
      case errors.api.schedule.invalidSubject:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTitleFail', isError: true });
        break;
      case errors.api.schedule.invalidDescription:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTitleFail', isError: true });
        break;
      case errors.api.schedule.maximumAllowedEvent:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maximumUpcomingEventsAllowed', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.updateFail', isError: true });
    }
  }
}

function* editPanelists({ webinarKey, panelists, deleteKeys }) {
  try {
    const data = yield call(webinarService.editPanelist, webinarKey, panelists, deleteKeys);
    yield put({
      type: editPanelistsAction.complete.toString(), data, deleteKeys, webinarKey
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.panelistsUpdateSuccess' });
  } catch (e) {
    yield put({ type: editPanelistsAction.failed.toString(), message: e.message });

    switch (e.body.Details) {
      case errors.api.schedule.invalidEmail:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidEmailFail', isError: true });
        break;
      case errors.api.schedule.maxPanelistsCount:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.panelistsMaxLimitReached', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.panelistsUpdateFail', isError: true });
    }
  }
}

function* editOrganizers({ webinarKey, organizers, deleteKeys }) {
  try {
    const data = yield call(webinarService.editOrganizer, webinarKey, organizers, deleteKeys);
    yield put({
      type: editOrganizersAction.complete.toString(), data, deleteKeys, webinarKey
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.organizersUpdateSuccess' });
  } catch (e) {
    yield put({ type: editOrganizersAction.failed.toString(), message: e.message });

    switch (e.body.Details) {
      case errors.api.schedule.invalidEmail:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidEmailFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.organizersUpdateFail', isError: true });
    }
  }
}

function* updateWebinarAudioSettings({ webinarKey, audioDetails, notifyParticipants }) {
  try {
    yield call(webinarService.updateWebinarAudioSettings, webinarKey, audioDetails, notifyParticipants);
    const data = yield call(webinarService.getWebinarDetails, webinarKey, ['audio']);
    yield put({ type: postWebinarAudioSettingsAction.complete.toString(), data, webinarKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'audioSettings.audioSaved' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
    yield put({
      type: postWebinarAudioSettingsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchWebinarAutoRecorded({ organizerKey, webinarKey }) {
  try {
    const isAutoRecorded = yield call(webinarService.getWebinarAutoRecorded, organizerKey, webinarKey);
    yield put({ type: getAutoRecordedAction.complete.toString(), payload: { autoRecordEnabled: isAutoRecorded, webinarKey } });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: getAutoRecordedAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchSupportedWebinarTypes({ accountKey, userKey }) {
  try {
    const supportedTypes = yield call(webinarService.getSupportedWebinarTypes, accountKey, userKey);
    yield put({ type: getSupportedWebinarTypes.complete.toString(), supportedTypes });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: getSupportedWebinarTypes.failed.toString(),
      message: e.message
    });
  }
}

export function* getCalendarDetails({ webinarKey, userKey, sourceUrl }) {
  try {
    const downloadUrl = yield call(webinarService.getCalendarDetails, webinarKey, userKey, sourceUrl);
    yield put({ type: getCalendarDetailsAction.complete.toString(), downloadUrl });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: getCalendarDetailsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchWebinarAttendance({ webinarKey }) {
  try {
    const attendanceStats = yield call(getAttendanceStats, webinarKey);
    yield put({ type: getWebinarAttendanceAction.complete.toString(), webinarKey, attendanceStats });
  } catch (e) {
    yield put({
      type: getWebinarAttendanceAction.failed.toString(),
      message: e.message,
      webinarKey
    });
  }
}

function* startWebinar({
  webinarKey, successUrl, mode, authCode, redirectUrl
}) {
  try {
    const data = yield call(webinarService.startWebinar, webinarKey, successUrl, mode, authCode, redirectUrl);
    data.authCode = authCode;
    yield put({ type: startWebinarAction.complete.toString(), data, mode });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.startWebinarFail', isError: true });
    yield put({
      type: startWebinarAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchSeriesRecurrence({ recurrenceKey }) {
  try {
    const data = yield call(webinarService.getSeriesRecurrence, recurrenceKey);
    yield put({ type: getSeriesRecurrenceAction.complete.toString(), data, recurrenceKey });
  } catch (e) {
    yield put({ type: getSeriesRecurrenceAction.failed.toString(), message: e.message });
  }
}

export function* updateSeriesRecurrence({
  webinarKey, recurrenceKey, added, modified, notifyParticipants
}) {
  try {
    const [modifiedData, addedData] = yield all([
      modified && modified.length > 0 ? modified.map((details) => call(webinarService.updateWebinarDetails, details.webinarKey,
        constructTimeAndTimeZone(details), notifyParticipants)) : null,
      added && added.length > 0 ? added.map((details) => call(webinarService.updateSeriesRecurrence, recurrenceKey,
        { times: [details], timeZone: details.timeZone }, notifyParticipants)) : null
    ]);
    yield put({
      type: updateSeriesRecurrenceAction.complete.toString(), webinarKey, addedData, modifiedData, added, modified, recurrenceKey
    });
    if (notifyParticipants) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.editDateAndTime.updateSuccessWithEmailSent', isError: false });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.editDateAndTime.updateSuccessWithoutEmail', isError: false });
    }
  } catch (e) {
    if (e.status === 400) {
      switch (e.body.Details) {
        case errors.api.schedule.timeInPast:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
          break;
        case errors.api.schedule.overlapingWebinarTimes:
        case errors.api.schedule.overlapingWebinarSeriesTime:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTimeWebinarsOverlap', isError: true });
          break;
        case errors.api.schedule.overYearAhead:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.startTimeYearAheadFail', isError: true });
          break;
        case errors.api.schedule.maximumAllowedEvent:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maximumUpcomingEventsAllowed', isError: true });
          break;
        default:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.editDateAndTime.updateFailed', isError: true });
          break;
      }
    }
    yield put({ type: updateSeriesRecurrenceAction.failed.toString(), message: e.message });
  }
}

function* updateWebinarBranding({ webinarKey, brandingData }) {
  try {
    yield call(webinarService.updateBranding, webinarKey, brandingData);
    const data = yield call(webinarService.getWebinarDetails, webinarKey, ['branding']);
    yield put({ type: updateWebinarBrandingAction.complete.toString(), webinarKey, data: data[0] });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.brandingUpdated' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

export function* updateSimuliveWebinarRecording({ webinarKey, selectedRecording }) {
  try {
    yield call(webinarService.updateRecordingForSimuliveWebinar, webinarKey, selectedRecording.assetKey);
    yield put({ type: updateSimuliveRecording.complete.toString() });
    yield put({ type: getSimuliveRecording.fulfilled.toString(), payload: selectedRecording });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.recordingEvent.recordingUpdated' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

function* fetchWebinar({ webinarKey, organizerKey, shouldLoadWebinar }) {
  if (shouldLoadWebinar) {
    try {
      const data = yield call(webinarService.getWebinar, webinarKey);
      if (organizerKey === data.organizerKey) {
        yield put({ type: getWebinarAction.complete.toString(), data, webinarKey });
      } else {
        try {
          const coOrganizers = yield call(webinarService.getWebinarCoOrganizers, webinarKey);
          const internalOrganizerKeys = coOrganizers.filter((o) => (o.external !== 'true'));
          const mappedInternalOrganizerKeys = internalOrganizerKeys.map((o) => (o.organizerKey));

          if (mappedInternalOrganizerKeys) {
            if (mappedInternalOrganizerKeys.includes(organizerKey)) {
              yield put({ type: getWebinarAction.complete.toString(), data, webinarKey });
            }

            try {
              yield put({ type: getPanelistsAction.toString(), webinarKey });
              yield put({ type: getWebinarDetailsAction.toString(), webinarKey, details: ['audio', 'coorganizers', 'branding'] });
              yield put({ type: getAutoRecordedAction.toString(), organizerKey, webinarKey });
              yield put({ type: getRegistrationSettingsAction.toString(), webinarKey });
            } catch (e) {
              yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
            }
            return;
          }

          history.replace({ pathname: '/error', state: { titleKey: 'cards.webinar.details.unauthorizedWebinar' } });
          yield put({ type: getWebinarAction.failed.toString(), webinarKey, message: 'unauthorized' });
          return;
        } catch (e) {
          history.replace({ pathname: '/error', state: { titleKey: 'cards.webinar.details.unauthorizedWebinar' } });
          yield put({ type: getWebinarAction.failed.toString(), webinarKey, message: 'unauthorized' });
          return;
        }
      }
    } catch (e) {
      history.replace({ pathname: '/error', state: { titleKey: 'cards.webinar.details.notFound' } });
      yield put({ type: getWebinarAction.failed.toString(), webinarKey, message: e.message });
      return;
    }
  }

  try {
    yield put({ type: getPanelistsAction.toString(), webinarKey });
    yield put({ type: getWebinarDetailsAction.toString(), webinarKey, details: ['audio', 'coorganizers', 'branding'] });
    yield put({ type: getAutoRecordedAction.toString(), organizerKey, webinarKey });
    yield put({ type: getRegistrationSettingsAction.toString(), webinarKey });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
  }
}

// ------------------------------------ WATCHERS -----------------------------------
export function* watchFetchUpcomingWebinar() {
  yield takeLatest(getUpcomingWebinarsAction.toString(), fetchUpcomingWebinars);
}

export function* watchFetchPastWebinar() {
  yield takeLatest(getPastWebinarsAction.toString(), fetchPastWebinars);
}

export function* watchFetchPastFilteredWebinars() {
  yield takeLatest(getPastFilteredWebinarsAction.toString(), fetchPastFilteredWebinars);
}

export function* watchClearPastFilteredWebinars() {
  yield takeLatest(clearPastFilteredWebinarsAction.toString(), clearPastFilteredWebinars);
}

export function* watchFetchupcomingFilteredWebinars() {
  yield takeLatest(getUpcomingFilteredWebinarsAction.toString(), fetchUpcomingFilteredWebinars);
}

export function* watchClearUpcomingFilteredWebinars() {
  yield takeLatest(clearUpcomingFilteredWebinarsAction.toString(), clearUpcomingFilteredWebinars);
}

export function* watchFetchSupportedWebinarTypes() {
  yield takeLatest(getSupportedWebinarTypes.toString(), fetchSupportedWebinarTypes);
}

export function* watchDeleteWebinar() {
  yield takeLatest(deleteWebinarAction.toString(), deleteWebinar);
}

export function* watchFetchPanelists() {
  yield takeEvery(getPanelistsAction.toString(), fetchPanelists);
}

export function* watchFetchWebinarDetails() {
  yield takeLatest(getWebinarDetailsAction.toString(), fetchWebinarDetails);
}

export function* watchUpdateWebinarDetails() {
  yield takeLatest(updateWebinarDetailsAction.toString(), updateWebinarDetails);
}

export function* watchUpdateWebinarAudioSettings() {
  yield takeLatest(postWebinarAudioSettingsAction.toString(), updateWebinarAudioSettings);
}

export function* watchFetchWebinarAutoRecorded() {
  yield takeLatest(getAutoRecordedAction.toString(), fetchWebinarAutoRecorded);
}

export function* watchEditPanelists() {
  yield takeLatest(editPanelistsAction.toString(), editPanelists);
}

export function* watchEditOrganizers() {
  yield takeLatest(editOrganizersAction.toString(), editOrganizers);
}

export function* watchFetchWebinarAttendance() {
  yield takeEvery(getWebinarAttendanceAction.toString(), fetchWebinarAttendance);
}

export function* watchStartWebinar() {
  yield takeLatest(startWebinarAction.toString(), startWebinar);
}

export function* watchSeriesRecurrence() {
  yield takeLatest(getSeriesRecurrenceAction.toString(), fetchSeriesRecurrence);
}

export function* watchUpdateSeriesRecurrence() {
  yield takeLatest(updateSeriesRecurrenceAction.toString(), updateSeriesRecurrence);
}

export function* watchUpdateWebinarBranding() {
  yield takeLatest(updateWebinarBrandingAction.toString(), updateWebinarBranding);
}

export function* watchFetchWebinar() {
  yield takeLatest(getWebinarAction.toString(), fetchWebinar);
}

export function* watchGetCalendarDetailsForWebinar() {
  yield takeLatest(getCalendarDetailsAction.toString(), getCalendarDetails);
}

export function* watchUpdateRecordingForSimulive() {
  yield takeLatest(updateSimuliveRecording.toString(), updateSimuliveWebinarRecording);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchUpcomingWebinar),
    fork(watchFetchPastWebinar),
    fork(watchFetchPastFilteredWebinars),
    fork(watchFetchupcomingFilteredWebinars),
    fork(watchClearPastFilteredWebinars),
    fork(watchClearUpcomingFilteredWebinars),
    fork(watchDeleteWebinar),
    fork(watchFetchPanelists),
    fork(watchFetchWebinarDetails),
    fork(watchUpdateWebinarDetails),
    fork(watchUpdateWebinarAudioSettings),
    fork(watchEditPanelists),
    fork(watchEditOrganizers),
    fork(watchFetchWebinarAutoRecorded),
    fork(watchFetchSupportedWebinarTypes),
    fork(watchFetchWebinarAttendance),
    fork(watchStartWebinar),
    fork(watchGetCalendarDetailsForWebinar),
    fork(watchSeriesRecurrence),
    fork(watchUpdateSeriesRecurrence),
    fork(watchUpdateWebinarBranding),
    fork(watchUpdateRecordingForSimulive),
    fork(watchFetchWebinar)
  ]);
}
