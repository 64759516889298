/* eslint-disable max-len */

// regular expression
export const emailRegex = /^(?!.*\.\..*)^([_.\-!#$%&'*+/=?^`{|}~a-z0-9]{1,64})@([.\-\da-z]+)\.([a-z]{2,24}|[\d]{3})$/i;
export const emailRegexAllowEmptyAtStart = /^(?!.*\.\..*)^[\s]{0,10}([_.\-!#$%&'*+/=?^`{|}~a-z0-9]{1,64})@([.\-\da-z]+)\.([a-z]{2,24}|[\d]{3})$/i;
export const nameRegex = /^[a-zÀ-ż0-9 ,.'-]+$/i;
export const nameRegexAllowEmptyAtStart = /^\s*[^<>{|}][a-zÀ-ż0-9 ,.'-]+$/i;
export const presenterRegex = /^[a-zÀ-ż0-9 ,.&/#\\'-]+$/i;
export const presenterRegexAllowEmpty = /^[a-zÀ-ż0-9 ,.&/#\\'-]*$/i;
export const channelRegex = /^\s*[a-zÀ-ż0-9 /\\,.!#@$%&'"*+=?^_`;:(|)~-]*$/i;
export const timeRegex = /^(0?[1-9]|1[012]):[0-5][0-9]$/;
export const urlRegex = /^(?!.*<script>.*)^((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/i;
export const httpsRegex = /((https:\/\/))/i;
export const httpRegex = /((http?:\/\/))/i;
export const anyCharRegex = /([^\s])/;
export const numbersExceptZeroRegex = /^[0-9]*[1-9][0-9]*$/;
export const numbersOnlyRegex = /^[0-9]+$/;
export const youtubeIdRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
export const lineBreakRegex = /\r?\n|\r/g;
export const threeDigitsRegex = /(\d{3})/g;
export const registrationSourceRegex = nameRegex;
export const stripeUrlCodeRegex = /[?&]code=([^&#]*)/i;
export const alphanumericRegex = /^[a-z0-9]+$/i;
export const numbersGreaterThan1WithOptionaldecimalRegex = /^[1-9]\d{0,3}(\.\d{0,2})?$/;
export const numbersGreaterThan0Regex = /^[0-9]\d{0,3}$/;
export const unsafeCharactersRegex = /[<>:"/\\|?*.#[\]@!$&'()+,;={}^~`%]/g;

// date formats
export const webinarDayDateFormat = 'ddd, MMM D, YYYY';
export const webinarTimeDateFormat = 'hh:mm A';

