import { createAsyncAction } from '../reduxActions';

export const getRegistrationSettingsAction = createAsyncAction('GET_REGISTRATION_SETTINGS');
export const updateRegistrationSettingsAction = createAsyncAction('UPDATE_REGISTRATION_SETTINGS');
export const getCustomDisclaimerAction = createAsyncAction('GET_CUSTOM_DISCLAIMER');

export function fetchRegistrationSettings(webinarKey, details) {
  return { type: getRegistrationSettingsAction.toString(), webinarKey, details };
}

export function updateRegistrationSettings(webinarKey, details, registrationType) {
  return {
    type: updateRegistrationSettingsAction.toString(), webinarKey, details, registrationType
  };
}

export function getCustomDisclaimer(webinarKey) {
  return { type: getCustomDisclaimerAction.toString(), webinarKey };
}

