import * as Actions from './constants';
import sanitizeSurveyData from './surveyHelper';

const initialState = {
  webinarSurvey: {
    isFetching: false,
    obj: {},
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CREATE_WEBINAR_SURVEY_START: {
      return {
        ...state,
        webinarSurvey: {
          isFetching: true,
          obj: action.survey
        }
      };
    }
    case Actions.CREATE_WEBINAR_SURVEY_COMPLETE: {
      const surveyObjCopy = Object.assign({}, state.webinarSurvey.obj);
      surveyObjCopy.key = action.data.key;
      return {
        ...state,
        webinarSurvey: {
          isFetching: false,
          obj: surveyObjCopy
        }
      };
    }
    case Actions.CREATE_WEBINAR_SURVEY_FAILURE: {
      const webinarSurveyCopy = Object.assign({}, state.webinarSurvey);
      webinarSurveyCopy.isFetching = false;
      webinarSurveyCopy.error = action.error;
      return {
        ...state,
        webinarSurvey: webinarSurveyCopy
      };
    }
    case Actions.DELETE_WEBINAR_SURVEY_START: {
      const webinarSurveyCopy = Object.assign({}, state.webinarSurvey);
      webinarSurveyCopy.isFetching = false;
      webinarSurveyCopy.error = null;
      return {
        ...state,
        webinarSurvey: webinarSurveyCopy
      };
    }
    case Actions.DELETE_WEBINAR_SURVEY_COMPLETE: {
      return {
        ...state,
        webinarSurvey: {
          isFetching: false,
          obj: {},
          error: {}
        }
      };
    }
    case Actions.DELETE_WEBINAR_SURVEY_FAILURE: {
      const webinarSurveyCopy = Object.assign({}, state.webinarSurvey);
      webinarSurveyCopy.isFetching = false;
      webinarSurveyCopy.error = action.error;
      return {
        ...state,
        webinarSurvey: webinarSurveyCopy
      };
    }
    case Actions.RETRIEVE_WEBINAR_SURVEY_START: {
      const webinarSurveyCopy = Object.assign({}, state.webinarSurvey);
      webinarSurveyCopy.isFetching = true;
      return {
        ...state,
        webinarSurvey: webinarSurveyCopy
      };
    }
    case Actions.RETRIEVE_WEBINAR_SURVEY_COMPLETE: {
      const webinarSurveyCopy = Object.assign({}, state.webinarSurvey);
      webinarSurveyCopy.isFetching = false;
      webinarSurveyCopy.obj = sanitizeSurveyData(action.data);
      webinarSurveyCopy.error = null;
      return {
        ...state,
        webinarSurvey: webinarSurveyCopy
      };
    }
    case Actions.RETRIEVE_WEBINAR_SURVEY_FAILURE: {
      const webinarSurveyCopy = Object.assign({}, state.webinarSurvey);
      webinarSurveyCopy.isFetching = false;
      webinarSurveyCopy.obj = {};
      webinarSurveyCopy.error = action.error;
      return {
        ...state,
        webinarSurvey: webinarSurveyCopy
      };
    }
    default:
      return state;
  }
};
