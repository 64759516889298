import { NOTIFICATION_STYLE } from 'Containers/Notification';
import { SEND_NOTIFICATION, showAlert } from './actions';

export default (state = {}, action) => {
  switch (action.type) {
    case SEND_NOTIFICATION: {
      return {
        ...state,
        style: NOTIFICATION_STYLE.NORMAL,
        messageKey: action.messageKey,
        messageKeyParams: action.messageKeyParams,
        message: action.message,
        isError: action.isError
      };
    }

    case showAlert.toString(): {
      return {
        ...state,
        style: NOTIFICATION_STYLE.ALERT,
        alertType: action.payload.alertType,
        alertTitle: action.payload.alertTitle,
        alertMessage: action.payload.alertMessage,
        closeButtonTitle: action.payload.closeButtonTitle,
        secondButton: action.payload.secondButton
      };
    }

    default: {
      return state;
    }
  }
};
