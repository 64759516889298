import * as actions from './actions';
import { generateAudioWaveform } from '../VideoEditor/actions';

const defaultState = {
  eventTabIndex: 0,
  hideVideoEditingTrimAlert: false,
  isEventCompactView: false,
  modal: {},
  pastEventPageIndex: 0,
  showAllEvents: false,
  upcomingEventPageIndex: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.eventTabChangedAction.toString(): {
      return {
        ...state,
        eventTabIndex: action.index
      };
    }
    case actions.pastEventPageChangedAction.toString(): {
      return {
        ...state,
        pastEventPageIndex: action.pageIndex
      };
    }
    case actions.upcomingEventPageChangedAction.toString(): {
      return {
        ...state,
        upcomingEventPageIndex: action.pageIndex
      };
    }
    case actions.showAllEventsAction.toString(): {
      return {
        ...state,
        showAllEvents: action.payload
      };
    }
    case actions.hideVideoEditingTrimAlert.toString(): {
      return {
        ...state,
        hideVideoEditingTrimAlert: true
      };
    }
    case actions.toggleModal.toString(): {
      return {
        ...state,
        modal: action.payload
      };
    }
    case generateAudioWaveform.pending.toString(): {
      return {
        ...state,
        modal: {
          ...state.modal,
          hasInitiated: false
        }
      };
    }
    case generateAudioWaveform.fulfilled.toString(): {
      return {
        ...state,
        modal: {
          ...state.modal,
          hasInitiated: true
        }
      };
    }
    case generateAudioWaveform.rejected.toString(): {
      return {
        ...state,
        modal: {
          ...state.modal,
          error: action.payload.message || action.payload.body.reason
        }
      };
    }
    case actions.setEventCompactView.toString(): {
      return {
        ...state,
        isEventCompactView: action.payload.isCompactView
      };
    }
    default: {
      return state;
    }
  }
};
