import { getAssociatedRecordingsAction,
  getAssetAssociationsAction,
  getAssetWebinarAssociationsAction,
  getRecordingMetaDataAction,
  deleteRecordingAssetAssociationAction,
  createRecordingAssetAssociationAction,
  updateRecordingAssetAssociationAction,
  getAutomaticallyAddedRecordingAssociationAction,
  updateWebinarRecordingAssociationAction } from './actionTypes';

export function getAssociatedRecordings(webinarId, includeOnlineRecordingAssociation) {
  return { type: getAssociatedRecordingsAction.toString(), webinarId, includeOnlineRecordingAssociation };
}

export function getAssetAssociations(entityKeys = [], userKey) {
  return { type: getAssetAssociationsAction.toString(), entityKeys, userKey };
}

export function getAutomaticallyAddedAssetAssociations(entityKeys = [], userKey, accountKey, attendeeType,
  createOrUpdate, recordingObj, recordingLinkType = '') {
  return {
    type: getAutomaticallyAddedRecordingAssociationAction.toString(),
    entityKeys,
    userKey,
    accountKey,
    attendeeType,
    createOrUpdate,
    recordingObj,
    recordingLinkType
  };
}

export function getAssetWebinarAssociations(assetKeys = []) {
  return { type: getAssetWebinarAssociationsAction.toString(), assetKeys };
}

export function deleteRecordingAssetAssociation(associationKeys = [], accountKey = '', userKey = '', recordingKey = '', attendeeType = '', recordingLinkType = '') {
  return {
    type: deleteRecordingAssetAssociationAction.toString(),
    associationKeys,
    accountKey,
    userKey,
    recordingKey,
    attendeeType,
    recordingLinkType
  };
}

export function createRecordingAssetAssociation(entityKeys = [], assetKey = '', associationType = '',
  accountKey = '', userKey = '', recordingObj, recordingLinkType = '') {
  return {
    type: createRecordingAssetAssociationAction.toString(),
    entityKeys,
    assetKey,
    associationType,
    accountKey,
    userKey,
    recordingObj,
    recordingLinkType
  };
}

export function updateRecordingAssetAssociation(associationKeys = [], assetKey = '', accountKey = '',
  userKey = '', recordingObj = {}, associationType = '', previousRecordingKey, recordingLinkType = '') {
  return {
    type: updateRecordingAssetAssociationAction.toString(),
    associationKeys,
    assetKey,
    accountKey,
    userKey,
    recordingObj,
    associationType,
    previousRecordingKey,
    recordingLinkType
  };
}

export function getRecordingMetadata(accountKey = '', recordingKey) {
  return { type: getRecordingMetaDataAction.toString(), accountKey, recordingKey };
}

export function updateWebinarRecordingAssociation(webinarKey = '', recordingKey = '', recordingSettings = {},
  associationType = '', previousRecordingKey = '', recordingObj = {}, recordingLinkType = '') {
  return {
    type: updateWebinarRecordingAssociationAction.toString(),
    webinarKey,
    recordingKey,
    recordingSettings,
    associationType,
    previousRecordingKey,
    recordingObj,
    recordingLinkType
  };
}
