import { getOrganizerAudioSettingsAction } from 'Reducks/Webinar/actions';
import { getOrganizerWebinarSettingsAction,
  updateOrganizerWebinarSettingsAction,
  updateOrganizerAudioSettingsAction } from './actions';

const defaultState = {
  organizerSettings: {
    error: false,
    isFetching: false,
    isCertificatesEnabled: false,
    isCertificatesEntitled: false,
    isCertificatesProvisioned: false,
    isSourceTrackingEnabled: false,
    isSourceTrackingEntitled: false,
    isSourceTrackingProvisioned: false,
    isVideoLibraryEnabled: true,
    audio: {}
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case getOrganizerWebinarSettingsAction.fulfilled.toString(): {
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          ...action.payload,
          isFetching: false,
          error: null
        }
      };
    }
    case updateOrganizerWebinarSettingsAction.complete.toString(): {
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          ...action.data,
          isFetching: false
        }
      };
    }
    case getOrganizerWebinarSettingsAction.pending.toString(): {
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          isFetching: true,
          error: false
        }
      };
    }
    case getOrganizerWebinarSettingsAction.rejected.toString(): {
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          isFetching: false,
          error: true
        }
      };
    }
    case updateOrganizerAudioSettingsAction.toString(): {
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          isFetching: true
        }
      };
    }
    case updateOrganizerAudioSettingsAction.complete.toString(): {
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          audio: action.audioDetails,
          isFetching: false
        }
      };
    }
    case updateOrganizerAudioSettingsAction.failed.toString(): {
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          isFetching: false
        }
      };
    }
    case getOrganizerAudioSettingsAction.toString():
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          audio: {},
          isFetching: true
        }
      };
    case getOrganizerAudioSettingsAction.complete.toString():
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          audio: action.data,
          isFetching: false
        }
      };
    case getOrganizerAudioSettingsAction.failed.toString():
      return {
        ...state,
        organizerSettings: {
          ...state.organizerSettings,
          isFetching: false
        }
      };
    default:
      return state;
  }
};
