import config from 'appConfig';
import { getBearerHeaders } from 'Api/Helpers/getHeaders';
import { fetchJson } from '../Helpers/fetch';
import { GET, PUT } from '../Helpers/httpMethods';
import { JSON_MIME_TYPE } from '../Helpers/requestProperties';

/**
 * Engagement Service API
 * https://confluence.ops.expertcity.com/display/proddev/Engagement+Engine+API+v2
 */

const defaultQueryParams = 'product=g2w';

export function getEngagementInfo(accountKey, userKey) {
  const url = `${config.engagementService.url}/account/${accountKey}/user/${userKey}?${defaultQueryParams}`;
  const options = {
    headers: getBearerHeaders(false),
    method: GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE).catch(() => {}); // TODO: Log to Logging Service.
}

export function updateEngagementInfo(accountKey, userKey, body) {
  const url = `${config.engagementService.url}/account/${accountKey}/user/${userKey}?${defaultQueryParams}`;
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify(body)
  };
  return fetchJson(url, options, JSON_MIME_TYPE, false);
}
