import { getIsVideoPushEnabledAction,
  getAllVideosAction,
  getVideoAction,
  uploadVideoAction,
  uploadYouTubeLinkAction,
  updateVideoAction,
  deleteVideoAction,
  getYouTubeVideoDetailsAction } from './actionTypes';

export function getIsVideoPushEnabled(accountKey, userKey) {
  return { type: getIsVideoPushEnabledAction.toString(), accountKey, userKey };
}

export function getAllVideos(webinarKey, accountKey, userKey) {
  return {
    type: getAllVideosAction.toString(), webinarKey, accountKey, userKey
  };
}

export function getVideo(webinarKey, accountKey, userKey, videoKey) {
  return {
    type: getVideoAction.toString(), webinarKey, accountKey, userKey, videoKey
  };
}

export function uploadVideo(userWebinarInfo, fileMetaData, fileObject) {
  return {
    type: uploadVideoAction.toString(), userWebinarInfo, fileMetaData, fileObject
  };
}

export function uploadYouTubeLink(userWebinarInfo, youTubeLinkData) {
  return { type: uploadYouTubeLinkAction.toString(), userWebinarInfo, youTubeLinkData };
}

export function updateVideo(userWebinarInfo, videoKey, videoData) {
  return {
    type: updateVideoAction.toString(), userWebinarInfo, videoKey, videoData
  };
}

export function deleteVideo(webinarKey, accountKey, userKey, videoKey) {
  return {
    type: deleteVideoAction.toString(), webinarKey, accountKey, userKey, videoKey
  };
}

export function getYouTubeVideoDetails(youTubeId) {
  return { type: getYouTubeVideoDetailsAction.toString(), youTubeId };
}
