import { createAsyncAction } from 'redux-promise-middleware-actions';
import * as paymentService from 'Api/Payment/paymentService';
import * as couponService from 'Api/Payment/couponService';

export const getUserStripeIdAction = createAsyncAction('GET_USER_STRIPE_ID', (accountKey) => paymentService.getUserStripeId(accountKey));
export const getWebinarPaymentAction = createAsyncAction('GET_WEBINAR_PAYMENT', (webinarKey) => paymentService.getWebinarPayment(webinarKey));
export const createWebinarPaymentAction = createAsyncAction('CREATE_WEBINAR_PAYMENT', (body, webinarKey, accountKey) => paymentService.createWebinarPayment(body, webinarKey, accountKey));
export const updateWebinarPaymentAction = createAsyncAction('UPDATE_WEBINAR_PAYMENT', (body, webinarKey) => paymentService.updateWebinarPayment(body, webinarKey));
export const deleteWebinarPaymentAction = createAsyncAction('DELETE_WEBINAR_PAYMENT', (webinarKey, accountKey) => paymentService.deleteWebinarPayment(webinarKey, accountKey));

export const getCouponsForWebinarAction = createAsyncAction('GET_COUPONS_FOR_WEBINAR', (accountKey, webinarKey) => couponService.getCouponsForWebinar(accountKey, webinarKey));
export const createCouponForWebinarAction = createAsyncAction('CREATE_COUPON_FOR_WEBINAR', async (newCoupons, webinarKey, accountKey) => {
  try {
    const couponResponse = await couponService.createCoupon(newCoupons, accountKey);
    if (couponResponse && couponResponse.length === newCoupons.length) {
      const associationArray = couponResponse.map((coupon) => ({
        referenceKey: webinarKey,
        couponKey: coupon.couponKey,
        product: 'G2W'
      }));

      const associatedCoupons = await couponService.associateCouponToWebinar(associationArray, accountKey);
      if (associatedCoupons && associatedCoupons.length === couponResponse.length) {
        const something = couponResponse.map((coupon) => {
          const couponAssociationObj = associatedCoupons.find((association) => association.couponKey === coupon.couponKey);
          return { ...coupon, associationKey: couponAssociationObj.associationKey };
        });
        return { couponResponse: something };
      }
    }
    throw new Error('Unable to create all coupons');
  } catch (error) {
    throw (error);
  }
});
export const deleteCouponForWebinarAction = createAsyncAction('DELETE_COUPON', couponService.deleteCouponForWebinar, (body) => ({ body }));
