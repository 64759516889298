import UserType from './userType';

function sanitize(context) {
  // Remove all entries with null/undefined values.
  Object.entries(context).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete context[key];
    }
  });
}

export default class People {
  constructor() {
    this.userKey = null;
    this.accountStartDate = null;
    this.userType = UserType.UNKNOWN;
    this.userEmailDomain = null;
    this.pricingTier = null;
    Object.preventExtensions(this);
  }

  setUserKey(userKey) {
    this.userKey = userKey;
    return this;
  }

  setAccountProperties(accountStartDate) {
    const fourthSept = Date.parse(new Date('September 4, 2018'));
    this.accountStartDate = new Date(Number(accountStartDate)).toDateString();
    this.userType = UserType.CLASSIC;
    if (accountStartDate >= fourthSept) {
      this.userType = UserType.NEW;
    }
    return this;
  }

  setUserEmailDomain(email) {
    if (!email) {
      return this;
    }
    const splitEmail = email.split('@');
    this.userEmailDomain = splitEmail && splitEmail.length > 1 ? splitEmail[1] : null;
    return this;
  }

  setPricingTier(pricingTier) {
    if (!pricingTier) {
      return this;
    }
    this.pricingTier = pricingTier;
    return this;
  }

  build() {
    sanitize(this);
    return Object.freeze(this);
  }
}
