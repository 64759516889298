
import config from 'appConfig';
import { MAX_ATTENDEES_WEBCAST, MAX_ATTENDEES, MAX_REGISTRANTS_SUMULIVE } from 'Utils/maxAttendeeLimit';
import WebinarType from '../../Components/Model/webinarType';

export function shouldUpgrade(webinarType, registrationLimit, numberOfRegistrants, metadata) {
  if (metadata) {
    if (numberOfRegistrants < registrationLimit) {
      return false;
    }

    if (webinarType.toLowerCase() === WebinarType.CLASSIC.toLowerCase()
        && metadata.maxAttendees < MAX_ATTENDEES) {
      return true;
    }

    if (webinarType.toLowerCase() === WebinarType.SIMULIVE.toLowerCase()
        && metadata.maxRegistrantCountBroadcast < MAX_REGISTRANTS_SUMULIVE) {
      return true;
    }

    if (numberOfRegistrants <= metadata.maxAttendeesBroadcast
        && metadata.maxAttendeesBroadcast < MAX_ATTENDEES_WEBCAST) {
      return true;
    }
  }
  return false;
}

export function getUserKey(user) {
  return user && user.metadata && user.metadata.userKey;
}

export function getUpgradeUrl(metadata) {
  if (metadata) {
    const { serviceType, channel } = metadata;
    if (serviceType === 'trial') {
      return config.ipd.trial;
    }
    if (channel === 'offline') {
      return config.ipd.upgrade.offlineUrl;
    }
    if (channel === 'online') {
      return config.ipd.upgrade.onlineUrl;
    }
    return config.webinarPricingUrl;
  }
  return config.webinarPricingUrl;
}

