import { getIsVideoPushEnabledAction,
  getAllVideosAction,
  getVideoAction,
  uploadYouTubeLinkAction,
  uploadVideoAction,
  updateVideoAction,
  deleteVideoAction,
  getYouTubeVideoDetailsAction } from './actionTypes';

const initialState = {
  isVideoPushEnabled: {
    isFetching: false,
    data: {},
    error: null
  },
  allVideos: {
    isFetching: false,
    data: [],
    error: null
  },
  video: {
    isFetching: false,
    data: {},
    error: null
  },
  newVideo: {
    isFetching: false,
    data: {},
    error: null
  },
  updatedVideo: {
    isFetching: false,
    data: {},
    error: null
  },
  deleteVideo: {
    isFetching: false,
    error: null
  },
  youTubeVideoDetail: {
    isFetching: false,
    title: '',
    isValidLink: true,
    error: null
  },
  isUploading: false,
  uploadError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // -------------------Get Is Video Push Enabled----------------------------
    case getIsVideoPushEnabledAction.toString(): {
      const isVideoPushEnabledCopy = { ...state.isVideoPushEnabled };
      isVideoPushEnabledCopy.isFetching = true;
      return {
        ...state,
        isVideoPushEnabled: isVideoPushEnabledCopy
      };
    }
    case getIsVideoPushEnabledAction.complete.toString(): {
      const isVideoPushEnabledCopy = { ...state.isVideoPushEnabled };
      isVideoPushEnabledCopy.isFetching = false;
      isVideoPushEnabledCopy.data = action.data;
      return {
        ...state,
        isVideoPushEnabled: isVideoPushEnabledCopy
      };
    }
    case getIsVideoPushEnabledAction.failed.toString(): {
      const isVideoPushEnabledCopy = { ...state.isVideoPushEnabled };
      isVideoPushEnabledCopy.isFetching = false;
      isVideoPushEnabledCopy.data = {};
      isVideoPushEnabledCopy.error = action.error;
      return {
        ...state,
        isVideoPushEnabled: isVideoPushEnabledCopy
      };
    }
    // -------------------Get All Video----------------------------
    case getAllVideosAction.toString(): {
      const allVideosCopy = { ...state.allVideos };
      allVideosCopy.isFetching = true;
      return {
        ...state,
        allVideos: allVideosCopy
      };
    }
    case getAllVideosAction.complete.toString(): {
      const allVideosCopy = { ...state.allVideos };
      allVideosCopy.isFetching = false;
      allVideosCopy.data = action.data;
      return {
        ...state,
        allVideos: allVideosCopy
      };
    }
    case getAllVideosAction.failed.toString(): {
      const allVideosCopy = { ...state.allVideos };
      allVideosCopy.isFetching = false;
      allVideosCopy.data = [];
      allVideosCopy.error = action.error;
      return {
        ...state,
        allVideos: allVideosCopy
      };
    }
    // -------------------Get Video-----------------------------------
    // TODO: After fixing API use getVideo reducer to add new video to the state
    case getVideoAction.toString(): {
      const videoCopy = { ...state.video };
      videoCopy.isFetching = true;
      return {
        ...state,
        video: videoCopy
      };
    }
    case getVideoAction.complete.toString(): {
      const videoCopy = { ...state.video };
      videoCopy.isFetching = false;
      videoCopy.data = action.data;
      return {
        ...state,
        video: videoCopy
      };
    }
    case getVideoAction.failed.toString(): {
      const videoCopy = { ...state.video };
      videoCopy.isFetching = false;
      videoCopy.error = action.error;
      return {
        ...state,
        video: videoCopy
      };
    }
    // -------------------Upload Video----------------------------
    case uploadVideoAction.toString(): {
      return {
        ...state,
        isUploading: true,
        uploadError: false
      };
    }

    case uploadVideoAction.complete.toString(): {
      return {
        ...state,
        isUploading: false,
        uploadError: false
      };
    }
    case uploadVideoAction.failed.toString(): {
      return {
        ...state,
        isUploading: false,
        uploadError: true
      };
    }
    // -------------------Upload YouTube Link--------------------------------
    case uploadYouTubeLinkAction.toString(): {
      const newVideoCopy = { ...state.newVideo };
      newVideoCopy.isFetching = true;
      return {
        ...state,
        newVideo: newVideoCopy
      };
    }
    case uploadYouTubeLinkAction.complete.toString(): {
      const newVideoCopy = { ...state.newVideo };
      newVideoCopy.isFetching = false;
      newVideoCopy.data = action.data;

      const allVideosCopy = { ...state.allVideos };
      allVideosCopy.data.push(newVideoCopy.data);
      return {
        ...state,
        newVideo: newVideoCopy,
        allVideos: allVideosCopy
      };
    }
    case uploadYouTubeLinkAction.failed.toString(): {
      const newVideoCopy = { ...state.newVideoVideo };
      newVideoCopy.isFetching = false;
      newVideoCopy.error = action.error;
      return {
        ...state,
        newVideo: newVideoCopy
      };
    }
    // -------------------Update Video--------------------------------
    case updateVideoAction.toString(): {
      const updatedVideoCopy = { ...state.updatedVideo };
      updatedVideoCopy.isFetching = true;
      return {
        ...state,
        updatedVideo: updatedVideoCopy
      };
    }
    case updateVideoAction.complete.toString(): {
      const updatedVideoCopy = { ...state.updatedVideo };
      updatedVideoCopy.isFetching = false;

      const allVideosCopy = { ...state.allVideos };
      const updateIndex = allVideosCopy.data.map((video) => video.id).indexOf(action.videoKey);
      allVideosCopy.data[updateIndex].title = action.data.title;
      return {
        ...state,
        updatedVideo: updatedVideoCopy,
        allVideos: allVideosCopy
      };
    }
    case updateVideoAction.failed.toString(): {
      const updatedVideoCopy = { ...state.updatedVideo };
      updatedVideoCopy.isFetching = false;
      updatedVideoCopy.error = action.error;
      return {
        ...state,
        updatedVideo: updatedVideoCopy
      };
    }
    // -------------------Delete Video--------------------------------
    case deleteVideoAction.toString(): {
      const deleteVideoCopy = { ...state.deleteVideo };
      deleteVideoCopy.isFetching = true;
      return {
        ...state,
        deleteVideo: deleteVideoCopy
      };
    }
    case deleteVideoAction.complete.toString(): {
      const deleteVideoCopy = { ...state.deleteVideo };
      deleteVideoCopy.isFetching = false;

      const allVideosCopy = { ...state.allVideos };
      const removeIndex = allVideosCopy.data.map((video) => video.id).indexOf(action.videoKey);
      allVideosCopy.data.splice(removeIndex, 1);
      return {
        ...state,
        deleteVideo: deleteVideoCopy,
        allVideos: allVideosCopy
      };
    }
    case deleteVideoAction.failed.toString(): {
      const deleteVideoCopy = { ...state.deleteVideo };
      deleteVideoCopy.isFetching = false;
      deleteVideoCopy.error = action.error;
      return {
        ...state,
        deleteVideo: deleteVideoCopy
      };
    }
    // -------------------Get Youtube Video Details--------------------------------
    case getYouTubeVideoDetailsAction.toString(): {
      const youTubeVideoDetailCopy = { ...state.youTubeVideoDetail };
      youTubeVideoDetailCopy.isFetching = true;
      youTubeVideoDetailCopy.title = '';
      return {
        ...state,
        youTubeVideoDetail: youTubeVideoDetailCopy
      };
    }
    case getYouTubeVideoDetailsAction.complete.toString(): {
      const youTubeVideoDetailCopy = { ...state.youTubeVideoDetail };
      youTubeVideoDetailCopy.isFetching = false;
      if (action.data && action.data.items && action.data.items.length === 0) {
        youTubeVideoDetailCopy.isValidLink = false;
      }
      if (action.data && action.data.items && action.data.items.length > 0 && action.data.items[0].snippet && action.data.items[0].snippet.title) {
        youTubeVideoDetailCopy.title = action.data.items[0].snippet.title;
        youTubeVideoDetailCopy.isValidLink = true;
      }
      return {
        ...state,
        youTubeVideoDetail: youTubeVideoDetailCopy
      };
    }
    case getYouTubeVideoDetailsAction.failed.toString(): {
      const youTubeVideoDetailCopy = { ...state.youTubeVideoDetail };
      youTubeVideoDetailCopy.isFetching = false;
      youTubeVideoDetailCopy.error = action.error;
      return {
        ...state,
        youTubeVideoDetail: youTubeVideoDetailCopy
      };
    }
    default:
      return state;
  }
};
