import * as Constants from './constants';

export function createWebinarSurvey(webinarKey, survey) {
  return { type: Constants.CREATE_WEBINAR_SURVEY_START, webinarKey, survey };
}

export function deleteWebinarSurvey(webinarKey) {
  return { type: Constants.DELETE_WEBINAR_SURVEY_START, webinarKey };
}

export function retrieveWebinarSurvey(webinarKey) {
  return { type: Constants.RETRIEVE_WEBINAR_SURVEY_START, webinarKey };
}

// TODO: Delete these actions if not needed
export function retrieveWebinarSurveyInfo(webinarKey) {
  return { type: Constants.RETRIEVE_WEBINAR_SURVEY_INFO_START, webinarKey };
}

export function retrieveWebinarSurveyResponses(webinarKey, sessionKey) {
  return { type: Constants.RETRIEVE_WEBINAR_SURVEY_RESPONSES_START, webinarKey, sessionKey };
}

export function retrieveWebinarSurveyResponsesForAttendees(webinarKey, sessionKey, registrantKey) {
  return {
    type: Constants.RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_START, webinarKey, sessionKey, registrantKey
  };
}
