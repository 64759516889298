import Action from 'Mixpanel/Model/action';
import * as transcriptActions from '../Reducks/Transcript/actions';
import MixpanelTracker from '../Mixpanel/mixpanelTracker';
import { TRANSCRIPT, SETTINGS, PAYMENT_TOS, PAYMENT_CONNECT_TO_STRIPE } from '../Mixpanel/mixpanelConstants';
import { get } from '../Utils/objectHelpers';
import { updateOrganizerWebinarSettingsAction, trackPaymentTosClickedAction, trackPaymentConnectToStripeClickedAction } from '../Reducks/AccountSettings/actions';
import { reportAppcuesEventAction } from '../Reducks/Appcues/actions';
import trackAppcues from '../Mixpanel/Features/Appcues/appcuesTracker';

const mixpanelTracker = new MixpanelTracker();

const track = (name, properties) => {
  mixpanelTracker.setEventName(name);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
};

const mixpanelMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case String(transcriptActions.createTranscript.fulfilled): {
      track(
        TRANSCRIPT,
        {
          Action: 'Transcript Created',
          assetKey: get(action, (a) => a.payload.assetKey)
        }
      );
      break;
    }
    case String(updateOrganizerWebinarSettingsAction.complete): {
      if (Object.prototype.hasOwnProperty.call(action.data, 'autogenerateTranscriptsEnabled')) {
        track(
          TRANSCRIPT,
          {
            Action: 'Update Settings',
            autoGenerate: get(action, (a) => a.data.autogenerateTranscriptsEnabled)
          }
        );
      }
      break;
    }
    case String(reportAppcuesEventAction): {
      trackAppcues(action.payload.name, action.payload.event);
      break;
    }
    case String(trackPaymentTosClickedAction): {
      track(
        SETTINGS,
        {
          Action: Action.CLICK,
          Type: `${PAYMENT_TOS}`
        }
      );
      break;
    }
    case String(trackPaymentConnectToStripeClickedAction): {
      track(
        SETTINGS,
        {
          Action: Action.CLICK,
          Type: `${PAYMENT_CONNECT_TO_STRIPE}`
        }
      );
      break;
    }
    default: return next(action);
  }

  return next(action);
};

export default mixpanelMiddleware;
