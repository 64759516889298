import { getRegistrantsAction,
  getRegistrationReportAction,
  clearRegistrantsAction,
  getFilteredRegistrantsAction } from './actions';

const defaultState = {
  registrantsList: {
    isFetching: true,
    isSearchInProgress: false,
    data: [],
    filteredList: [],
    filterKey: '',
    error: null,
    isDownloadingReportInProgress: false
  }
};

const DEFAULT_CHECKED_STATE_FOR_PENDING_REGISTRANTS = true;
const DEFAULT_CHECKED_STATE_FOR_APPROVED_AND_DENIED_REGISTRANTS = false;

export default (state = defaultState, action) => {
  switch (action.type) {
    case getRegistrantsAction.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = true;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrantsAction.complete.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = false;
      const registrantsList = action.data;
      const isSourceTrackingAvailable = registrantsList.some((registrant) => (registrant.source));
      let mappedList;

      if (!isSourceTrackingAvailable) {
        mappedList = registrantsList.map((registrant) => ({
          ...registrant,
          fullName: [registrant.firstName, registrant.lastName].join(' '),
          selectedForAction: registrant.status === 'WAITING' ? DEFAULT_CHECKED_STATE_FOR_PENDING_REGISTRANTS : DEFAULT_CHECKED_STATE_FOR_APPROVED_AND_DENIED_REGISTRANTS
        }));
      } else {
        mappedList = registrantsList.map((registrant) => ({
          ...registrant,
          fullName: [registrant.firstName, registrant.lastName].join(' '),
          selectedForAction: registrant.status === 'WAITING' ? DEFAULT_CHECKED_STATE_FOR_PENDING_REGISTRANTS : DEFAULT_CHECKED_STATE_FOR_APPROVED_AND_DENIED_REGISTRANTS,
          source: registrant.source || ''
        }));
      }
      registrantsCopy.data = mappedList;
      registrantsCopy.filterKey = '';

      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrantsAction.failed.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = false;
      registrantsCopy.error = action.error;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getFilteredRegistrantsAction.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = false;
      registrantsCopy.isSearchInProgress = true;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getFilteredRegistrantsAction.complete.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      const filterKey = action.filter;
      registrantsCopy.filterKey = filterKey;

      const registrantsList = registrantsCopy.data;
      const filteredList = registrantsList.filter((registrant) => {
        if (registrant.firstName.toLocaleLowerCase().includes(filterKey)
            || registrant.lastName.toLocaleLowerCase().includes(filterKey)
            || registrant.email.toLocaleLowerCase().includes(filterKey)) {
          return true;
        }

        return false;
      });
      registrantsCopy.filteredList = filteredList;
      registrantsCopy.isSearchInProgress = false;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrationReportAction.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isDownloadingReportInProgress = true;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrationReportAction.complete.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isDownloadingReportInProgress = false;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrationReportAction.failed.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isDownloadingReportInProgress = false;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case clearRegistrantsAction.complete.toString(): {
      return defaultState;
    }
    default:
      return {
        ...state
      };
  }
};
