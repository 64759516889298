import { getAssociatedRecordingsAction,
  getAssetAssociationsAction,
  createRecordingAssetAssociationAction,
  updateRecordingAssetAssociationAction,
  deleteRecordingAssetAssociationAction,
  getRecordingMetaDataAction,
  updateWebinarRecordingAssociationAction,
  getAssetWebinarAssociationsAction } from './actionTypes';
import { createAssociations, updateAssociations, removeAssociations } from './reducerOperations';

const initialState = {
  associatedRecordings: {
    isFetching: false,
    arr: [],
    error: null
  },
  assetAssociations: {
    isFetching: false,
    obj: {},
    error: null
  },
  assetWebinarAssociations: {
    isFetching: false,
    associations: {},
    error: null
  },
  recordingMetadata: {
    isFetching: false,
    map: new Map(),
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case getAssociatedRecordingsAction.toString(): {
      const associatedRecordings = { ...state.associatedRecordings };
      associatedRecordings.isFetching = true;
      return {
        ...state,
        associatedRecordings
      };
    }
    case getAssociatedRecordingsAction.complete.toString(): {
      const associatedRecordings = { ...state.associatedRecordings };
      associatedRecordings.isFetching = false;
      associatedRecordings.arr = action.data;
      return {
        ...state,
        associatedRecordings
      };
    }
    case getAssociatedRecordingsAction.failed.toString(): {
      const associatedRecordings = { ...state.associatedRecordings };
      associatedRecordings.isFetching = false;
      associatedRecordings.error = action.error;
      return {
        ...state,
        associatedRecordings
      };
    }
    case getAssetAssociationsAction.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = true;
      return {
        ...state,
        assetAssociations
      };
    }
    case getAssetAssociationsAction.complete.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = false;
      assetAssociations.obj = action.data;
      return {
        ...state,
        assetAssociations
      };
    }
    case getAssetAssociationsAction.failed.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = false;
      assetAssociations.error = action.error;
      return {
        ...state,
        assetAssociations
      };
    }
    case getAssetWebinarAssociationsAction.complete.toString(): {
      const newWebinarAssociations = { ...state.assetWebinarAssociations };
      newWebinarAssociations.isFetching = false;
      newWebinarAssociations.error = null;
      if (action.data._embedded && action.data._embedded.associationResources) {
        const associations = action.data._embedded.associationResources;
        associations.forEach((a) => {
          const currentassociations = newWebinarAssociations.associations[a.assetKey];
          if (!currentassociations) {
            newWebinarAssociations.associations[a.assetKey] = [a];
          } else {
            const index = currentassociations.map((c) => c.entityKey).indexOf(a.entityKey);
            if (index >= 0) {
              currentassociations[index] = a;
            } else {
              currentassociations.push(a);
            }
          }
        });
      }
      return {
        ...state,
        assetWebinarAssociations: newWebinarAssociations
      };
    }

    case getRecordingMetaDataAction.toString(): {
      const recordingMetadata = { ...state.recordingMetadata };
      recordingMetadata.isFetching = true;
      return {
        ...state,
        recordingMetadata
      };
    }
    case getRecordingMetaDataAction.complete.toString(): {
      const recordingMetadata = { ...state.recordingMetadata };
      recordingMetadata.isFetching = false;
      recordingMetadata.map = new Map([...recordingMetadata.map, ...action.data]);
      return {
        ...state,
        recordingMetadata
      };
    }
    case getRecordingMetaDataAction.failed.toString(): {
      const recordingMetadata = { ...state.recordingMetadata };
      recordingMetadata.isFetching = false;
      recordingMetadata.error = action.error;
      return {
        ...state,
        recordingMetadata
      };
    }
    case createRecordingAssetAssociationAction.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = true;
      return {
        ...state,
        assetAssociations
      };
    }
    case createRecordingAssetAssociationAction.complete.toString(): {
      const stateCopy = createAssociations(state, action);
      return stateCopy;
    }
    case createRecordingAssetAssociationAction.failed.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = false;
      assetAssociations.error = action.error;
      return {
        ...state,
        assetAssociations
      };
    }
    case deleteRecordingAssetAssociationAction.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = true;
      return {
        ...state,
        assetAssociations
      };
    }
    case deleteRecordingAssetAssociationAction.complete.toString(): {
      const stateCopy = removeAssociations(state, action);
      return stateCopy;
    }
    case deleteRecordingAssetAssociationAction.failed.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = false;
      assetAssociations.error = action.error;
      return {
        ...state,
        assetAssociations
      };
    }
    case updateRecordingAssetAssociationAction.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = true;
      return {
        ...state,
        assetAssociations
      };
    }
    case updateRecordingAssetAssociationAction.complete.toString(): {
      const recordingKey = action.recordingObj.recordingKey;
      const stateCopy = updateAssociations(state, action, recordingKey);
      return stateCopy;
    }
    case updateRecordingAssetAssociationAction.failed.toString(): {
      const assetAssociations = { ...state.assetAssociations };
      assetAssociations.isFetching = false;
      assetAssociations.error = action.error;
      return {
        ...state,
        assetAssociations
      };
    }
    case updateWebinarRecordingAssociationAction.toString(): {
      const associatedRecordings = { ...state.associatedRecordings };
      associatedRecordings.isFetching = true;
      return {
        ...state,
        associatedRecordings
      };
    }
    case updateWebinarRecordingAssociationAction.complete.toString(): {
      const stateCopy = updateAssociations(state, action, action.recordingKey);
      return stateCopy;
    }
    case updateWebinarRecordingAssociationAction.failed.toString(): {
      const associatedRecordings = { ...state.associatedRecordings };
      associatedRecordings.isFetching = false;
      associatedRecordings.error = action.error;
      return {
        ...state,
        associatedRecordings
      };
    }
    default:
      return state;
  }
};
