import config from 'appConfig';
import { getAuth } from 'Api';
import { fetchJson } from '../Helpers/fetch';
import { PUT, POST } from '../Helpers/httpMethods';
import getHeader from '../Helpers/getHeaders';

let maxRecordings = 50;
export function setMaxRecordings(r) { maxRecordings = r }

function getUrls(recordingKeys, startingIndex, urls) {
  const count = recordingKeys.length;
  if (startingIndex >= count) {
    return urls;
  }

  const endingIndex = startingIndex + maxRecordings;
  if (endingIndex < count) {
    const keys = recordingKeys.slice(startingIndex, endingIndex);
    const url = `${config.g2s.url}/contents?product=G2W&productRefKeys=${keys}`;
    urls.push(url);
    return getUrls(recordingKeys, endingIndex, urls);
  }

  const keys = recordingKeys.slice(startingIndex, count);
  const url = `${config.g2s.url}/contents?product=G2W&productRefKeys=${keys}`;
  urls.push(url);
  return urls;
}

export function getContentKeyForWebinar(webinarKey) {
  const url = `${config.g2s.url}/contents?product=G2W&productRefKeys=${webinarKey}`;
  const options = {
    headers: getHeader(true, 'Bearer')
  };
  return fetchJson(url, options, 'json');
}

export function addContentToChannel(contentKey, channelKey) {
  const url = `${config.g2s.url}/channels/${channelKey}/contents`;
  const options = {
    method: PUT,
    body: JSON.stringify(contentKey),
    headers: getHeader(true, 'Bearer')
  };
  return fetchJson(url, options, 'json');
}

export function addWebinarToChannel(channelKey, webinarIdentifier) {
  const url = `${config.g2wReporting.url}/webinars/contents`;
  const options = {
    method: POST,
    body: JSON.stringify({ channelKey, webinarIdentifier }),
    headers: getHeader(true, 'Bearer')
  };
  return fetchJson(url, options, 'json');
}

function getIdUrls(contentIds, startingIndex, urls) {
  const count = contentIds.length;
  if (startingIndex >= count) {
    return urls;
  }

  const endingIndex = startingIndex + maxRecordings;
  if (endingIndex < count) {
    const ids = contentIds.slice(startingIndex, endingIndex);
    const url = `${config.g2s.url}/contents?ids=${ids}`;
    urls.push(url);
    return getIdUrls(contentIds, endingIndex, urls);
  }

  const ids = contentIds.slice(startingIndex, count);
  const url = `${config.g2s.url}/contents?ids=${ids}`;
  urls.push(url);
  return urls;
}

export function getContents(recordingKeys) {
  const urls = getUrls(recordingKeys, 0, []);
  const options = { headers: { Authorization: `Bearer ${getAuth().access_token}` } };

  return Promise.all(urls.map((url) => fetchJson(url, options, 'json')))
    .then((responseArray) => [].concat(...responseArray));
}

export function getContentsFromIds(contentIds) {
  const urls = getIdUrls(contentIds, 0, []);
  const options = { headers: { Authorization: `Bearer ${getAuth().access_token}` } };

  return Promise.all(urls.map((url) => fetchJson(url, options, 'json')))
    .then((responseArray) => [].concat(...responseArray));
}

export function getContent(contentKey) {
  const url = `${config.g2s.url}/channels?query=contentKey:=${contentKey}`;
  const options = {
    headers: { Authorization: `Bearer ${getAuth().access_token}` }
  };
  return fetchJson(url, options, 'json');
}

export function updateContent(contentKey, content) {
  const url = `${config.g2s.url}/contents/${contentKey}`;
  const options = {
    method: PUT,
    body: JSON.stringify(content),
    headers: getHeader(true, 'Bearer')
  };
  return fetchJson(url, options, 'json');
}

export function getContentChannel(contentIds) {
  const contentKeyString = contentIds.join(',');
  const url = `${config.g2s.url}/channels?query=contentKey:=${contentKeyString}`;
  const options = {
    headers: { Authorization: `Bearer ${getAuth().access_token}` }
  };
  return fetchJson(url, options, 'json');
}

export function getContentAttributes(contentKey) {
  const url = `${config.g2s.url}/contents/${contentKey}/attributes?key=showtranscript`;
  return fetchJson(url);
}

export function updateContentAttributes(contentKey, attributes) {
  const body = {};
  Object.keys(attributes).forEach((attribute) => {
    body[attribute] = String(attributes[attribute]);
  });

  const url = `${config.g2s.url}/contents/${contentKey}/attributes`;
  const options = {
    method: PUT,
    headers: getHeader(true, 'Bearer'),
    body: JSON.stringify(body)
  };

  return fetchJson(url, options);
}
