import AddVideoToChannel from './AddVideoToChannel';
import FeatureUnlock from './FeatureUnlock';
import CreateChannel from './CreateChannel';
import GenerateAudioWaveform from './GenerateAudioWaveform';
import TranscriptProcessing from './TranscriptProcessing';
import VideoDownloadOption from './VideoDownloadOption';

const modals = {
  CreateChannel,
  AddVideoToChannel,
  FeatureUnlock,
  GenerateAudioWaveform,
  TranscriptProcessing,
  VideoDownloadOption
};

export default modals;
