import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { EditTextarea, InputField } from '@getgo/chameleon-react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { channelRegex } from 'Utils/formats';
import filestackHelper from 'Utils/filestackHelper';
import emptyChannelLogo from 'Components/Channel/Assets/emptyChannelLogo.png';
import { connectDispatch } from 'Utils/decorators';
import { sendNotification } from 'Reducks/Notification/actions';
import classNames from 'classnames';
import config from 'appConfig';
import { withTranslation } from 'react-i18next';
import styles from '../Common/styles.scss';

const supportedFileTypes = ['image/gif', 'image/png', 'image/jpeg', 'image/pjpeg'];
const MAX_LOGO_SIZE = 20971520;

@connectDispatch()
class CreateChannel extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    onClick: PropTypes.func,
    onChannelUpdated: PropTypes.func,
    selected: PropTypes.bool,
    t: PropTypes.func,
    disableLogo: PropTypes.bool // disable Logo upload to support filestack 'inline' mode on content library.
  };

  static defaultProps = {
    selected: false,
    onClick: Function.prototype,
    disableLogo: false
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.privacyModes = [t('channel.private'), t('channel.public')];
    this.state = {
      dropdownOpen: false,
      showAddImageIcon: true,
      hasTitleError: false,
      hasDescriptionError: false,
      channel: {
        isNewChannel: true,
        isLimitedAudience: false,
        channelLogo: undefined
      }
    };
    this.displayedFilestack = false;
  }

  componentDidMount() {
    this.displayFilestack();
  }

  displayFilestack = () => {
    if (!this.displayedFilestack) {
      this.displayedFilestack = true;
      const filestackOptions = {
        container: 'create_channel_filestack',
        displayMode: 'dropPane',
        accept: supportedFileTypes,
        maxSize: MAX_LOGO_SIZE,
        transformations: {
          crop: {
            aspectRatio: 1,
            force: true
          },
          rotate: true
        },
        imageMin: [160, 160],
        dropPane: {
          overlay: false, // if this is true then overlays will conflict between instances
          showIcon: false,
          customText: ' ', // removes the text
          onSuccess: (files) => {
            const file = files[0];
            if (file) {
              const fileUrl = `${config.filestack.storage.s3.baseUrl}/${file.container}/${filestackHelper.fileEncoder(file.key)}`;
              const updatedChannel = { ...this.state.channel, channelLogo: fileUrl };
              this.setState({ channel: updatedChannel });
              this.props.onChannelUpdated(updatedChannel);
            } else {
              this.props.dispatch(sendNotification(null, 'error.genericError', true));
              this.setState({ showAddImageIcon: true });
            }
          }
        },
        onFileSelected: (file) => {
          if (!supportedFileTypes.includes(file.mimetype)) {
            this.props.dispatch(sendNotification(null, 'handouts.wrongFileType', true));
          } else if (file.size > MAX_LOGO_SIZE) {
            this.props.dispatch(sendNotification(null, 'handouts.wrongFileSize', true, [{ limit: 15 }]));
          } else {
            this.setState({ showAddImageIcon: false });
          }
        }
      };
      filestackHelper.pickStageChannelImage(filestackOptions);
    }
  };

  onPrivacyChanged = (mode) => {
    const isLimitedAudience = mode === this.privacyModes[0];
    this.setState({ channel: { ...this.state.channel, isLimitedAudience } });
  };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  onClick = () => {
    const { channel } = this.state;
    this.props.onClick(channel);
  };

  onTitleChanged = (channelTitle, hasError) => {
    const updatedChannel = { ...this.state.channel, channelTitle };
    this.setState({ channel: updatedChannel, hasTitleError: hasError });
    this.props.onChannelUpdated(updatedChannel, this.state.hasDescriptionError || hasError);
  };

  onDescriptionChanged = (channelDescription, hasError) => {
    const updatedChannel = { ...this.state.channel, channelDescription };
    this.setState({ channel: updatedChannel, hasDescriptionError: hasError });
    this.props.onChannelUpdated(updatedChannel, this.state.hasTitleError || hasError);
  };

  renderPrivacyIcon = (isLimitedAudience) => <i className={classNames(isLimitedAudience ? 'togo-icon togo-icon-lock-closed' : 'togo-icon togo-icon-globe', styles.icon)}/>;

  render() {
    const { channel, showAddImageIcon } = this.state;
    const { selected, disableLogo } = this.props;
    const { t } = this.props;
    const boxStyle = selected ? styles.selectedChannelBox : styles.channelBox;
    const isPrivate = channel.isLimitedAudience;
    return (
      <div id='create_channel_input_container' className={boxStyle} onClick={this.onClick} onKeyPress={this.onClick} role='button' tabIndex='0'>
        <div className={styles.imageContainer}>
          <div id='create_channel_filestack' className={styles.filestackContainer}/>
          { disableLogo ? <img src={emptyChannelLogo} className={styles.channelImage} alt={t('channel.logoAlt')}/>
            : <> {showAddImageIcon && <i className={classNames('togo-icon togo-icon-add', styles.icon)} />}
            {channel.channelLogo && <img id='create_channel_uploaded_channel_logo' src={channel.channelLogo} className={styles.channelImage} alt={t('channel.logoAlt')}/>}
            </>
          }
        </div>
        <div className={styles.newInfoContainer}>
          <InputField
            id='channel_modal_title_input'
            placeholder={t('channel.newChannel')}
            containerClassName={styles.newChannelTitle}
            inputClassName={`edit ${styles.channelTitleInput}`}
            errorMessage={t('channelCard.invalidTitleDescription')}
            pattern={channelRegex}
            value={channel.channelTitle}
            onChange={(value, hasError) => this.onTitleChanged(value, hasError)}
            maxLength={25}
            inputType='text'
          />
          <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
            <DropdownToggle id='new_channel_privacy_dropdown' className={`${styles.dropdownToggle} edit`} caret={false}>
              {this.renderPrivacyIcon(isPrivate)}<span id='new_channel_privacy_text' className={styles.dropdownText}>{this.privacyModes[isPrivate ? 0 : 1]}</span>
            </DropdownToggle>
            <DropdownMenu>
              {this.privacyModes.map((mode) => (
                <DropdownItem
                  className={styles.dropdownItem}
                  onClick={() => this.onPrivacyChanged(mode)}
                  key={mode}>
                  {this.renderPrivacyIcon(mode === this.privacyModes[0])} {mode}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          <EditTextarea
            id='channel_modal_description_input'
            containerClassName={styles.descriptionInputContainer}
            inputClassName={styles.descriptionInput}
            placeholder={t('channel.description')}
            errorMessage={t('channelCard.invalidTitleDescription')}
            pattern={channelRegex}
            value={channel.channelDescription}
            onChange={(value, hasError) => this.onDescriptionChanged(value, hasError)}
            maxLength={500}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(CreateChannel);
